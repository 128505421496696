/* LR OT4 brand font */
@font-face {
  font-family: 'lr_ot4';
  src: url('../fonts/LandRoverWeb-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/LandRoverWeb-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/LandRoverWeb-Bold.woff') format('woff'), /* Modern Browsers */
       url('../fonts/LandRoverWeb-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */

  font-style:   normal;
  font-weight:  bold;
}
@font-face {
  font-family: 'lr_ot4';
  src: url('../fonts/LandRoverWeb-Demi.eot'); /* IE9 Compat Modes */
  src: url('../fonts/LandRoverWeb-Demi.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/LandRoverWeb-Demi.woff') format('woff'), /* Modern Browsers */
       url('../fonts/LandRoverWeb-Demi.ttf')  format('truetype'); /* Safari, Android, iOS */

  font-style:   normal;
  font-weight:  500;
}
@font-face {
  font-family: 'lr_ot4';
  src: url('../fonts/LandRoverWeb-Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/LandRoverWeb-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/LandRoverWeb-Medium.woff') format('woff'), /* Modern Browsers */
       url('../fonts/LandRoverWeb-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */

  font-style:   normal;
  font-weight:  bold;
}
@font-face {
  font-family: 'lr_ot4';
  src: url('../fonts/LandRoverWeb-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/LandRoverWeb-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/LandRoverWeb-Light.woff') format('woff'), /* Modern Browsers */
       url('../fonts/LandRoverWeb-Light.ttf')  format('truetype'); /* Safari, Android, iOS */

  font-style:   normal;
  font-weight:  100;
}

@font-face {
  font-family: 'lr_ot4';
  src: url('../fonts/LandRoverWeb-Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/LandRoverWeb-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/LandRoverWeb-Medium.woff') format('woff'), /* Modern Browsers */
       url('../fonts/LandRoverWeb-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */

  font-style:   normal;
  font-weight:  normal;
}


/* Avenir copy font */
@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir_Heavy.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Avenir_Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Avenir_Heavy.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Avenir_Heavy.ttf')  format('truetype'); /* Safari, Android, iOS */

  font-style:   normal;
  font-weight:  bold;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir_Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Avenir_Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Avenir_Medium.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Avenir_Medium.ttf')  format('truetype'); /* Safari, Android, iOS */

  font-style:   normal;
  font-weight:  500;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir_Roman.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Avenir_Roman.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Avenir_Roman.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Avenir_Roman.ttf')  format('truetype'); /* Safari, Android, iOS */

  font-style:   normal;
  font-weight:  normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir_Roman-Oblique.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Avenir_Roman-Oblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Avenir_Roman-Oblique.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Avenir_Roman-Oblique.ttf')  format('truetype'); /* Safari, Android, iOS */

  font-style:   italic;
  font-weight:  normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/avenir_black-webfont.eot');
    src: url('../fonts/avenir_black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/avenir_black-webfont.woff2') format('woff2'),
         url('../fonts/avenir_black-webfont.woff') format('woff'),
         url('../fonts/avenir_black-webfont.ttf') format('truetype'),
         url('../fonts/avenir_black-webfont.svg#webfontregular') format('svg');
    font-weight: 900;
    font-style: normal;

}

@font-face{
	font-family:icons;
	src:url('../fonts/icons-74e4910bf5d0a67b62f1644d49361248.eot');
	src:url('../fonts/icons-74e4910bf5d0a67b62f1644d49361248.eot?#iefix') format("embedded-opentype"),
url('../fonts/icons-74e4910bf5d0a67b62f1644d49361248.woff') format("woff"),
url('../fonts/icons-74e4910bf5d0a67b62f1644d49361248.ttf') format("truetype"),
url('../fonts/icons-74e4910bf5d0a67b62f1644d49361248.svg?#icons') format("svg");
font-weight:400;
font-style:normal
}