.desktop-only {
  display: block;
}

.mobile-only {
  display: none !important ;
}

@media (max-width: 1024px) {
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: block !important;
  }
}

