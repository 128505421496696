/* Weiterentwicklung Kopfbereich */

/*

class = "kopfbereich $typ $height $align $style "

$typ
default = schlicht, nix
.mitbild = Bild im Hintergrund

$height
default = schlicht (wie Unterseiten, Impressum...)
.fullheight = volle Höhe (Hauptseiten) --> Scrolldown muss vorhanden sein
.subheight = 400px --> kein Scrolldown erfordlich, --> keine vertikale Zentrierung

$align
Kopfbereich kann folgende Textpositionierung haben:
default = zentriert
.linksbuendig = linksbuendig

$style = darkestgrey
(zum vererben von style-Angaben für smartphone-Version!) 

.lazy:before {
	content:'';
	width: 700px;
	height: 467px;
	display: block;
	background-color: blue;
}
loading customisation*/




.loading {
	background-size: 40px !important;
	background-repeat: no-repeat;
	background-position: center center !important;
	transition: none !important;
  position: relative;
}

.secondary-teaser-img {
	//transition: transform 0s !important;
}

.grey .lazy:before, .grey .lazy:after, .darkestgrey .lazy:before, .darkestgrey .lazy:after{

	background-color: #fff !important;
}

.lazy:before, .lazy:after  {
	content: '';
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #333;
	opacity: 0.9;
	position: absolute;
	top: 50%;
	left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  transform: scale(0);
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
}


.kopfbereich .lazy:before, .kopfbereich .lazy:after  {

	top: 6%;
	left: 5%;
}

.zambezi-logo .spinner, .zambezi-logo  .double-bounce1, .zambezi-logo  .double-bounce2 {
	display: none !important;
}

.zambezi-logo:before, .zambezi-logo:after  {
	display: none !important;
	width: 0px;
	height: 0px;
}

.kopf-content .spinner {
	display: none !important;
}




.lazy:after {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
	content: '';
}

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  transform: scale(0);
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.img-loaded {
  display: none;
}

.loaded:before, .loaded:after {
  content: none !important;
}

.Slider-Team .spinner {
  margin-top: 90px !important;
  top: 0;
}

.page-template-page-tour-2019-mit-location-php .GridListWrapper .spinner, .page-template-page-tour-2017-peru-map .GridListWrapper .spinner {
	margin-top: -30%;
}

.page-template-page-events .GridListWrapper .spinner, .page-template-page-reisen .GridListWrapper .spinner {
	margin-top: -20px !important;
}

.page-template-page-fahrangebote-php

.darkestgrey .double-bounce1, .darkestgrey .double-bounce2 {
/*background-color: #fff !important;*/
}

.page-template-page-tour-2019-mit-location-php .GridListWrapper .double-bounce1, .page-template-page-tour-2019-mit-location-php .GridListWrapper .double-bounce2, .page-template-page-tour-2017-peru-map .GridListWrapper .double-bounce2, .page-template-page-tour-2017-peru-map .GridListWrapper .double-bounce1 {
	background-color: #fff !important
}




.block .image {
  position: relative;
}

.kopfbereich .home .light a {
	display: block !important;
}



@keyframes sk-bounce {
	0%, 100% {
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	} 50% {
		  transform: scale(1.0);
		  -webkit-transform: scale(1.0);
	  }
}






/* Allgemein */
.kopfbereich {
	width: 100%;
	text-align: center;
	padding: 80px 90px;
	position: relative;
}
.kopfbereich.with-map {
	padding: 0px 90px 0px 90px;
}
@media screen and (min-width: 740px) {
	.kopfbereich.with-map {
		
		
	}
}
.kopfbereich.subheight {
	height: 500px;
}
@media screen and (min-width: 1px) and (max-width: 1600px) {
    .kopfbereich.subheight {
		height: 400px;
	}
}
.kopfbereich.fullheight {
	min-height: 400px;
}
@media screen and (max-width: 740px) {
.kopfbereich.fullheight {
	min-height: 100px;
}
}
.kopfbereich.linksbuendig {
	text-align: left;
}
@media (max-width: 740px) {
    .kopfbereich {
        padding: 40px 30px;
    }
	.kopfbereich.mitbild {
		height: auto !important;
		min-height: auto !important;
	}	
	.kopfbereich.with-map {
		padding: 0px 30px;
	}
}

.centered-broschure {
	display: inline !important;
}

.secondaryLinkWithStyle {
	position: relative !important;
}

.background-teaser-image-home {
	height: 100%;
	width: 100%;
	display: block;
	position: absolute;
	z-index: 4;
	pointer-events: none;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100;Neutral+Density */
	background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

/* Hero-Headline */
.kopfbereich h1 {
	padding: 0;
    font-family: lr_ot4, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 1.1em;
	max-width: 800px; /* zu pruefen */
	margin: 0 auto 0 auto; /* zu pruefen */
	
	font-size: 32px;
    font-weight: 500;
    margin: 0;
    max-width: 100%;
    padding: 0;
}
@media screen and (max-width: 740px) {
.kopfbereich h1 {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
	.page-template-page-fahrangebote .kopf-content, .page-template-page-reisen-php .kopf-content {

		max-width: 700px !important;

	}
}



/* Absatz */
.kopfbereich p {
    margin: 30px auto 0 auto;
    max-width: 800px;
	font-size: 16px;
}
.kopfbereich.darkestgrey p, .kopfbereich.grey p {
	color: #cbcbcb;
}
@media screen and (max-width: 740px) {
.kopfbereich p {
    margin: 20px auto 0 auto;
	font-size: 14px;
}	
}

/* Hintergrundbild */
.kopfbereich.mitbild {
	padding: 0;
	background: black;
}
.kopfbereich.mitbild .kopf-bild {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-size: cover;
}
@media screen and (max-width: 740px) {
.kopfbereich.mitbild .kopf-bild {
	position: relative;
	height: 250px;
}
}
@media screen and (min-width: 1px) and (max-width: 600px) {
.kopfbereich.mitbild .kopf-bild {height:250px;}
}
@media screen and (min-width: 1px) and (max-width: 390px) {
.kopfbereich.mitbild .kopf-bild {height:150px;}
}
@media screen and (min-width: 1px) and (max-width: 390px) {
.kopfbereich.mitbild.fullheight .kopf-bild {height:250px;}
}

.kopfbereich.mitbild .dark-image {
	opacity: 0.9;
	background-color: #000;
}

.kopfbereich.mitbild .kopf-content-wrap {
	display: inline-block;
    height: 100%;
	width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    vertical-align: middle;	
}
@media screen and (max-width: 740px) {
.kopfbereich.mitbild .kopf-content-wrap {
	display: block;
	position: relative;
	height: auto;
    padding: 30px 20px;
}
}
@media screen and (max-width: 740px) {
.kopfbereich.mitbild.bildschmal .kopf-content-wrap {
    padding: 0px;
}
}

.kopfbereich.mitbild .kopf-content {
    display: inline-block;
    margin: 0 auto;
    max-width: 1000px;
    vertical-align: middle;
	padding: 0 50px;
	max-width: 840px;
}

.kopfbereich.with-map .kopf-content {
	padding: 20px 0px 12px 0px;
	
}

.kopfbereich.mitbild.subheight .kopf-content {
	vertical-align: top;
	padding-top: 80px;
	max-width: 650px; /* war vorher 560px */
}
.page-id-2650 .kopfbereich.mitbild.subheight .kopf-content,
.page-id-2547 .kopfbereich.mitbild.subheight .kopf-content,
.page-id-2583 .kopfbereich.mitbild.subheight .kopf-content,
.page-id-2675 .kopfbereich.mitbild.subheight .kopf-content {
    max-width: 720px;
}
@media (max-width: 900px) {
.kopfbereich.mitbild.subheight .kopf-content {
	padding-top: 40px;
}
}
@media (max-width: 740px) {
.kopfbereich.mitbild.subheight .kopf-content {
	padding-top: 0;
	text-align: center;
}
}

/* mittig etwas ausgleichen wegen Scrolldown */
.kopfbereich.mitbild.fullheight .kopf-content {
	margin-bottom: 50px;
}
.home .kopfbereich.mitbild.fullheight .kopf-content {
	margin-bottom: 0;
}

@media (max-width: 900px) {
.kopfbereich.mitbild.mitscrolldown .kopf-content {
	margin-bottom: 0; /* hier gibt es keinen scrolldown */
}
}

.page-template-page-tour-above-and-beyond .kopfbereich.mitbild.fullheight .kopf-content {
	margin-bottom: 150px;
}

@media screen and (max-width: 900px) {
.kopfbereich.mitbild .kopf-content {
	padding: 0 40px;
}
}
@media screen and (max-width: 740px) {
.kopfbereich.mitbild .kopf-content {
	padding: 0;
    display: block;
    margin: 0 auto !important;
	width: 100%;	
}
}

/* vertically centered helper */
.mittighelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
@media screen and (max-width: 740px) {
.mittighelper {
	display: none;
}
}

/* Headline */
.kopfbereich.mitbild h1 {
    font-size: 48px;
    font-family: lr_ot4,Arial,Helvetica,sans-serif;
    font-weight: 500;
    line-height: 1.1em;
    letter-spacing: 0.15em;
	margin-bottom: 0;
}
@media screen and (max-width: 740px) {
	.kopfbereich.mitbild h1 {
        font-size: 26px;
    }
}

.with-map {
	background-color: #fff;
	color: #363636;
}

/* Absatz */
.kopfbereich.mitbild p {
	margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.12em;
    margin: 20px auto 0 auto;
    max-width: 600px;
	color: white;
}
.kopfbereich.mitbild.linksbuendig p {
	margin-left: 0;
	margin-right: 0;
}

@media screen and (max-width: 740px) {
	.kopfbereich.mitbild p {
        font-size: 14px;
		letter-spacing: 0;
		max-width: none;
		color: #cbcbcb;
    }
}


/* Basis-Schatten */
.kopfbereich .kopf-content {
	text-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
.kopfbereich .kopf-content.dark-shadow {
	text-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
}
.kopfbereich.with-map .kopf-content {
	text-shadow: none;
}

.kopfbereich.with-map.with-tour-logo-small .kopf-content {
	padding: 10px 0 5px 0;
}

/* Weitere Inhalte im kopf-content */
.kopf-logo {
	max-width: 100%;
}

.tour-logo {
	width: 350px; // 390px 
	height: auto;
	margin: 0 auto;
}
@media screen and (max-width: 740px) {
	.tour-logo {
		width: 300px;
		margin: 40px auto;
	}
}
@media screen and (min-width: 1px) and (max-width: 480px) {
	.tour-logo {
		width: 115px;
		margin: 20px auto;
	}
}
@media screen and (min-width: 740px) and (max-height: 700px) {
	.tour-logo {
		width: 250px;
	}
}
.ie9 .tour-logo[src*=".svg"] {
  width: 100%; 
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .tour-logo[src*=".svg"] {
    width: 100%; 
  }
}
.tour-logo-small {
	width: auto; 
	height: 80px;
	margin: 0 auto;	
}

.record-logo {
	width: 350px; // 390px 
	height: auto;
	margin: 0 auto 40px;
}
@media screen and (max-width: 1600px) {
	.record-logo {
		width: 300px;
	}
}
@media screen and (max-width: 740px) {
	.record-logo {
    position: absolute;
    top: -215px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    margin: 0 auto;
	}
}
@media screen and (min-width: 740px) and (max-height: 790px) {
	.record-logo {
		width: 200px;
		margin: 20px auto;
	}
}
.ie9 .record-logo[src*=".svg"] {
  width: 100%; 
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .record-logo[src*=".svg"] {
    width: 100%; 
  }
}

.zambezi-logo {
	width: 700px;
	height: auto;
	margin: 0 auto;
}
@media screen and (max-width: 1600px) {
	.zambezi-logo {
		width: 500px;
	}
}
@media screen and (max-width: 740px) {
	.zambezi-logo {
		width: 210px;
    position: absolute;
    top: -240px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
	}
}
@media screen and (min-width: 740px) and (max-height: 700px) {
	.zambezi-logo {
		width: 210px;
		margin: 0 auto 20px;
	}
}
.ie9 .zambezi-logo[src*=".svg"] {
  width: 100%; 
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .zambezi-logo[src*=".svg"] {
    width: 100%; 
  }
}

.zambezi-logo-small {
	max-width: 430px;
}
@media screen and (max-width: 1600px) {
	.zambezi-logo-small {
		max-width: 290px;
	}
}

.abt-logo {
	max-width: 75%;
}

.dmax-banner-desktop {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 9;
}
@media screen and (max-width: 740px) {
	.dmax-banner-desktop {
		display: none;
	}
}
.dmax-banner-mobile {
	position: relative;
	margin: 0 auto;
	display: block;
  height: auto;
  margin-bottom: -6px;	
}
.dmax-banner-mobile img {
	max-width: 100%;
	width: 100%;
	height: auto;
}
@media screen and (min-width: 741px) {
	.dmax-banner-mobile {
		display: none;
	}
}

.kopf-content small {
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 0.12em;
	font-weight: 500;
}
.with-map .kopf-content small {
	/*font-size: 11px;*/
}
@media screen and (max-width: 740px) {
.kopf-content small {
	font-size: 10px;
}
}

.kopf-content .button-wrap {
	margin-top: 20px;
}
.kopf-content .button {
	min-width: 200px;
	margin-top:10px;
}

/* Text mit Linien oben und unten */
p.prominent {
	margin-top: 20px;
	max-width: 70%;
}
p.prominent em {
	color: white;
	display: inline-block;
	font-style: normal;
	font-family: lr_ot4, Arial, Helvetica, sans-serif;
	line-height: 60px;
	font-size: 30px;
	letter-spacing: 2px;
	border-top:1px solid white;
	border-bottom:1px solid white;
	padding: 0 5px;
}

@media screen and (max-width: 740px) {
    p.prominent em {
        line-height: 40px;
        font-size: 12px;
    }
}

/* Countdown */
.countdown-wrap {
	margin-top: 40px;
}
@media screen and (min-width: 1px) and (max-width: 480px) {
	.countdown-wrap {
		margin-top: 0px;
		padding-bottom: 20px;
	}
}

.countdown-wrap p {
	color: white;
	display: block;
	font-style: normal;
	font-family: Avenir, Arial, Helvetica, sans-serif;
	line-height: 20px;
	font-size: 16px !important;
	letter-spacing: 0 !important;
}

.countdown-wrap em {
	color: white;
	display: inline-block;
	font-style: normal;
	font-weight: bold !important;
	font-family: lr_ot4, Arial, Helvetica, sans-serif;
	line-height: 60px;
	font-size: 37px !important;
	letter-spacing: 0 !important;
}
@media screen and (min-width: 1px) and (max-width: 480px) {
	.countdown-wrap em {
		line-height: 24px;
		font-size: 20px !important;
	}
}

.countdown-wrap .countdown-period {
	font-size: 19px !important;
}
@media screen and (min-width: 1px) and (max-width: 480px) {
	.countdown-wrap .countdown-period {
		font-size: 14px !important;
	}
}

/* ----------------------------------------- Scrolldown-Button */

#scrolldown-wrap {
	margin: 0 auto;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
}

.ScrollDown {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background-color: #0c121c;
    color: #fff;
    text-decoration: none;
    text-align: center;
    transition: background-color .5s,color .5s;
    position: relative;
    border: 0;
    z-index: 20;
}
@media (max-width: 900px) {
    #scrolldown-wrap {
        display: none;
    }
}
@media (max-height: 600px) {
    #scrolldown-wrap {
        display: none;
    }
}

.ScrollDown:after {
    font-family:'lrex',sans-serif;
    content: '\e90d';
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0;
    font-size: 25px;
    position: relative;
    top: -1px;
}

.ScrollDown:focus,
.ScrollDown:hover {
    background-color: #fff;
    color: #0c121c;
}


#stoerer-wrap {
	position: absolute;
	right: 10%;
	top: 10%;
	border: 1px solid #fff;
}
@media screen and (max-width: 768px) {
	#stoerer-wrap {
    	right: auto;
    	top: auto;
    	margin: 0 auto;
    	bottom: 10px;
    	left: 50%;
    	display: inline-block;
    	transform: translateX(-50%);
	}
}
@media screen and (max-width: 600px) {
	#stoerer-wrap {
		position: relative;
    	right: auto;
    	top: auto;
    	margin: 0 auto;
    	bottom: 10px;
    	left: auto;
    	display: inline-block;
    	transform: translateX(0);
	}
}

#stoerer-wrap .ScrollDown {
	font-size: 18px;
	display: block;
    height: auto;
    line-height: 25px;	
    padding: 15px;
	background: #E05206;
}
#stoerer-wrap .ScrollDown:focus,
#stoerer-wrap .ScrollDown:hover {
    background: #4e4e4e;
}
#stoerer-wrap .ScrollDown:after {
    line-height: 25px;
    height: 25px;
}
@media screen and (max-width: 600px) {
	#stoerer-wrap .ScrollDown {
		font-size: 14px;
	    line-height: 19px;	
	    padding: 10px;
	}	
}

.page-template-page-tour-2017-peru .ScrollDown {
    background: #E05206;
    border: 1px solid #fff;
}
.page-template-page-tour-2017-peru .ScrollDown:focus,
.page-template-page-tour-2017-peru .ScrollDown:hover {
    background: #4e4e4e;
}

.page-template-page-tour-2019-mit-location .kopf-content h2 {
	font-weight: bold;
}

/* ----------------------------------------- Video */

#video {
	position: absolute;
	bottom: 0px;
	right: 0px;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	overflow: hidden;
}

.video-js {
	width: auto !important;
	height: auto !important;	
}

video,
.video-js .vjs-tech {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%; 	
}

/* .kopfbereich.mitbild.darkestgrey für mobile Farben invertieren */
@media (max-width: 740px) {
	.kopfbereich.mitbild.darkestgrey,
	.kopfbereich.mitbild {
		background-color: #fff !important;
		background: #fff !important;
		color: #000 !important;

		h1, h2, h3, h4, h5, h6, p, a:not(.button) {
			color: #000 !important;
		}
	}
  .kopfbereich .kopf-content {
      background-image: none !important;
      text-shadow: none;
      text-align: center;
  }	
}

