@charset "UTF-8";
/*
Theme Name: LREX
Author: enabee
Author URI: http://www.enabee.com/
Version: 1.0

*/
/* --------------------------------------------- Allgemein */
* {
  box-sizing: border-box;
  outline: 0; }

body, html {
  overflow-x: hidden;
  font-family: 'Avenir',sans-serif;
  padding: 0;
  margin: 0;
  color: #fff;
  line-height: 1.5em;
  font-size: 14px;
  font-weight: 400; }

/* Font Smoothing für content = ZU PRÜFEN !! */
#content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* zu PRÜFEN !!! */ }

body {
  overflow: hidden;
  background-color: #393939; }

li {
  list-style-type: none; }

a {
  text-decoration: none; }

a:hover, a:active, a:focus {
  outline: 0; }

a img {
  border: none; }

sub, sup {
  font-size: 0.7em;
  position: relative; }

.nobr {
  white-space: nowrap; }

/*
input[type=text] {
    border: 1px solid #000;
    display:block;
    height: 40px;
    min-width: 240px;
    padding:0 10px;
}
*/
/* --------------------------------------------- Content-Style-Varianten */
/*
Varianten: 
1. white
2. light
3. grey
4. darkestgrey 
5. darkblue
*/
/* white */
.white {
  background-color: #fff;
  color: #000; }

.white a {
  color: #000; }

/* light */
.light {
  background-color: #e9ecec;
  color: #000; }

/* grey */
.grey, .darkgrey {
  background: #4a4f54; }

.grey a {
  color: white; }

/* darkestgrey */
.darkestgrey {
  background: #3f4347; }

.darkestgrey p {
  color: #cbcbcb; }

.darkestgrey a {
  color: white; }

.light a,
.light p,
.light h3 {
  color: #000; }

.darkblue {
  background: #0c212e;
  color: #fff; }

/* --------------------------------------------- Typo / Content */
p {
  max-width: 600px; }

h2 {
  font-family: lr_ot4, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  line-height: 1.1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto 0 auto;
  /* zu pruefen */ }

h1 a, h2 a, h3 a {
  text-transform: uppercase; }

h2 {
  font-size: 25px;
  font-weight: 200;
  margin: 0 auto 2ex auto;
  /* zu pruefen */ }

@media screen and (max-width: 740px) {
  h2 {
    /*   font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0.02em;
        font-weight: 600;
        margin: 0 0 10px 0;*/ } }

h3 {
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 10px;
  margin: 0; }

.primaryHeading {
  font-size: 32px;
  padding: 0;
  margin: 0;
  text-align: center;
  max-width: 100%;
  font-weight: 500; }

@media screen and (max-width: 740px) {
  .primaryHeading,
  .primaryHeading {
    font-size: 24px; } }

h2.secondaryHeading {
  font-size: 25px;
  /* war 32px */
  font-weight: 100;
  letter-spacing: 0.15em;
  line-height: 1.1em;
  text-align: center;
  margin: 0 auto 30px auto; }

@media screen and (max-width: 740px) {
  h2.secondaryHeading {
    font-size: 24px;
    line-height: 1.1em;
    letter-spacing: 0.15em;
    font-weight: 100; } }

h2.secondaryHeading + p {
  margin: 20px auto;
  max-width: 500px;
  text-align: center;
  display: block; }

h3 {
  font-weight: bold; }

/* Typo allgemein ??? */
p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5em;
  /* color: #c2c2c2; */
  letter-spacing: 0.015em; }

.itemContent p {
  font-size: 20px;
  margin-top: 50px; }

.clearfix {
  clear: both; }

.center {
  display: block !important;
  float: none;
  margin: auto !important; }

/* why? */
.Comparison {
  width: 200px; }

.total {
  background-color: #000;
  height: 100%;
  top: 0px;
  left: 0px; }

.separator {
  background-color: #c5c6c0;
  width: 100%;
  margin: auto;
  height: 1px; }

/* --------------------------------------------- 2 Gesamtlayout --------------------------------------------- */
.pageWrapper {
  margin: 0 auto;
  max-width: 2000px;
  padding: 0;
  position: relative; }

.new-cookie-notice {
  display: none;
  width: 100%;
  min-height: 90px;
  color: #fff;
  background: #4a4f54 !important;
  padding: 25px 50px 25px 20px;
  margin: 0 auto;
  max-width: 2000px;
  position: relative; }

@media (max-width: 1060px) {
  .new-cookie-notice {
    min-height: 160px; } }

@media (max-width: 900px) {
  .new-cookie-notice {
    margin-top: 50px; } }

.cookie-notice-text-wrap {
  float: left; }

@media (max-width: 1060px) {
  .cookie-notice-text-wrap {
    float: none;
    margin-bottom: 15px; } }

.cookie-notice-text-up {
  font-size: 20px;
  font-family: lr_ot4, Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  -moz-osx-font-smoothing: grayscale; }

.cookie-notice-text-down {
  font-family: 'Avenir',sans-serif;
  font-size: 16px;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale; }

.cookie-notice-button {
  float: right; }

@media (max-width: 1060px) {
  .cookie-notice-button {
    float: none; } }

#close-cookie-notice {
  font-size: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer; }

@media (min-width: 1060px) {
  #close-cookie-notice {
    top: 50%;
    transform: translateY(-50%); } }

@media (max-width: 900px) {
  .cookie-notice-visible #page {
    padding-top: 0px !important; } }

.iframe .new-cookie-notice {
  display: none !important; }

/* --------------------------------------------- Borelabs Cookiebox */
#BorlabsCookieBox .bottom-center ._brlbs-bar-wrap._brlbs-position-fixed {
  bottom: 0; }

#BorlabsCookieBox.options-open .bottom-center ._brlbs-bar-wrap._brlbs-position-fixed {
  top: 50%;
  transform: translate(0, -50%);
  width: auto !important; }

@media (max-width: 1200px) {
  #BorlabsCookieBox.options-open .bottom-center ._brlbs-bar-wrap._brlbs-position-fixed {
    top: 0;
    transform: translate(0, 0);
    width: 100% !important; } }

@media (max-width: 1200px) {
  #BorlabsCookieBox.options-open .cookie-preference .container.not-visible {
    max-height: 100% !important; } }

#BorlabsCookieBox.options-open .show-cookie-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999; }

#BorlabsCookieBox ._brlbs-bar,
#BorlabsCookieBox ._brlbs-box {
  background: #fff;
  border-radius: 0;
  color: #444444; }

#BorlabsCookieBox.options-open ._brlbs-bar,
#BorlabsCookieBox.options-open ._brlbs-box {
  background: #fff;
  border-radius: 0;
  color: #0c121c;
  padding-top: 40px; }

#BorlabsCookieBox.options-open ._brlbs-refuse {
  position: fixed;
  top: 1.3rem;
  right: 1rem;
  color: #000; }
  #BorlabsCookieBox.options-open ._brlbs-refuse a {
    font-size: 18px;
    display: block !important; }

#BorlabsCookieBox #CookieBoxTextHeadline {
  display: block; }

#BorlabsCookieBox p {
  max-width: 100%; }

#BorlabsCookieBox a {
  color: #4A4F54; }
  #BorlabsCookieBox a:hover {
    color: #005a2b; }

#BorlabsCookieBox #CookieBoxTextDescription a {
  text-decoration: underline; }

#BorlabsCookieBox a.cursor.d-block {
  color: #555; }

#BorlabsCookieBox .cookie-box ._brlbs-btn {
  border-radius: 0; }

#BorlabsCookieBox .col-12.col-sm-9 > ul,
#BorlabsCookieBox ._brlbs-bar ._brlbs-legal {
  display: none; }

@media (min-width: 576px) {
  #BorlabsCookieBox .col-12.col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  #BorlabsCookieBox .col-12.col-sm-9 {
    flex: 0 0 60%;
    max-width: 60%; } }

@media (min-width: 1200px) {
  #BorlabsCookieBox .col-12.col-sm-9 {
    flex: 0 0 70%;
    max-width: 70%; } }

@media (min-width: 576px) {
  #BorlabsCookieBox .col-12.col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;
    flex-direction: row-reverse;
    display: flex; } }

@media (min-width: 992px) {
  #BorlabsCookieBox .col-12.col-sm-3 {
    flex: 0 0 40%;
    max-width: 40%; } }

@media (min-width: 1200px) {
  #BorlabsCookieBox .col-12.col-sm-3 {
    flex: 0 0 30%;
    max-width: 30%; } }

@media (max-width: 575px) {
  #BorlabsCookieBox .col-12.col-sm-3 ._brlbs-manage {
    width: 100%; }
    #BorlabsCookieBox .col-12.col-sm-3 ._brlbs-manage a {
      width: 100%; } }

@media (min-width: 576px) {
  #BorlabsCookieBox .col-12.col-sm-3 ._brlbs-manage {
    margin: 20px 0 0 0;
    width: 50%; } }

@media (min-width: 1200px) {
  #BorlabsCookieBox .col-12.col-sm-3 ._brlbs-manage {
    margin: 0 20px 0 0;
    width: auto; } }

@media (min-width: 576px) {
  #BorlabsCookieBox .col-12.col-sm-3 ._brlbs-accept {
    margin: 20px 0 0 0;
    width: 50%; }
    #BorlabsCookieBox .col-12.col-sm-3 ._brlbs-accept a {
      width: auto; } }

@media (min-width: 1200px) {
  #BorlabsCookieBox .col-12.col-sm-3 ._brlbs-accept {
    width: auto;
    margin: 0; } }

.GridListWrapper:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden; }

.GridListWrapper {
  display: block;
  padding: 80px 90px;
  width: 100%; }

.GridListWrapper .items .el img:not(.attachment-teaser-big):not(.j-poster-image) {
  height: auto;
  width: 100%;
  border: 0;
  display: block;
  margin-bottom: 20px; }

.page-template-page-reisen .GridListWrapper .items .el img:not(.attachment-teaser-big):not(.j-poster-image) {
  margin-bottom: -6px; }

.page-template-page-fahrangebote .GridListWrapper .items .el img:not(.attachment-teaser-big):not(.j-poster-image) {
  margin-bottom: -6px; }

.GridListWrapper .items .el .fadein {
  height: auto;
  width: 100%;
  border: 0;
  display: block; }

/* Fahrdisziplinen-Abwandlung */
.GridListWrapper.runde-bilder .el {
  text-align: center; }

.GridListWrapper.runde-bilder .el img {
  width: 80%;
  margin: 2ex auto 4ex auto;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; }

@media (max-width: 740px) {
  .GridListWrapper .items .el img {
    margin-bottom: 15px; } }

.GridListWrapper.bordered .items .el img {
  padding-bottom: 0px;
  float: left;
  margin-bottom: 0px; }

.GridListWrapper .items[data-total="6"] .el {
  width: 16%;
  float: left; }

.GridListWrapper .items[data-total="4"] .el {
  width: 25%; }

.GridListWrapper .items[data-total="2"] .el {
  width: 49.9%; }

.GridListWrapper .items[data-total="1"] .el {
  width: 90%;
  float: none;
  display: block;
  margin: auto; }

.GridListWrapper .simple-content .el {
  width: 50% !important;
  float: none;
  display: block;
  margin: auto; }

@media (max-width: 740px) {
  .GridListWrapper .simple-content .el {
    width: auto !important; } }

.GridListWrapper .items .el {
  position: relative;
  display: inline-block;
  float: left;
  vertical-align: top;
  width: 33.3%;
  overflow: hidden; }

.GridListWrapper.padding .items .el {
  padding: 0 20px 30px 20px;
  /* padding unten war 40px */
  /* fuer events */ }

@media (max-width: 740px) {
  .GridListWrapper.padding .items .el {
    padding: 15px 0; } }

.GridListWrapper .subHeading {
  max-width: 600px;
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 10px;
  text-transform: uppercase; }

.GridListWrapper .subHeadingSpecial {
  display: block;
  margin: 0 auto 40px auto;
  text-align: center;
  width: 100%;
  max-width: 600px; }

.GridListWrapper .headings .button-wrap {
  text-align: center;
  margin-bottom: 20px; }

.GridListWrapper.light h3 a {
  text-transform: none; }

.GridListWrapper.light.bordered h3,
.GridListWrapper.light.bordered h3 a {
  text-transform: uppercase; }

.GridListWrapper.white p {
  color: #000;
  font-size: 14px;
  line-height: 1.5em; }

.GridListWrapper .heading {
  max-width: 860px; }

.twocol ul, .onecol ul {
  padding-left: 1.2em; }

.twocol li, .onecol li {
  list-style-type: inherit;
  /* list-style-type: cjk-ideographic; */ }

@media screen and (min-width: 1px) and (max-width: 1024px) {
  .GridListWrapper {
    padding: 40px; }
  .GridListWrapper .items[data-total="2"] .el, .GridListWrapper .items[data-total="4"] .el {
    width: 49.9% !important; }
  .GridListWrapper .items[data-total="3"] .el {
    width: 49.9% !important; }
  .GridListWrapper .items[data-total="6"] .el {
    width: 25% !important; } }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .GridListWrapper {
    padding: 30px 15px; }
  .GridListWrapper.Program {
    padding: 30px; }
  .GridListWrapper .items[data-total="2"] .el, .GridListWrapper .items[data-total="3"] .el, .GridListWrapper .items[data-total="4"] .el {
    width: 100% !important; } }

.GridListWrapper.FullWrapper .heading {
  text-align: center; }

.GridListWrapper.FullWrapper p {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  text-align: center; }

.GridListWrapper.FullWrapper a {
  text-align: center;
  margin: 0 auto;
  display: table; }

.GridListWrapper .freetext p {
  display: block;
  max-width: 800px;
  margin: 0 auto 10px auto; }

/* Layout fuer Angebote + Reisen */
.bordered .box {
  position: relative;
  width: 100%;
  border-right: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  float: left;
  padding: 30px 20px;
  box-sizing: border-box; }

.box h3 {
  text-align: center;
  font-size: 18px; }

.box h3 + p {
  padding-bottom: 20px;
  text-align: center; }

.box a {
  text-align: center;
  margin: 20px auto 0 auto !important; }

.box td {
  padding: 5px 0; }

.box .legal-info {
  padding: 20px 0;
  text-align: center;
  color: #666; }

.block div.left.onecol, .block div.right.onecol {
  border: medium none;
  float: left;
  padding: 40px 40px 40px 50px;
  width: 33.333333%;
  height: 100%; }

@media (max-width: 740px) {
  .block div.left.onecol, .block div.right.onecol {
    padding: 40px 30px 40px !important; } }

.block div.left.onecol > div, .block div.right.onecol > div {
  display: table;
  height: 100%;
  width: 100%; }

.block div.left.onecol > div > div, .block div.right.onecol > div > div {
  display: table-cell;
  vertical-align: middle;
  width: 100%; }

.block.secondary-teaser div.left.onecol > div, .block.secondary-teaser div.right.onecol > div {
  vertical-align: inherit; }

.block div.left.onecol h2, .block div.right.onecol h2, .block div.left p, .block div.right p {
  margin-bottom: 20px; }

@media screen and (min-width: 741px) {
  .block div.left.onecol h2, .block div.right.onecol h2 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; } }

@media screen and (max-width: 740px) {
  .block div.left.onecol h2, .block div.right.onecol h2 {
    /* font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0.02em;
        font-weight: 600;
        font-family: Avenir,Arial,Helvetica,sans-serif;*/ } }

#momente-teaser {
  background-size: 100%;
  background-position: center  80%; }

@media screen and (max-width: 1280px) {
  #momente-teaser {
    background-size: cover;
    background-position: center; } }

@media screen and (max-width: 900px) {
  #momente-teaser {
    background-size: 70% auto;
    background-position: center  80%; } }

@media screen and (max-width: 768px) {
  #momente-teaser {
    background-size: cover;
    background-position: center  80%; } }

.block div.left.onecol p,
.block div.right.onecol p {
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  color: #222; }

.block.grey div.left.onecol p,
.block.grey div.right.onecol p,
.block.darkestgrey div.left.onecol p,
.block.darkestgrey div.right.onecol p {
  color: #fff; }

.block.white div.left.onecol p,
.block.white div.right.onecol p {
  color: #363636; }

.block .onecol p a {
  text-decoration: underline; }

.block .onecol p a:hover {
  color: #e05206; }

.block div.left.twocol {
  border: medium none;
  float: left;
  width: 66.6%;
  height: 100%; }

.block div.right.twocol {
  float: right;
  text-align: center;
  width: 66.66%;
  height: 100%; }

/* neu */
.block div.full.twocol {
  float: left;
  text-align: center;
  width: 100%;
  height: 100%; }

.block div.right.onecol {
  float: right;
  padding: 40px 50px 40px 40px;
  width: 33.333333%;
  height: 100%; }

@media screen and (min-width: 901px) and (max-width: 1085px) {
  .block div.left.onecol h2, .block div.right.onecol h2 {
    font-size: 23px; }
  .block div.left.onecol, .block div.right.onecol {
    padding: 20px !important; } }

.block .image {
  height: 100%;
  background-size: cover; }

.block .fullHeightImage-wrap {
  padding: 40px;
  background-size: auto; }

.block .fullHeightImage {
  display: block;
  height: 100%;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.block {
  height: 800px; }

.page-template-page-formular-tourblogger .block {
  height: 600px; }

@media screen and (min-width: 1px) and (max-width: 1600px) {
  .block {
    height: 600px; } }

@media screen and (min-width: 1px) and (max-width: 1310px) {
  .page-template-page-tour-above-and-beyond .block {
    height: 700px; } }

@media screen and (min-width: 1px) and (max-width: 1490px) {
  .page-template-page-formular-tourblogger .block {
    height: 780px; } }

@media screen and (min-width: 1px) and (max-width: 1440px) {
  .page-template-page-tour-2017-peru-map .block {
    height: 780px; } }

@media screen and (min-width: 1px) and (max-width: 1160px) {
  .page-template-page-tour-2017-peru .block {
    height: 768px; } }

@media screen and (min-width: 1px) and (max-width: 925px) {
  .page-template-page-tour-2017-peru-map .block {
    height: 800px; } }

@media screen and (min-width: 1px) and (max-width: 900px) {
  .page-template-page-tour-2017-peru .block,
  .page-template-page-tour-2017-peru-map .block,
  .page-template-page-formular-tourblogger .block,
  .page-template-page-tour-above-and-beyond .block {
    height: auto; } }

@media screen and (min-width: 1px) and (max-width: 900px) {
  div.right, div.left {
    width: 100% !important; }
  .onecol {
    height: auto !important; }
  .block {
    height: auto; }
  .block .image {
    height: 500px; } }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .block .image {
    height: 250px; } }

@media screen and (min-width: 1px) and (max-width: 320px) {
  .block .image {
    height: 150px; } }

.block:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden; }

.block.videoblock {
  overflow: hidden; }

.block .video-wrap {
  position: relative; }
  .block .video-wrap .background-video {
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 900px) {
      .block .video-wrap .background-video {
        display: none; } }
  @media screen and (min-width: 901px) {
    .block .video-wrap .backgroundImage {
      display: none !important; } }
  .block .video-wrap .video-content-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
    @media screen and (max-width: 900px) {
      .block .video-wrap .video-content-wrap {
        position: relative; } }
    @media screen and (min-width: 901px) {
      .block .video-wrap .video-content-wrap .video-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 840px; } }
    @media screen and (max-width: 900px) {
      .block .video-wrap .video-content-wrap .video-content {
        position: relative;
        max-width: 100%;
        padding: 40px 30px; } }

/* --------------------------------------------- 3 Element spezifische Styles --------------------------------------------- */
/* --------------------------- Startseite: Secondary Teaser Alt -- */
/* Layout */
.block.secondary-teaser {
  height: inherit; }

.block.secondary-teaser .onecol {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  padding-top: 40px !important;
  /*  -moz-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;*/ }

.secondary-teaser a:hover .onecol {
  /* zoom effekt ? */
  /*opacity: .6;*/ }

.block.secondary-teaser .right.onecol {
  background-position: center top; }

.block.secondary-teaser > div > div {
  text-align: center; }

.block.secondary-teaser p {
  display: block;
  margin: auto;
  max-width: 300px; }

/* Content stylen */
.block.secondary-teaser .onecol h3, .block.secondary-teaser .onecol p {
  color: #FFFFFF !important;
  text-align: center; }

.block.secondary-teaser .onecol.black h3, .block.secondary-teaser .onecol.black p {
  color: #000000 !important; }

/* Typo */
.block.secondary-teaser .onecol h3 {
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px; }

/* --------------------------- Startseite: Secondary Teaser Neu -- */
/* Layout */
.block.secondary-teaser {
  height: inherit; }

.block.secondary-teaser .secondary-teaser-wrap {
  border: none;
  width: 33.333333%;
  height: 100%;
  min-height: 300px;
  float: left;
  overflow: hidden;
  position: relative;
  background-color: #000; }

@media screen and (min-width: 1px) and (max-width: 900px) {
  .block.secondary-teaser .secondary-teaser-wrap {
    width: 100% !important; } }

@media screen and (min-width: 801px) and (max-width: 900px) {
  .block.secondary-teaser .secondary-teaser-wrap {
    min-height: 370px; } }

@media screen and (min-width: 700px) and (max-width: 800px) {
  .block.secondary-teaser .secondary-teaser-wrap {
    min-height: 350px; } }

.block.secondary-teaser .secondary-teaser-img {
  border: none;
  position: absolute;
  height: 100%;
  width: 100%;
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  -moz-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat; }

.block.secondary-teaser a:hover .secondary-teaser-img {
  -moz-transform: scale(1.2, 1.2);
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
  opacity: .9; }

.block.secondary-teaser a:hover .secondary-teaser-img.scale-less {
  -moz-transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  opacity: .9; }

.block.secondary-teaser .secondary-teaser-text {
  position: relative;
  padding: 40px;
  z-index: 5; }

/* Content stylen */
.block.secondary-teaser .secondary-teaser-text h3,
.block.secondary-teaser .secondary-teaser-text p {
  color: #FFFFFF !important;
  text-align: center; }

.block.secondary-teaser .secondary-teaser-text.black h3,
.block.secondary-teaser .secondary-teaser-text.black p {
  color: #000000 !important; }

/* Typo */
.block.secondary-teaser .secondary-teaser-text h3 {
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px; }

.block.secondary-teaser .secondary-teaser-text p {
  display: block;
  margin: auto;
  max-width: 300px; }

/* --------------------------- Startseite: Icon-Infos -- */
.StartPageCategories .items[data-total="2"] .el {
  padding: 0 60px 0 70px;
  position: relative; }

.StartPageCategories .el a span {
  color: #cbcbce;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 5px;
  margin-left: -5px; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .StartPageCategories .el a span, .StartPageCategories .el h3, .StartPageCategories .el p, .StartPageCategories .el .secondaryLinkWithStyle {
    position: relative;
    text-align: center; }
  .StartPageCategories .el a span {
    padding-bottom: 10px; }
  .StartPageCategories .el .secondaryLinkWithStyle {
    display: block !important;
    padding: 0px !important;
    margin: 10px auto 30px !important; }
  .secondaryLinkWithStyle:before {
    left: -2px !important;
    position: relative !important;
    top: 0px !important; }
  .StartPageCategories .items[data-total="2"] .el {
    padding: 0px; } }

.StartPageCategories .el a:hover span:before {
  background-color: #3e3e3e;
  transition: background-color 300ms linear 0s; }

.StartPageCategories .el a span:before {
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
  display: inline-block;
  font-size: 23px;
  font-weight: normal;
  height: 50px;
  line-height: 50px;
  margin-right: 8px;
  text-align: center;
  transition: background-color 300ms linear 0s;
  width: 50px; }

/* Typo */
.StartPageCategories h3 a {
  margin-bottom: 10px;
  padding: 0;
  text-transform: uppercase !important;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px; }

/* --------------------------- Startseite: Icon-Infos -- */
.facts {
  margin: 20px 0 14px;
  padding: 0; }

.facts li {
  position: relative;
  padding: 0 0 12px 35px;
  min-height: 40px; }

.facts li .icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px; }

.facts-card-wrap {
  max-width: 400px; }

.carouselWrapper ul li {
  display: block;
  float: left;
  width: 33.3333%;
  padding: 0 40px; }

.carouselWrapper ul li img {
  width: 100%; }

.cars {
  text-align: center; }

.cars .headings {
  padding-bottom: 60px; }

.cars .number {
  position: absolute;
  left: 50px;
  /* top:-25px; ist nicht sichtbar wegen el=overflow hidden */
  font-size: 25px; }

.pros, .specs, .prosText {
  padding: 0; }

.pros li {
  position: relative;
  min-height: 25px;
  display: block;
  padding: 0 0 8px 22px; }

.pros li .icon {
  position: absolute;
  top: 0px;
  left: 0; }

#rtd-wrap .text p {
  color: #393939; }

.prosText {
  margin-bottom: 20px; }

.prosTextTeaser li {
  padding: 0 0 8px 0px; }

/* ------------------------ Query Box */
.QueryBox {
  padding: 0 0 0 40px;
  vertical-align: middle; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .QueryBox {
    padding: 0 0 0 0; } }

.QueryBox p {
  font-size: 14px !important; }

.QueryBox h5 {
  clear: both;
  margin: 0 0 0 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.4; }

.freitext-feld {
  margin-bottom: 20px;
  max-width: 400px; }

.freitext-feld h5 {
  margin-bottom: 15px; }

.freitext-feld p {
  margin-bottom: 15px; }

.freitext-feld em {
  font-size: 11px;
  line-height: 15px;
  max-width: 300px;
  color: gray;
  display: block;
  clear: both; }

.termine-wrap {
  margin-bottom: 20px; }

.termine-wrap-remove {
  margin-top: -20px; }

.preise-wrap {
  margin-bottom: 20px; }

.preis-spalte1 {
  float: left; }

.preis-spalte2 {
  padding-left: 20px;
  float: left; }

.preis-zusatzinfo {
  font-size: 11px;
  line-height: 15px;
  max-width: 300px;
  color: gray;
  display: block;
  clear: both; }

.page-id-2547 .preis-zusatzinfo {
  max-width: 350px; }

.preis-zusatzinfo a {
  color: gray;
  text-decoration: underline; }

.preis-zusatzinfo a:hover {
  color: #000; }

.reiseleistungen-zusatzinfo {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  max-width: 300px;
  color: #000;
  display: block;
  clear: both; }

.reiseleistungen-zusatzinfo a {
  color: #000;
  text-decoration: underline; }

.reiseleistungen-zusatzinfo a:hover {
  color: gray; }

#reiseleistungen-popup ul {
  margin: 0;
  padding: 0 20px; }

#reiseleistungen-popup li {
  list-style-type: disc; }

.kontakt-aufforderung {
  font-weight: 600;
  margin-top: 20px;
  max-width: 400px; }

.ansprechpartner {
  margin-top: 20px; }

/* Formatierung Telefonblock - allgemein */
.phone-block {
  font-size: 14px !important; }

.QueryBox .phone-block {
  margin-top: 20px; }

.phoneNumber a {
  text-decoration: none !important; }

.phoneNumber {
  font-weight: bold;
  display: block;
  font-size: 20px; }

.QueryBox .phoneNumber {
  margin-top: 10px; }

.phoneNumber .icon-phone {
  font-size: 20px;
  width: 20px; }

.phoneTime {
  font-size: 11px;
  color: gray;
  padding-left: 28px;
  display: block; }

/* Dropdown */
.ui-icon {
  background-image: url("../images/dropdown.png");
  height: 16px;
  width: 14px; }

.QueryBox .button, .QueryBox .button {
  margin-top: 0; }

.ui-selectmenu-button {
  background-color: #fff;
  vertical-align: middle; }

.selectpicker {
  margin: 0 10px;
  vertical-align: middle;
  width: 55px; }

/* Weitere Hinweise */
.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
  color: #333; }

/* Karte und Untergründe */
.GridListWrapper.padding.karte-und-untergruende .items .el {
  padding: 0 20px 0 20px; }

@media (max-width: 740px) {
  .GridListWrapper.padding.karte-und-untergruende .items .el {
    padding: 15px 0; } }

.karten-image-wrap {
  margin: 0 auto; }

.icon-bereich {
  font-family: Avenir,sans-serif !important;
  padding: 0 0 0 40px;
  vertical-align: middle;
  border-left: 1px solid #3d535d; }

@media (max-width: 740px) {
  .icon-bereich {
    padding: 0;
    border-left: none; } }

.abenteuercharakter-wrap {
  margin-bottom: 40px; }

.abenteuer-icon {
  display: inline-block !important;
  width: 25px !important;
  margin-right: 5px;
  margin-bottom: 0px !important;
  opacity: 0.2; }

.abenteuer-icon.full {
  opacity: 1; }

.untergruende-wrap ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0; }

.untergruende-wrap li {
  position: relative;
  padding-left: 60px;
  line-height: 50px; }

.untergruende-wrap .untergrund-icon {
  position: absolute;
  top: 0;
  left: -7px;
  width: 60px !important;
  margin-bottom: 0px !important; }

/* ------- Timeline (Fahrangebote und Reisen) */
.Timeline {
  position: relative;
  padding-left: 30px;
  padding-top: 31px;
  margin-left: 50%; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .Timeline {
    padding-left: 0;
    padding-top: 0;
    margin-left: 0; } }

.single-image-timeline .Timeline {
  margin-left: 0; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .GridListWrapper .items .el.single-image-timeline {
    overflow: initial; } }

.Timeline li {
  position: relative;
  margin-bottom: 50px;
  list-style-type: none;
  z-index: 2; }

.Timeline time {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 3px; }

.Timeline .Timestop {
  padding-left: 63px;
  max-width: 448px; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .Timeline .Timestop {
    padding-left: 10px;
    max-width: 100%;
    margin-bottom: 53px; } }

@media screen and (min-width: 1px) and (max-width: 400px) {
  .Timeline .Timestop {
    padding-left: 3px; } }

.Timeline .Timestop.with-slider {
  min-height: 370px; }

@media screen and (min-width: 1px) and (max-width: 980px) {
  .Timeline .Timestop.with-slider {
    min-height: 370px; } }

.Timeline .Timestop:before {
  content: '';
  height: 20px;
  width: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  position: absolute;
  left: -21px;
  top: 3px; }

.white .Timestop:before {
  background-color: #fff; }

.light .Timestop:before {
  background-color: #e9ecec; }

.grey .Timestop:before,
.darkgrey .Timestop:before {
  background-color: #4a4f54; }

.darkestgrey .Timestop:before {
  background-color: #3f4347; }

.darkblue .Timestop:before {
  background-color: #0c212e; }

.Timeline .Timestop:after {
  content: '';
  background-color: #6dd14d;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: -16px;
  top: 8px; }

.Timeline .line {
  display: block;
  background-color: #ccc;
  position: absolute;
  top: 31px;
  left: 28px;
  width: 2px;
  height: 100%;
  list-style-type: none;
  z-index: 1; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .Timeline .line {
    top: 0;
    left: 18px; } }

.Timeline .head-stripe {
  width: 38px;
  height: 2px;
  background-color: #ccc;
  position: absolute;
  top: 12px;
  left: 3px; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .Timeline .head-stripe {
    display: none; } }

.Timeline .Timestop ul {
  padding: 0; }

.Timeline .Timestop ul li {
  margin-bottom: 0; }

.Timeline .Timestop ul li ul {
  padding-left: 20px; }

.Timeline-Slider-Wrapper {
  position: absolute;
  top: 11px;
  left: -430px;
  width: 365px;
  height: 340px; }

@media screen and (min-width: 1px) and (max-width: 980px) {
  .Timeline-Slider-Wrapper {
    left: -400px;
    width: 335px;
    height: 350px; } }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .Timeline-Slider-Wrapper {
    position: relative;
    max-width: 100%;
    top: 15px;
    left: auto; } }

@media screen and (min-width: 1px) and (max-width: 320px) {
  .Timeline-Slider-Wrapper {
    width: 280px;
    height: 293px; } }

.Timeline-Single-Image-Wrap {
  margin: 0 auto; }

.Timeline-Single-Image-Wrap img {
  max-width: 100%;
  width: 100%;
  height: auto; }

.Timeline .Single-Slider {
  height: 100%;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  padding: 12px 12px 70px 12px;
  background-color: #fff; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .Timeline .Single-Slider {
    padding: 10px 10px 64px 10px; } }

.Timeline .Single-Slider .image {
  height: 100%;
  background-size: cover;
  background-position: center center; }

.Timeline .pager {
  position: absolute;
  left: 12px;
  bottom: 25px;
  color: #000; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .Timeline .pager {
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px; } }

.Timeline .pager .slick-prev:before,
.Timeline .pager .slick-next:before {
  color: #000; }

@media screen and (min-width: 1px) and (max-width: 400px) {
  .Program {
    padding: 50px 10px 50px 25px; } }

.fulltext p {
  margin: 0 auto 20px auto; }

.page-template-page-formular-bar a {
  text-decoration: underline; }

.page-template-page-formular-bar .kopf-content {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.9) !important; }

.brochure-block {
  /* border-top: 1px solid #e4e8e8; */
  background: #ececec;
  color: #000; }

.brochure-logo {
  width: 300px;
  margin: 0 auto 20px;
  display: block; }

@media screen and (min-width: 1px) and (max-width: 768px) {
  .brochure-logo {
    width: 200px; } }

/* --------------------------- Buch-Seite -- */
.buch-sectionWrapper {
  position: relative; }

#buch-grafik {
  position: absolute;
  top: -140px;
  left: 0; }

#buch-grafik img {
  width: 190px; }

@media screen and (max-width: 740px) {
  #buch-grafik {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center; }
  #buch-grafik img {
    width: auto;
    max-width: 100%;
    margin-bottom: 20px; } }

/* ------------------------- FAQ */
.accordion:not(.pseudo-accordion) {
  border-top: 1px solid #e1e1e1; }

.accordion:not(.pseudo-accordion) > div {
  border-bottom: 1px solid #e1e1e1; }

.accordion h3 {
  font-weight: normal;
  border-bottom: 1px solid #e1e1e1;
  min-height: 50px;
  vertical-align: middle;
  padding-top: 14px;
  padding-bottom: 10px;
  cursor: pointer; }

.accordion h3 .icon {
  margin-right: 5px; }

.accordion div:not(.pseudo-accordion-trigger) {
  display: none;
  padding: 10px 0; }

.accordion p {
  margin-bottom: 15px; }

.accordion a {
  text-decoration: underline; }

.accordion a:hover {
  color: #666; }

.pager {
  position: relative;
  width: 140px;
  margin: auto; }

.accordion .pseudo-accordion-trigger {
  font-weight: normal;
  min-height: 30px;
  vertical-align: middle;
  padding-top: 14px;
  padding-bottom: 10px;
  cursor: pointer; }

.accordion .pseudo-accordion-trigger .icon {
  margin-right: 5px; }

.newsletter input, .newsletter .primaryLinkWithStyle {
  min-width: 300px; }

.more {
  width: 200px;
  margin-top: 20px; }

.price {
  font-weight: bold; }

.Picker {
  height: 60px; }

.Picker li {
  float: left; }

.ContactForm ul {
  padding: 0; }

.ContactForm .el:first-child {
  padding: 0 100px 0 130px !important; }

.ContactData label {
  display: block;
  padding: 10px 0 5px 0; }

.ContactData input {
  width: 400px; }

.ContactFormTour .items[data-total="2"] .el {
  padding: 0 40px; }

@media screen and (min-width: 1px) and (max-width: 1024px) {
  .ContactFormTour .items[data-total="2"] .el {
    padding: 0 30px; } }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .ContactFormTour .items[data-total="2"] .el {
    padding: 0px; } }

.ContactFormTour .input-wrap {
  clear: both;
  float: left;
  margin-bottom: 20px;
  position: relative;
  width: 100%; }

.ContactFormTour label {
  clear: both;
  display: block;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 4px;
  cursor: pointer;
  width: 100%;
  max-width: 400px; }

.ContactFormTour input[type="text"], .ContactFormTour input[type="radio"], .ContactFormTour textarea {
  font-family: Avenir,Arial,Helvetica,sans-serif;
  display: block;
  background: #EAEAEA;
  border: none !important;
  padding: 12px 10px;
  font-size: 16px;
  line-height: 16px;
  color: #173F5F;
  border-radius: 0;
  /* fuer iPhone */
  width: 400px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */ }

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .ContactFormTour input[type="text"], .ContactFormTour input[type="radio"], .ContactFormTour textarea {
    width: 100%; } }

.ContactFormTour input[type="submit"] {
  width: initial;
  -webkit-appearance: none;
  /* fuer iOS */
  border-radius: 0;
  /* fuer iPhone */
  margin: 10px 0; }

::-webkit-input-placeholder {
  color: #000; }

:-moz-placeholder {
  color: #000;
  /* Firefox 18- */ }

::-moz-placeholder {
  color: #000;
  /* Firefox 19+ */ }

:-ms-input-placeholder {
  color: #000; }

/* ------------------------ Checkbox */
.ContactFormTour .checkbox-wrap {
  clear: none;
  float: left;
  margin-right: 25px; }

.ContactFormTour label.checkbox-wrap, .ContactFormTour .checkbox-wrap input {
  width: auto !important;
  padding: 0 !important;
  background: none !important; }

.ContactFormTour .checkbox-wrap span {
  padding: 8px 0 0 8px;
  float: left; }

/* langes label bei checkboxes */
.ContactFormTour .checkbox-wrap.longtext {
  margin-top: 4px;
  margin-right: 0; }

.ContactFormTour .checkbox-wrap.longtext .ez-checkbox {
  position: absolute;
  top: 4px;
  left: 0; }

.ContactFormTour .checkbox-wrap.longtext span {
  padding: 6px 0 0 38px;
  float: left;
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 4px;
  min-height: 20px; }

.ContactFormTour .ez-hide {
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 100px;
  cursor: pointer; }

.ContactFormTour .ez-checkbox, .ContactFormTour .ez-radio {
  zoom: 1;
  display: inline-block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  float: left; }

.ContactFormTour .ez-checkbox {
  background: #EAEAEA url("../images/input_checkbox.png") 0 0 no-repeat; }

.ContactFormTour .ez-radio {
  background: #EAEAEA url("../images/input_checkbox.png") 0 0 no-repeat; }

.ContactFormTour .ez-checked {
  background-position: 0 -30px !important; }

.ContactFormTour .ez-selected {
  background-position: 0 -30px !important; }

/* ------------------------ Fehlermeldung */
.ContactFormTour .input-wrap.fehler input, .ContactFormTour .input-wrap.fehler .ez-radio, .ContactFormTour .input-wrap.fehler .ez-checkbox, .ContactFormTour .input-wrap.fehler .dk_toggle {
  border: 1px solid #E05206 !important; }

.ContactFormTour label.error {
  color: #E05206;
  font-size: 12px !important;
  padding-top: 0;
  text-transform: none;
  letter-spacing: 0;
  padding-bottom: 0; }

#form-bewerbung-tourblogger.ContactFormTour label {
  text-transform: none; }

/* Platzmangel-Loesung fuer das KMI-Formular der Startseite */
@media only screen and (min-width: 1000px) {
  #form-nexttour label.error {
    position: relative;
    top: 10px;
    left: -20px;
    height: 14px;
    padding-left: 20px;
    background: url(../images/error.png);
    background-repeat: no-repeat;
    border-radius: 10px;
    z-index: 100;
    /*text-indent: -10000px;*/ }
  #form-nexttour .mit-checkbox label.error {
    top: 9px; } }

@media only screen and (min-width: 1000px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1000px) and (min-resolution: 192dpi) {
  #form-nexttour label.error {
    background: url(../images/error-2x.png);
    background-size: 14px 14px;
    background-repeat: no-repeat; } }

/* ------------------------ Fehlermeldung */
#form-tourcamp .input-wrap.fehler input, #form-tourcamp .input-wrap.fehler .ez-radio, #form-tourcamp .input-wrap.fehler .ez-checkbox, #form-tourcamp .input-wrap.fehler .dk_toggle {
  border: 1px solid #E05206 !important; }

#form-tourcamp label.error {
  color: #E05206;
  font-size: 12px !important;
  padding-top: 0;
  text-transform: none;
  letter-spacing: 0;
  padding-bottom: 0; }

@media only screen and (min-width: 1000px) {
  #form-tourcamp label.error {
    position: relative;
    top: 5px;
    left: 0px;
    height: 14px;
    padding-left: 20px;
    background: url(../images/error.png);
    background-repeat: no-repeat;
    border-radius: 10px;
    z-index: 100;
    margin-bottom: 15px; }
  #form-tourcamp .mit-checkbox label.error {
    top: 9px; } }

@media only screen and (min-width: 1000px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1000px) and (min-resolution: 192dpi) {
  #form-tourcamp label.error {
    background: url(../images/error-2x.png);
    background-size: 14px 14px;
    background-repeat: no-repeat; } }

/* ------------------------ Erfolgsmeldung */
#tourcamp-success.success {
  display: none;
  /* fuer ajax form */
  color: #00693C;
  font-size: 26px;
  margin-top: 20px;
  height: 60px;
  line-height: 30px; }

/* ------------------------ Erfolgsmeldung */
.ContactFormTour .success {
  display: none;
  /* fuer ajax form */
  color: #00693C;
  font-size: 26px;
  margin-top: 20px;
  min-height: 60px;
  line-height: 30px; }

/* ------------------------ Abwandlung nexttour-Formular */
@media screen and (min-width: 1px) and (max-width: 740px) {
  #form-nexttour {
    margin-top: 30px; } }

#form-nexttour label {
  font-size: 16px;
  line-height: 16px;
  text-transform: none;
  letter-spacing: 0; }

#form-nexttour label.overlabel-apply {
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 1; }

/* ------------------------ Abwandlung tourcamp-Formular */
.button.tourcampbutton {
  text-align: center;
  margin: 0 auto;
  display: table; }

.onecol .button.tourcampbutton {
  margin: 0px;
  display: block; }

span.tourcampbutton {
  text-decoration: underline;
  cursor: pointer; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #form-tourcamp {
    margin-top: 30px; } }

#form-tourcamp label {
  font-size: 16px;
  line-height: 16px;
  text-transform: none;
  letter-spacing: 0; }

#form-tourcamp label.overlabel-apply {
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 1; }

/* ------------------------ Abwandlung book-Formular */
.page-template-page-buch-formular #buch-grafik {
  position: relative;
  top: 0;
  left: 0; }

.ContactFormBook .input-wrap {
  width: 100%; }

.ContactFormBook label {
  padding-top: 0; }

.page-template-page-buch-formular .ui-selectmenu-button {
  border: 1px solid #fff; }

.page-template-page-buch-formular .ui-selectmenu-menu .ui-menu {
  border: 1px solid #ccc;
  padding-bottom: 0; }

.page-template-page-buch-formular .ui-menu .ui-state-focus,
.page-template-page-buch-formular .ui-menu .ui-state-active {
  margin: 0;
  background: #218cfd; }

.ContactFormBook input[type="text"],
.ContactFormBook input[type="radio"],
.ContactFormBook textarea {
  width: 100%;
  background: #fff; }

.ContactFormTour .ez-radio {
  background: #fff url(../images/input_checkbox.png) 0 0 no-repeat; }

.ContactFormTour p {
  margin-bottom: 20px; }

.ContactFormTour .input-wrap-submit .button {
  margin-bottom: 30px; }

.ContactFormBook .success {
  margin-top: 0; }

/* longtext abgewandelt fuer startseite */
#form-nexttour .longtext {
  font-size: 11px;
  line-height: 15px;
  margin-top: 0; }

#form-nexttour .longtext span {
  padding-top: 0 !important;
  margin-bottom: 0;
  min-height: 32px; }

#form-nexttour .longtext a {
  text-transform: none;
  text-decoration: underline; }

#form-nexttour .checkbox-wrap.longtext .ez-checkbox {
  top: 0; }

@media only screen and (min-width: 0) and (max-width: 630px) {
  #nexttour-datenschutz {
    padding: 10px; }
  #nexttour-datenschutz .popup-headline {
    font-size: 20px;
    line-height: 20px; } }

#form-bar-influencer label {
  text-transform: inherit; }

.Selector {
  margin: 10px 0 25px 0; }

.slick-slider h3 {
  text-transform: uppercase; }

.slick-slider p {
  font-size: 14px !important;
  line-height: 1.5em;
  letter-spacing: 0.02em; }

.Single-Slider, .Single-Slider-auto {
  height: 90%; }

.Single-Slider .slick-list, .Single-Slider .slick-track, .Single-Slider .el,
.Single-Slider-auto .slick-list, .Single-Slider-auto .slick-track, .Single-Slider-auto .el {
  height: 100%; }

#partner-popup {
  padding: 30px 20px 20px 20px; }

.partner-popup-container {
  width: 100%; }

.partner-popup-row {
  width: 100%;
  margin-bottom: 20px; }

@media screen and (max-width: 900px) {
  .partner-popup-row {
    margin-bottom: 40px; } }

.partner-popup-container .left.onecol {
  float: left;
  width: 33.3%; }

@media screen and (max-width: 900px) {
  .partner-popup-container .left.onecol {
    text-align: center; } }

.partner-popup-container .left.onecol img {
  width: 180px;
  max-width: 100%; }

@media screen and (max-width: 900px) {
  .partner-popup-container .left.onecol img {
    margin-bottom: 20px; } }

.partner-popup-container .right.twocol {
  float: right;
  width: 66.6%;
  padding-left: 15px; }

@media screen and (max-width: 900px) {
  .partner-popup-container .right.twocol {
    padding-left: 0px; } }

.social-wrapper .juicer-feed h1.referral {
  display: none !important; }

.social-wrapper [data-feed-id="landroverexp"] .j-poster {
  display: none; }

.social-wrapper .juicer-feed .j-poster a {
  display: block; }

.social-wrapper .juicer-feed .j-stack .feed-item {
  border: none; }

.social-wrapper .juicer-feed h3 {
  font-family: Avenir,sans-serif; }

.social-wrapper .juicer-feed p {
  font-family: Avenir,sans-serif;
  line-height: 1.5em;
  font-size: 14px;
  font-weight: 400; }

.social-wrapper .juicer-feed a.juicer-button {
  width: 250px;
  display: block; }

.social-wrapper .juicer-feed .j-meta a:hover {
  color: #333; }

.social-wrapper .juicer-feed .j-paginate {
  margin-bottom: 40px; }

.social-wrapper .social-info-text {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 600px; }

.social-wrapper .social-info-text p {
  line-height: 1.5em;
  font-size: 12px;
  font-weight: 400; }

.j-loading {
  border-color: #333 !important; }

.j-loading:before {
  background-color: #333 !important; }

.j-poster h3,
.j-poster .j-date,
.j-message p,
.j-post-overlay.juicer-feed .j-overlay-text {
  font-family: Avenir,sans-serif; }

.j-post-overlay.juicer-feed .j-overlay-text {
  padding: 35px 25px 25px; }

.j-overlay .j-close {
  top: 15px;
  right: 10px; }

.fallingSnow {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 500;
  opacity: 0; }

.fallingSnow span {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: contain;
  margin: -500px 60px 54px -34px;
  background-image: url(../images/snowflake.png);
  animation: fallingSnow 12s infinite cubic-bezier(0.35, 0.45, 0.39, 0.29); }

.fallingSnow span:nth-child(5n+5) {
  animation-delay: 1.3s; }

.fallingSnow span:nth-child(3n+2) {
  animation-delay: 1.5s; }

.fallingSnow span:nth-child(2n+5) {
  animation-delay: 1.7s; }

.fallingSnow span:nth-child(3n+10) {
  animation-delay: 2.7s; }

.fallingSnow span:nth-child(7n+2) {
  animation-delay: 3.5s; }

.fallingSnow span:nth-child(4n+5) {
  animation-delay: 5.5s; }

.fallingSnow span:nth-child(10) {
  animation-delay: 8s; }

.fallingSnow span:nth-child(17) {
  animation-delay: 1s; }

@keyframes fallingSnow {
  0% {
    opacity: 1;
    -webkit-transform: translate(0, 0px) rotateZ(0deg); }
  75% {
    opacity: 1;
    -webkit-transform: translate(100px, 200px) rotateZ(270deg); }
  100% {
    opacity: 0;
    -webkit-transform: translate(150px, 300px) rotateZ(360deg); } }

.page-child .button {
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 10px !important;
  font-weight: 700;
  letter-spacing: .3em !important;
  line-height: 1.4em !important; }

.section-sub-headline {
  font-size: 16px;
  padding-top: 40px;
  padding-bottom: 30px;
  color: rgba(255, 255, 255, 0.6);
  max-width: 650px;
  margin: 0 auto; }

/* Buttons weiter entwickeln */
/* button $style $outlinestyle  */
.button,
.juicer-feed a.juicer-button,
.j-post-overlay.juicer-feed a.juicer-button,
._brlbs-btn-accept-all,
#BorlabsCookieBox ._brlbs-manage a,
#BorlabsCookieBox ._brlbs-accept a,
#BorlabsCookieBox .cookie-preference ._brlbs-btn {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0);
  border: 1px solid #000;
  border-radius: 0;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.3em;
  line-height: 12px;
  min-width: 125px;
  overflow: hidden;
  padding: 15px 24px 14px;
  text-align: center;
  text-decoration: none !important;
  text-shadow: none;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  text-transform: uppercase; }

#BorlabsCookieBox .cookie-preference ._brlbs-btn-accept-all,
#BorlabsCookieBox ._brlbs-accept a {
  background: black;
  border: 1px solid black;
  color: white; }

#BorlabsCookieBox .cookie-preference ._brlbs-btn-accept-all:hover,
#BorlabsCookieBox ._brlbs-accept a:hover {
  background-color: white;
  border: 1px solid black;
  color: black; }

#BorlabsCookieBox ._brlbs-manage a,
#BorlabsCookieBox .cookie-preference ._brlbs-btn:not(._brlbs-btn-accept-all) {
  background: #e9ecec;
  border: 1px solid #e9ecec;
  color: #005a2b; }

#BorlabsCookieBox ._brlbs-manage a:hover,
#BorlabsCookieBox .cookie-preference ._brlbs-btn:not(._brlbs-btn-accept-all):hover {
  background-color: white;
  border: 1px solid black;
  color: black !important; }

.page-template-page-home .light a {
  position: initial; }

.light a {
  display: inline-block;
  position: relative; }

/* Light Grey/White theme Black Button */
.light .button,
.white .button,
.light .button.black,
.white .button.black,
.juicer-feed a.juicer-button,
.juicer-feed .j-paginate,
.j-post-overlay.juicer-feed a.juicer-button {
  background: black;
  border: 1px solid black;
  color: white; }

/* Light Grey/White theme Grey Button */
.light .button.grey,
.white .button.grey,
.light .button.white,
.white .button.white {
  background: #e9ecec;
  border: 1px solid #e9ecec;
  color: #005a2b; }

/* Light Grey/White theme hover states */
.light .button:hover,
.light .button:active,
.light .button:focus,
.white .button:hover,
.white .button:active,
.white .button:focus,
.juicer-feed a.juicer-button:hover,
.juicer-feed a.juicer-button:active,
.juicer-feed a.juicer-button:focus,
.juicer-feed .j-paginate:hover,
.juicer-feed .j-paginate:active,
.juicer-feed .j-paginate:focus,
.j-post-overlay.juicer-feed a.juicer-button:hover,
.j-post-overlay.juicer-feed a.juicer-button:active,
.j-post-overlay.juicer-feed a.juicer-button:focus {
  background-color: white;
  border: 1px solid black;
  color: black; }

/* Dark grey theme Black Button */
.darkestgrey .button,
.grey .button,
.darkblue .button,
.darkestgrey .button.black,
.grey .button.black,
.darkblue .button.black {
  background: #0c121c;
  border: 1px solid #0c121c;
  color: white; }

/* Dark grey theme White Button */
.darkestgrey .button.white,
.grey .button.white,
.darkblue .button.white,
.darkestgrey .button.grey,
.grey .button.grey,
.darkblue .button.grey {
  background: white;
  border: 1px solid white;
  color: #005a2b; }

/* Dark grey theme hover states */
.darkestgrey .button:hover,
.darkestgrey .button:active,
.darkestgrey .button:focus,
.grey .button:hover,
.grey .button:active,
.grey .button:focus,
.darkblue .button:hover,
.darkblue .button:active,
.darkblue .button:focus {
  background: #4a4f54;
  border: 1px solid white;
  color: white; }

/* .kopfbereich.mitbild .button styles */
.kopfbereich.mitbild .button.white {
  background: white;
  border: 1px solid white;
  color: #005a2b; }

.kopfbereich.mitbild .button.white:hover,
.kopfbereich.mitbild .button.white:active,
.kopfbereich.mitbild .button.white:focus {
  background: #4a4f54;
  border: 1px solid white;
  color: white; }

.kopfbereich.mitbild .button.black {
  background: black;
  border: 1px solid black;
  color: white; }

.kopfbereich.mitbild .button.black:hover,
.kopfbereich.mitbild .button.black:active,
.kopfbereich.mitbild .button.black:focus {
  background-color: white;
  border: 1px solid black;
  color: black; }

.kopfbereich.mitbild .button.grey {
  background: #e9ecec;
  border: 1px solid #e9ecec;
  color: #005a2b; }

.kopfbereich.mitbild .button.grey:hover,
.kopfbereich.mitbild .button.grey:active,
.kopfbereich.mitbild .button.grey:focus {
  background-color: white;
  border: 1px solid black;
  color: black; }

/* .kopfbereich.mitbild .button.white styles für mobile überschreiben */
@media (max-width: 740px) {
  .kopfbereich.mitbild .button.white {
    background: black !important;
    border: 1px solid black !important;
    color: white !important; }
  .kopfbereich.mitbild .button.white:hover,
  .kopfbereich.mitbild .button.white:active,
  .kopfbereich.mitbild .button.white:focus {
    background-color: white !important;
    border: 1px solid black !important;
    color: black !important; } }

/* border variante */
/* XXXXXXXXXXXX alter code */
@media (max-width: 1450px) {
  .onecol .button {
    min-width: 190px; } }

.primaryLinkWithStyle {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0);
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.3em;
  line-height: 14px;
  min-width: 125px;
  overflow: hidden;
  padding: 13px 23px 11px;
  text-align: center;
  text-decoration: none !important;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  text-transform: uppercase;
  height: 40px; }

.primaryLinkWithStyle:focus,
.primaryLinkWithStyle:hover {
  background-color: #000;
  color: #fff;
  border: 1px solid #000; }

.grey .primaryLinkWithStyle, .darkestgrey .primaryLinkWithStyle, .primaryLinkWithWhiteStyle {
  background: none repeat scroll 0 0 white;
  border: 1px solid #fff;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.3em;
  line-height: 1.4em;
  min-width: 125px;
  overflow: hidden;
  padding: 13px 23px 11px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  transition-timing-function: ease; }

.primaryLinkWithWhiteStyle:focus,
.primaryLinkWithWhiteStyle:hover,
.grey .primaryLinkWithStyle:focus,
.grey .primaryLinkWithStyle:hover,
.darkestgrey .primaryLinkWithStyle:focus,
.darkestgrey .primaryLinkWithStyle:hover {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  text-decoration: none; }

.primaryLinkWithWhiteBorderStyle {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0);
  border: 1px solid #FFF;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.3em;
  line-height: 1.4em;
  margin-top: 5px;
  min-width: 125px;
  overflow: hidden;
  padding: 13px 23px 11px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  text-transform: uppercase; }

@media (max-width: 740px) {
  .primaryLinkWithWhiteBorderStyle {
    margin-top: 10px; } }

.primaryLinkWithWhiteBorderStyle:focus,
.primaryLinkWithWhiteBorderStyle:hover {
  background-color: #FFF;
  color: #000;
  text-decoration: none; }

.primaryLinkWithBlackStyle {
  background: #000;
  border: 1px solid #000;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.3em;
  line-height: 1.4em;
  margin-top: 30px;
  min-width: 125px;
  overflow: hidden;
  padding: 13px 23px 11px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  text-transform: uppercase; }

.firstbutton {
  margin-right: 10px; }

@media (max-width: 1450px) {
  .onecol .firstbutton {
    margin-bottom: 10px; } }

.kopfbereich .firstbutton {
  margin-right: 0; }

/* Button für Tour Seite */
@media (max-width: 739px) {
  .page-template-page-tour-2017-peru .kopfbereich .button-wrap {
    display: none; } }

@media (min-width: 740px) {
  .page-template-page-tour-2017-peru .kopfbereich .button-wrap {
    margin: 0 auto;
    display: block;
    position: relative;
    left: 0;
    width: 100%;
    text-align: center; }
  .page-template-page-tour-2017-peru .kopfbereich .button-wrap .button {
    border: 2px solid #fff;
    padding: 17px 23px 16px; } }

/* Buttons im Kopfbereich */
.imageContainer .primaryLinkWithWhiteBorderStyle {
  margin-top: 30px; }

.primaryLinkWithBlackStyle:focus,
.primaryLinkWithBlackStyle:hover {
  background: #4e4e4e;
  border: 1px solid #4e4e4e;
  text-decoration: none; }

.kopfbereich.mitbild.linksbuendig .button-wrap {
  margin: 0;
  text-align: left; }

/* Link mit Pfeil */
.secondaryLinkWithStyle {
  display: inline-block;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.25em;
  line-height: 1.25;
  margin-top: 20px;
  overflow: hidden;
  padding-left: 18px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase; }

.secondaryLinkWithStyle:before {
  font-family: 'icons',sans-serif;
  content: '\e02c';
  position: absolute;
  top: 1px;
  left: 0px;
  font-size: 9px; }

.secondaryLinkWithStyle:focus,
.secondaryLinkWithStyle:hover {
  text-decoration: none;
  color: #696868; }

.secondaryLinkWithStyle:focus:before,
.secondaryLinkWithStyle:hover:before {
  color: #696868; }

/**
 * CSS File for ezMark jQuery Plugin
 *
 **/
.ez-hide {
  opacity: 0;
  filter: alpha(opacity=0); }

.ez-checkbox {
  background: transparent url("../assets/images/checkbox-red.png") 0 1px no-repeat;
  display: inline-block; }

.ez-radio {
  background: transparent url("../assets/images/radio-black.png") 0 1px no-repeat;
  display: inline-block; }

.ez-checked {
  background-position: 0 -18px; }

.ez-selected {
  background-position: 0 -19px; }

.ez-checkbox-green {
  background: transparent url("../assets/images/checkbox-green.png") 0 1px no-repeat;
  display: inline-block;
  *display: inline; }

.ez-checked-green {
  background-position: 0 -18px; }

.ez-checkbox, .ez-radio {
  zoom: 1;
  *display: inline;
  _height: 30px; }

/* LR OT4 brand font */
@font-face {
  font-family: 'lr_ot4';
  src: url("../fonts/LandRoverWeb-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/LandRoverWeb-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/LandRoverWeb-Bold.woff") format("woff"), url("../fonts/LandRoverWeb-Bold.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: 'lr_ot4';
  src: url("../fonts/LandRoverWeb-Demi.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/LandRoverWeb-Demi.eot?#iefix") format("embedded-opentype"), url("../fonts/LandRoverWeb-Demi.woff") format("woff"), url("../fonts/LandRoverWeb-Demi.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'lr_ot4';
  src: url("../fonts/LandRoverWeb-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/LandRoverWeb-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/LandRoverWeb-Medium.woff") format("woff"), url("../fonts/LandRoverWeb-Medium.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: 'lr_ot4';
  src: url("../fonts/LandRoverWeb-Light.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/LandRoverWeb-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/LandRoverWeb-Light.woff") format("woff"), url("../fonts/LandRoverWeb-Light.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 100; }

@font-face {
  font-family: 'lr_ot4';
  src: url("../fonts/LandRoverWeb-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/LandRoverWeb-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/LandRoverWeb-Medium.woff") format("woff"), url("../fonts/LandRoverWeb-Medium.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal; }

/* Avenir copy font */
@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Avenir_Heavy.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Avenir_Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/Avenir_Heavy.woff") format("woff"), url("../fonts/Avenir_Heavy.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Avenir_Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Avenir_Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Avenir_Medium.woff") format("woff"), url("../fonts/Avenir_Medium.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Avenir_Roman.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Avenir_Roman.eot?#iefix") format("embedded-opentype"), url("../fonts/Avenir_Roman.woff") format("woff"), url("../fonts/Avenir_Roman.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Avenir_Roman-Oblique.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Avenir_Roman-Oblique.eot?#iefix") format("embedded-opentype"), url("../fonts/Avenir_Roman-Oblique.woff") format("woff"), url("../fonts/Avenir_Roman-Oblique.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: italic;
  font-weight: normal; }

@font-face {
  font-family: 'Avenir';
  src: url("../fonts/avenir_black-webfont.eot");
  src: url("../fonts/avenir_black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir_black-webfont.woff2") format("woff2"), url("../fonts/avenir_black-webfont.woff") format("woff"), url("../fonts/avenir_black-webfont.ttf") format("truetype"), url("../fonts/avenir_black-webfont.svg#webfontregular") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: icons;
  src: url("../fonts/icons-74e4910bf5d0a67b62f1644d49361248.eot");
  src: url("../fonts/icons-74e4910bf5d0a67b62f1644d49361248.eot?#iefix") format("embedded-opentype"), url("../fonts/icons-74e4910bf5d0a67b62f1644d49361248.woff") format("woff"), url("../fonts/icons-74e4910bf5d0a67b62f1644d49361248.ttf") format("truetype"), url("../fonts/icons-74e4910bf5d0a67b62f1644d49361248.svg?#icons") format("svg");
  font-weight: 400;
  font-style: normal; }

.Footer ul {
  margin: 0;
  padding: 0; }

.Footer {
  text-transform: uppercase;
  max-width: 2000px;
  margin: 0 auto;
  background-color: #4a4f54; }

.Footer h5 {
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.2em;
  margin-bottom: 5px; }

@media screen and (min-width: 1px) and (max-width: 900px) {
  .Footer ul.bottomMenu li #bottomMenuButton {
    display: block; }
  .Footer ul.bottomMenu li .menuItems {
    display: none; }
  .Footer ul.bottomMenu li .menuItems.open {
    display: block;
    position: absolute;
    bottom: 45px;
    width: 100%; }
  .Footer ul.bottomMenu li ul li {
    float: left;
    width: 100%; }
  .Footer ul.bottomMenu li .menuItems.open li {
    width: 100%;
    background-color: #000;
    border-bottom: 1px solid #222; }
  .lightTheme .Footer ul.bottomMenu li .menuItems.open li {
    border-bottom-color: #cdd0d0; }
  .Footer ul.bottomMenu li a {
    width: 100%; } }

@media screen and (min-width: 901px) {
  .Footer ul.bottomMenu li #bottomMenuButton {
    display: none; }
  .Footer ul.bottomMenu li .menuItems {
    display: block; } }

.Footer {
  /*  border-top: 1px solid #4e4e4e; */
  position: relative;
  width: 100%; }

.Footer .wrapper {
  overflow: hidden;
  clear: both;
  padding: 0 30px; }

@media screen and (max-width: 400px) {
  .Footer .wrapper {
    padding: 0; } }

@media screen and (max-width: 740px) {
  .col-5 {
    display: none; }
  .col-5.socialLinks {
    display: block; } }

.Footer .wrapper .col-5 {
  float: left;
  position: relative;
  width: 20%;
  margin: 0;
  padding: 0 0 22px 0; }

.Footer .wrapper .col-5 ul li {
  padding: 4px 0;
  padding: 10px 0;
  line-height: 12px; }

.Footer .wrapper .col-5 li a {
  color: #cbcbce;
  font-size: 9px;
  letter-spacing: 0.2em;
  text-decoration: none;
  font-weight: 400; }

.Footer .wrapper .col-5 li a:hover {
  color: #ffffff; }

.Footer .wrapper .col-5 h5:first-child {
  margin-top: 50px; }

.Footer .wrapper .col-5 ul {
  margin: 0 20px 20px 0px; }

/* ---------------------------------------------- BottomMenu */
.Footer ul.bottomMenu li a {
  color: #cbcbce;
  display: inline-block;
  font-size: 8px;
  letter-spacing: 0.3em;
  padding: 16px 18px;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.5em; }

.lightTheme .Footer ul.bottomMenu li a {
  color: #4a4f54;
  background: none repeat scroll 0 0 #fff;
  font-size: 9px;
  padding: 16px 20px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.Footer ul.bottomMenu li a:hover {
  color: #FFF;
  -webkit-transition: color 200ms ease-out 0s;
  -moz-transition: color 200ms ease-out 0s;
  -o-transition: color 200ms ease-out 0s;
  -ms-transition: color 200ms ease-out 0s;
  transition: color 200ms ease-out 0s; }

.lightTheme .Footer ul.bottomMenu li a:hover {
  color: #0c121c;
  -webkit-transition: color 400ms ease-out;
  -moz-transition: color 400ms ease-out;
  -o-transition: color 400ms ease-out;
  -ms-transition: color 400ms ease-out;
  transition: color 400ms ease-out; }

.Footer ul.bottomMenu li ul li {
  float: left; }

.Footer ul.bottomMenu li a.toggleSwitch {
  display: none; }

.Footer ul.bottomMenu {
  background-color: #000;
  position: absolute;
  width: 100%; }

.lightTheme .Footer ul.bottomMenu {
  background-color: #fff; }

.Footer ul.bottomMenu > ul {
  width: 100%; }

.Footer #bottomMenuButton i {
  padding-left: 4px; }

.Footer #bottomMenuButton i {
  z-index: 19;
  cursor: pointer; }

.Footer #bottomMenuButton {
  float: left;
  width: auto; }

.Footer #bottomMenuButton.open {
  background: none repeat scroll 0 0 #4e4e4e;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear; }

.lightTheme .Footer #bottomMenuButton.open {
  background: none repeat scroll 0 0 #e4e8e8; }

.Footer #bottomMenuButton {
  background: none repeat scroll 0 0 #000;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear; }

.lightTheme .Footer #bottomMenuButton {
  background: none repeat scroll 0 0 #fff; }

#social ul li a span:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #222;
  display: block;
  font-size: 23px;
  font-weight: normal;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
  display: inline-block;
  margin-right: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#social ul li a:hover span:before {
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear; }

#social ul li a.social_facebook:hover span:before {
  background-color: #305891; }

#social ul li a.social_twitter:hover span:before {
  background-color: #2CA8D2; }

#social ul li a.social_youtube:hover span:before {
  background-color: #E62D27; }

#social ul li a.social_instagram:hover span:before {
  background-color: #406e95; }

#social ul li {
  position: relative; }

#social ul li a .text {
  position: absolute;
  top: 22px;
  left: 62px; }

@media screen and (min-width: 1px) and (max-width: 741px) {
  #social {
    width: 100%; }
  #social ul {
    clear: both;
    text-align: center;
    width: 100%; }
  #social ul li {
    display: inline; }
  #social ul li a .text {
    display: none; }
  #social h5 {
    text-align: center;
    padding-bottom: 8px; } }

/* ---------------------------------------------- Share-Button */
#bottomMenuShareButton {
  float: right;
  font-size: 18px;
  height: 42px;
  line-height: 17px;
  padding: 13px 18px;
  color: #FFFFFF;
  background-color: #0c121c;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
  z-index: 2; }

#bottomMenuShareButton:hover, #sharethis a:hover {
  background-color: #141b27;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  #bottomMenuShareButton {
    position: absolute;
    right: 0;
    width: auto;
    float: none; } }

#sharethis {
  position: absolute;
  right: 0;
  bottom: 44px;
  width: 62px;
  clear: both;
  overflow: hidden;
  z-index: 1;
  display: none; }

#sharethis a {
  font-size: 18px;
  height: 43px;
  line-height: 20px;
  padding: 11px 18px;
  color: #FFFFFF;
  background-color: #0c121c;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear; }

#sharethis #fb:hover {
  background-color: #305891; }

#sharethis #tw:hover {
  background-color: #2CA8D2; }

#sharethis #share:hover {
  background-color: #CCC; }

/* Sub-Footer */
#sub-footer {
  clear: both;
  padding: 20px;
  border-top: 1px solid #222;
  background: #000;
  text-align: center;
  color: #999;
  max-width: 2000px;
  margin: 0 auto; }

.lightTheme #sub-footer {
  border-top: 2px solid #e4e8e8;
  background: #fff;
  color: #4a4f54; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  .lightTheme #sub-footer {
    border-top: 1px solid #e4e8e8;
    margin-top: 2px; } }

#sub-footer p {
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 12px;
  max-width: none; }

.lightTheme #sub-footer p {
  font-size: 12pt; }

#sub-footer a {
  color: #ccc; }

.lightTheme #sub-footer a {
  color: #4a4f54; }

.lightTheme #sub-footer a:hover {
  color: #a4a7a9; }

html.noScroll, html.noScroll body {
  /*    bottom: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;*/
  overflow: hidden; }

#header ul, #header li,
#slideMenu ul, #slideMenu li {
  margin: 0;
  padding: 0;
  list-style: none; }

/* a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video{
    border: 0 none;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
} 
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
*/
* {
  box-sizing: border-box; }

#page {
  margin: 0 auto;
  max-width: 2000px;
  width: 100%;
  padding: 0;
  position: relative;
  padding-top: 0px; }

#content {
  min-height: 400px;
  width: 100%;
  position: relative;
  overflow: hidden; }

/* ----------------- slideMenu  g   */
#slideMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 20;
  display: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#slideMenu.open {
  display: block; }

#slideMenu .wrapper {
  background-color: #222;
  background-image: url("../images/texture-darkest-grey.png");
  background-repeat: repeat;
  float: left;
  height: 100%; }

.lightTheme #slideMenu .wrapper {
  background-color: #e4e8e8;
  background-image: none;
  background-repeat: no-repeat; }

#slideMenu .close-slide {
  float: left;
  width: 50px;
  height: 50px;
  border-bottom: 1px solid #454545;
  background-color: #4e4e4e;
  z-index: 2;
  cursor: pointer;
  position: relative; }

.lightTheme #slideMenu .close-slide {
  border-bottom: 1px solid #eef1f1;
  background-color: #fff; }

#slideMenu .close-slide a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 50px;
  margin: 0;
  text-align: center;
  text-decoration: inherit;
  text-indent: 0;
  text-transform: none;
  vertical-align: middle;
  width: 50px; }

.lightTheme #slideMenu .close-slide a {
  color: #0c121c; }

#slideMenu .close-slide .icon-menu:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 50px;
  margin: 0;
  text-align: center;
  text-indent: 0;
  text-decoration: inherit;
  text-transform: none;
  vertical-align: middle;
  width: 50px;
  font-size: 14px;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lightTheme #slideMenu .close-slide .icon-menu:before {
  color: #0c121c; }

#SlideSearch {
  background-color: #0c121c;
  float: left;
  height: 50px;
  position: relative;
  width: 100%; }

.lightTheme #SlideSearch {
  background-color: #fff; }

#SlideSearch:before {
  font-size: 10px;
  line-height: 50px;
  padding-left: 20px;
  position: absolute; }

.lightTheme #SlideSearch:before {
  color: #0c121c; }

#SlideSearch > span {
  display: block;
  height: 50px;
  overflow: hidden;
  padding: 15px; }

#SlideInput {
  background-color: #242424;
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: 20px auto;
  border: medium none;
  color: #fff;
  display: block;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 11px;
  height: 21px;
  letter-spacing: 0.1em;
  line-height: 21px;
  outline: medium none;
  padding: 0 10px 0 25px;
  width: 100%; }

.lightTheme #SlideInput {
  background-color: #fff;
  color: #0c121c; }

#slideMenu .navigation {
  bottom: 0;
  left: 50px;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 50px; }

#slideMenu > .wrapper ul {
  float: left;
  width: 100%; }

/* --  slideMenu ul.first  ------------------              */
@media screen and (min-width: 1px) and (min-width: 741px) {
  #slideMenu ul.first {
    display: none; } }

#slideMenu > .wrapper ul.first > li {
  background: none repeat scroll 0 0 #0c121c;
  border-top: 1px solid #333;
  clear: both;
  overflow: hidden;
  position: relative;
  width: 100%; }

.lightTheme #slideMenu > .wrapper ul.first > li {
  background: none repeat scroll 0 0 #fff;
  border-top: 1px solid #eef1f1; }

#slideMenu > .wrapper ul.first > li a {
  display: block;
  font-size: 10px;
  letter-spacing: 0.15em;
  line-height: 1.3em;
  padding: 18px 55px 18px 25px;
  text-decoration: none;
  color: white; }

.lightTheme #slideMenu > .wrapper ul.first > li a {
  color: #4a4f54;
  font-weight: bold; }

#slideMenu > .wrapper ul.first > li.current-menu-item,
#slideMenu > .wrapper ul.first > li.current-menu-parent {
  background-color: #4e4e4e; }

.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item,
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-parent {
  background-color: #e4e8e8; }

#slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-item,
#slideMenu > .wrapper ul.first > li.current-menu-parent li.current-menu-parent {
  border-top: 1px solid #4e4e4e; }

.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-item,
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-parent li.current-menu-parent {
  border-top: 1px solid #e4e8e8; }

#slideMenu > .wrapper ul.first > li.current-menu-item li,
#slideMenu > .wrapper ul.first > li.current-menu-parent li {
  border-top: 1px solid #454545; }

.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item li,
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-parent li {
  border-top: 1px solid #eef1f1; }

#slideMenu > .wrapper ul.first > li.current-menu-item > a,
#slideMenu > .wrapper ul.first > li.current-menu-parent > a,
#slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-item a,
#slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-parent a {
  background-color: #333; }

.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item > a,
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-parent > a,
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-item a,
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-parent a {
  background-color: #e4e8e8; }

#slideMenu > .wrapper ul.first > li ul li a {
  background-color: #4e4e4e; }

.lightTheme #slideMenu > .wrapper ul.first > li ul li a {
  background-color: #e4e8e8;
  border-top: 1px solid #d8dcdc; }

#slideMenu > .wrapper ul.first > li ul li a:before {
  font-size: 7px;
  padding-right: 4px; }

#slideMenu > .wrapper ul.first li a:hover {
  background-color: #333;
  background-image: none;
  color: #FFF; }

.lightTheme #slideMenu > .wrapper ul.first li a:hover {
  background-color: #e4e8e8;
  color: #0c121c; }

#slideMenu > .wrapper ul.first > li a i {
  float: right; }

#slideMenu > .wrapper ul.first > li a i:before {
  font-size: 21px;
  position: absolute;
  right: 25px;
  top: 17px; }

#slideMenu > .wrapper ul.first > li > ul {
  text-transform: uppercase;
  display: none;
  height: auto;
  overflow: hidden; }

/* --  slideMenu ul.second  ------------------              */
#slideMenu > .wrapper ul.second li {
  text-transform: uppercase;
  height: auto;
  width: 100%; }

#slideMenu > .wrapper ul.second li a {
  background-color: #222;
  background-image: url("../images/texture-darkest-grey.png");
  background-repeat: repeat;
  color: #cbcbce;
  display: block;
  font-size: 9px;
  letter-spacing: 0.15em;
  line-height: 50px;
  padding: 0 25px;
  text-decoration: none; }

.lightTheme #slideMenu > .wrapper ul.second li a {
  background-image: none;
  color: #4a4f54;
  background-color: #e4e8e8;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.3em; }

#slideMenu > .wrapper ul.second li a:hover {
  background-color: #4e4e4e;
  background-image: none; }

.lightTheme #slideMenu > .wrapper ul.second li a:hover {
  background-color: #e4e8e8;
  color: #0c121c; }

#slideMenu > .wrapper ul.second li a i:before {
  font-size: 17px;
  margin-right: 10px;
  text-align: center;
  width: 18px; }

/* --  slideMenu ul.third  ------------------              */
#slideMenu > .wrapper ul.third {
  font-family: Avenir,sans-serif;
  padding: 13px 0; }

.lightTheme #slideMenu > .wrapper ul.third {
  background-color: #e4e8e8; }

#slideMenu > .wrapper ul.third li a span {
  padding-left: 4px; }

#slideMenu > .wrapper ul.third li a:hover span {
  color: #4e4e4e;
  padding-left: 4px; }

.lightTheme #slideMenu > .wrapper ul.third li a:hover span {
  color: #0c121c;
  padding-left: 4px; }

#slideMenu > .wrapper ul.third li a:before {
  font-size: 7px; }

#slideMenu > .wrapper ul.third li a {
  padding: 13px 0;
  color: #cbcbce;
  display: block;
  font-size: 9px;
  letter-spacing: 0.15em;
  padding: 11px 25px;
  text-decoration: none;
  text-transform: uppercase; }

.lightTheme #slideMenu > .wrapper ul.third li a {
  color: #4a4f54;
  background-color: #e4e8e8;
  font-size: 10px;
  font-weight: bold;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  letter-spacing: 0.3em; }

#slideMenu .cancel.switch, #searchResults {
  display: none; }

#slideMenu .cancel {
  background-color: #0c121c;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
  height: 21px;
  line-height: 12px;
  letter-spacing: 0.15em;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 20px;
  width: 60px;
  text-transform: uppercase;
  font-family: Avenir,Arial,Helvetica,sans-serif; }

.lightTheme #slideMenu .cancel {
  background-color: #fff;
  color: #65696e; }

#SearchResults {
  top: 50px;
  position: absolute;
  left: 50px;
  right: 0;
  /* first we dont need this because we dont have a live search */
  background-color: #ededed;
  background-image: url("../images/texture-grey-mist.png");
  z-index: 12; }

.searchFormContainer {
  width: 500px;
  margin: 0 auto;
  position: relative; }

@media screen and (max-width: 740px) and (min-width: 1px) {
  .searchFormContainer {
    width: auto;
    max-width: 350px; } }

.searchFormContainer .searchform .textfield {
  font-family: "avenir","arial","helvetica",sans-serif;
  border: none;
  font-size: 16px;
  padding: 12px 0px 12px 15px;
  height: 45px;
  width: 100%;
  color: #4a4f54;
  margin: 0 0 20px; }

@media screen and (max-width: 740px) and (min-width: 1px) {
  .searchFormContainer .searchButton {
    width: 100%;
    float: none;
    height: 45px; } }

/*    fade-overlay   ------------------------------        */
#fade-overlay {
  background-color: none;
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 2000px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear; }

#fade-overlay.open {
  display: block;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear; }

/*    header   ------------------------------        */
#header {
  background-color: #0c121c;
  height: auto;
  margin: 0 auto;
  max-width: 2000px;
  width: 100%;
  z-index: 9; }

.lightTheme #header {
  background-color: #fff; }

#header > .wrapper {
  position: relative;
  height: 108px; }

/*    header logo   ------------------------------        */
#header #logo {
  height: 108px;
  width: 189px;
  border-right: 1px solid #333; }

.lightTheme #header #logo {
  border-right: 1px solid #dee2e2; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  #header #logo {
    height: 49px;
    width: 91px;
    background-color: #0c121c;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 10;
    left: 0; }
  .lightTheme #header #logo {
    background-color: #fff;
    border-bottom: 1px solid #dee2e2; } }

#header #logo > .wrapper {
  height: 100%;
  width: 100%;
  position: relative; }

#header #logo a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; }

#header #logo a img {
  height: 84px;
  width: 118px;
  margin-left: -59px;
  margin-top: -42px;
  top: 50%;
  left: 50%;
  position: absolute; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  #header #logo a img {
    height: 42px;
    width: 59px;
    margin-left: -30px;
    margin-top: -21px; } }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #landrover .headerWrapper {
    height: 50px; } }

#mainNav > .wrapper {
  margin-right: 70px; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  #mainNav .wrapper {
    margin-right: 50px; } }

@media screen and (min-width: 741px) and (min-width: 899px) {
  #mainNav .wrapper ul > li {
    display: block !important;
    width: 20%;
    float: left; } }

#mainNav .wrapper ul li.current-menu-item a,
#mainNav .wrapper ul li.current-page-ancestor a {
  background-color: #4e4e4e;
  border-bottom: 1px solid #454545; }

/* highlighting experience-tour hauptnavigation */
body.page-id-2082 li#menu-item-2839 a,
body.page-id-17 li#menu-item-2839 a,
body.page-id-426 li#menu-item-2839 a {
  background-color: #e4e8e8 !important;
  border-bottom: 1px solid #454545; }

.lightTheme #mainNav .wrapper ul li.current-menu-item a,
.lightTheme #mainNav .wrapper ul li.current-page-ancestor a {
  background-color: #e4e8e8;
  border-bottom: 1px solid #dee2e2; }

#mainNav .wrapper ul li a:hover {
  background-color: #4e4e4e;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear; }

.lightTheme #mainNav .wrapper ul li a:hover {
  background-color: #e4e8e8; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #mainNav .wrapper ul li {
    display: none; }
  #mainNav .MenuButton {
    /* border-left:none !important; */ } }

#header #uberMenu {
  position: absolute;
  top: 0;
  right: 0; }

#mainNav .wrapper ul {
  height: 70px; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  .MenuButton:hover {
    border-bottom: 1px solid #454545;
    background-color: #4e4e4e;
    -webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear; }
  .lightTheme .MenuButton:hover {
    border-bottom: 1px solid #eef1f1;
    background-color: #e4e8e8; }
  .MenuButton.open {
    border-bottom: 1px solid #454545;
    background-color: #4e4e4e; }
  .lightTheme .MenuButton.open {
    border-bottom: 1px solid #eef1f1;
    background-color: #e4e8e8; }
  #header {
    position: fixed;
    top: 0; }
  #header #uberMenu {
    display: none; }
  #header > .wrapper {
    height: 50px; }
  .lightTheme #header > .wrapper {
    border-bottom: 1px solid #dee2e2; }
  #mainNav .SearchButton {
    display: none; }
  #mainNav .wrapper ul {
    height: 50px; } }

#mainNav .wrapper ul li a {
  font-size: 10px;
  letter-spacing: 0.35em; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  #mainNav .wrapper ul li a {
    font-size: 9px; } }

.SearchButton.ready .switch:hover,
.SearchButton .close-mainSearch:hover {
  cursor: pointer;
  border-bottom: 1px solid #454545;
  background-color: #4e4e4e;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear; }

.lightTheme .SearchButton.ready .switch:hover,
.lightTheme .SearchButton .close-mainSearch:hover {
  border-bottom: 1px solid #eef1f1;
  background-color: #e4e8e8; }

#header #uberMenu li {
  float: left;
  height: 37px;
  line-height: 37px;
  position: relative;
  vertical-align: middle; }

#header #uberMenu li a:hover {
  color: #FFFFFF; }

.lightTheme #header #uberMenu li a:hover {
  color: #0c121c; }

#header #uberMenu li a {
  margin-right: 3px;
  padding: 0 20px; }

#header #uberMenu li a i {
  margin-right: 3px; }

#header #uberMenu li a, #header #uberMenu li {
  text-transform: uppercase;
  color: #cbcbce;
  display: block;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 8px;
  letter-spacing: 0.3em;
  text-decoration: none; }

.lightTheme #header #uberMenu li a,
.lightTheme #header #uberMenu li {
  color: #0c121c;
  font-weight: bold; }

#header #mainNav {
  position: absolute;
  right: 0;
  z-index: 8;
  left: 189px;
  top: 37px;
  border-top: 1px solid #333;
  text-transform: uppercase; }

.lightTheme #header #mainNav {
  border-top: 1px solid #dee2e2; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  #header #mainNav {
    border-top: 0 none;
    left: 91px;
    top: 0; } }

@media screen and (min-width: 1px) and (max-width: 899px) {
  .lightTheme #header #mainNav {
    border-top: 0 none;
    left: 91px;
    top: 0; } }

#mainNav .wrapper ul li {
  margin-left: 0;
  border-right: 1px solid #333;
  overflow: hidden;
  height: 100%;
  float: left;
  width: 20%; }

.lightTheme #mainNav .wrapper ul li {
  border-right: 1px solid #dee2e2; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #mainNav .wrapper ul > li {
    width: auto;
    float: none;
    display: none; } }

#mainNav .wrapper ul li a {
  height: 100%;
  width: 101%;
  background-color: #0c121c;
  border: medium none;
  color: #fff;
  display: table;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-weight: 700;
  letter-spacing: 0.35em;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }

.lightTheme #mainNav .wrapper ul li a {
  background-color: #fff;
  color: #0c121c;
  font-weight: 900;
  font-size: 9px;
  letter-spacing: 0.2em; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #landrover .headerWrapper {
    height: 50px; }
  #mainNav .wrapper ul li.current-menu-item {
    display: none; }
  #mainNav .wrapper ul > li {
    width: auto;
    float: none; } }

#mainNav .wrapper ul li a > span {
  display: table-cell;
  vertical-align: middle;
  line-height: 12px; }

.lightTheme #mainNav .wrapper ul li a > span {
  line-height: 14px;
  vertical-align: middle; }

#mainNav .SearchButton {
  overflow: hidden;
  height: 70px;
  width: 70px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #0c121c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lightTheme #mainNav .SearchButton {
  background-color: #fff; }

#mainNav .close-mainSearch {
  height: 70px;
  width: 70px;
  float: left;
  overflow: hidden;
  display: none; }

#mainNav .close-mainSearch > span {
  text-indent: -999em; }

#mainNavSearchForm {
  background-color: #0c121c;
  display: block;
  float: left;
  height: 70px;
  overflow: hidden;
  position: relative;
  z-index: 2; }

.lightTheme #mainNavSearchForm {
  background-color: #fff; }

#mainNavSearchForm input {
  background-color: #0c121c;
  border: medium none;
  bottom: 0;
  color: #fff;
  display: block;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 20px;
  height: 70px;
  left: 0;
  letter-spacing: 0.1em;
  line-height: 100%;
  outline: 0 none;
  padding: 0;
  position: absolute;
  right: 0;
  text-indent: 0;
  top: 0;
  width: 100%; }

.lightTheme #mainNavSearchForm input {
  background-color: #fff;
  color: #0c121c; }

#mainNavSearchForm label {
  color: #cbcbce;
  display: none;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 20px;
  letter-spacing: 0.1em;
  top: 25px;
  opacity: 0.75;
  position: relative;
  z-index: 2;
  text-transform: none; }

#mainNavSearchForm label.open {
  display: block; }

#mainNav .MenuButton {
  display: none; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  #mainNav .MenuButton {
    overflow: hidden;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #333;
    display: block; }
  .lightTheme #mainNav .MenuButton {
    border-left: 1px solid #dee2e2; } }

#mainNav .MenuButton.open {
  border-bottom: 1px solid #454545;
  background-color: #4e4e4e; }

.lightTheme #mainNav .MenuButton.open {
  border-bottom: 1px solid #eef1f1;
  background-color: #e4e8e8; }

#mainNav .MenuButton a {
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  outline: 0 none;
  width: 50px;
  overflow: hidden;
  display: block; }

#mainNav .MenuButton a:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 50px;
  margin: 0;
  text-align: center;
  text-indent: 0;
  text-decoration: inherit;
  text-transform: none;
  vertical-align: middle;
  width: 50px;
  font-size: 14px;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lightTheme #mainNav .MenuButton a:before {
  color: #0c121c; }

#mainNav .SearchButton.ready a.switch {
  cursor: pointer; }

#mainNav .SearchButton a.switch {
  cursor: default;
  float: left;
  height: 70px;
  line-height: 70px;
  outline: 0 none;
  width: 70px; }

#mainNav .SearchButton a:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 70px;
  margin: 0;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  vertical-align: middle;
  width: 70px;
  font-size: 18px;
  color: #FFFFFF; }

.lightTheme #mainNav .SearchButton a:before {
  color: #0c121c; }

/*  SubNavigation  ---------------------------------------     */
.SubNavigation {
  background: none repeat scroll 0 0 #4e4e4e;
  position: relative;
  width: 100%;
  z-index: 2; }

.lightTheme .SubNavigation {
  background: none repeat scroll 0 0 #e4e8e8; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .SubNavigation {
    display: none; } }

.SubNavigation .wrapper {
  height: 41px;
  position: relative; }

.SubNavigation .wrapper ul {
  clear: both;
  overflow: hidden;
  display: flex; }

.SubNavigation .wrapper ul li {
  float: left;
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.2em;
  white-space: nowrap; }

.lightTheme .SubNavigation .wrapper ul li {
  font-weight: 900; }

.SubNavigation .wrapper ul li a {
  color: #fff;
  display: block;
  line-height: 41px;
  padding: 0 25px;
  text-decoration: none;
  text-transform: uppercase; }

@media screen and (min-width: 741px) and (max-width: 785px) {
  .SubNavigation .wrapper ul li a {
    padding: 0 19px; } }

.lightTheme .SubNavigation .wrapper ul li a {
  color: #0c121c; }

.SubNavigation .wrapper ul li a:focus, .SubNavigation .wrapper ul li a:hover {
  color: #cbcbce; }

.lightTheme .SubNavigation .wrapper ul li a:focus,
.lightTheme .SubNavigation .wrapper ul li a:hover {
  background-color: #d6dada;
  color: #0c121c; }

.SubNavigation .wrapper ul li.current-menu-item a {
  background-color: #333;
  color: #cbcbce; }

.lightTheme .SubNavigation .wrapper ul li.current-menu-item a {
  background-color: #d6dada;
  color: #0c121c; }

/*  stickyNavigation  ---------------------------------------     */
#header.stickyNavigation {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 8;
  transition: all .4s; }

#header.stickyNavigation.searchActive {
  position: absolute; }

#header.stickyNavigation .headerWrapper {
  transition: all .4s; }

#header.stickyNavigation #uberMenu {
  transition: all .4s; }

#header.stickyNavigation #logo {
  transition: width .4s,height .4s; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  .lightTheme #header.stickyNavigation #logo {
    border-bottom: 1px solid #fff; } }

#header.stickyNavigation #logo img {
  transition: all .4s; }

#header.stickyNavigation > .wrapper {
  transition: height .4s; }

#header.stickyNavigation #mainNav {
  transition: all .4s; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  #header.stickyNavigation #mainNav {
    top: 0;
    margin-top: -1px; } }

#header.stickyNavigation #mainNav ul li {
  height: 70px; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  #header.stickyNavigation #mainNav ul li {
    height: 50px; } }

#header.stickyNavigation #mainNav .wrapper ul li.current-menu-item a,
#header.stickyNavigation #mainNav .wrapper ul li.current-page-ancestor a,
#header.stickyNavigation #mainNav .wrapper ul li a > span,
#header.stickyNavigation #mainNav ul li.active a,
#header.stickyNavigation #mainNav ul li a,
#header.stickyNavigation #mainNav ul li {
  transition: all .4s; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  .lightTheme #header.stickyNavigation .MenuButton {
    border-bottom: 1px solid #fff; } }

#header.stickyNavigation #mainNav #mainNavSearchForm,
#header.stickyNavigation #mainNav #mainNavSearchForm input,
#header.stickyNavigation #mainNav .inactiveSearchButton,
#header.stickyNavigation #mainNav .inactiveSearchButton > a,
#header.stickyNavigation #mainNav .SearchButton,
#header.stickyNavigation #mainNav .SearchButton > a,
#header.stickyNavigation #mainNav .SearchButton a:before {
  transition: height .4s,line-height .4s; }

#header.stickyNavigation .autoCompleteMenu {
  transition: top .4s; }

#header.stickyNavigation .SubNavigation .SubNavigationContainer {
  transition: height .4s,opacity .4s; }

#header.stickyNavigation .SubNavigation .SubNavigationContainer .SubNavigationDivider {
  transition: top .4s; }

#header.stickyNavigation .SubNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer {
  -webkit-transition: height 0;
  -webkit-transition-delay: .4s;
  transition: height 0 .4s; }

#header.stickyNavigation .SubNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer div {
  transition: line-height .2s; }

#header.stickyNavigation .SubNavigation .SubNavigationContainer .SubNavigationMainList li a {
  transition: color .4s,line-height .2s; }

#header.stickyNavigation .SubNavigation .SubNavigationContainer .SubNavigationButtons {
  transition: color .4s,top .4s; }

#header.stickyNavigation.reducedStickyNavigation {
  opacity: .97;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1); }

#header.stickyNavigation.reducedStickyNavigation .headerWrapper {
  height: 50px; }

#header.stickyNavigation.reducedStickyNavigation #uberMenu {
  margin-top: -70px; }

#header.stickyNavigation.reducedStickyNavigation #uberMenu .ShoppingToolsDropdown {
  display: none !important; }

#header.stickyNavigation.reducedStickyNavigation #logo {
  -webkit-transition: width .4s,height .4s;
  -webkit-transition-delay: .1s,0s;
  transition: width .4s .1s,height .4s;
  height: 49px; }

@media screen and (min-width: 900px) {
  #header.stickyNavigation.reducedStickyNavigation #logo img {
    width: 44px;
    height: 31px;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -20px; } }

#header.stickyNavigation.reducedStickyNavigation > .wrapper {
  height: 50px; }

#header.stickyNavigation.reducedStickyNavigation #mainNav {
  -webkit-transition: top .4s,left .4s,margin .4s;
  -webkit-transition-delay: 0s,.1s,0s;
  transition: top .4s,left .4s .1s,margin .4s;
  top: 0;
  margin-top: -1px; }

#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm,
#header.stickyNavigation.reducedStickyNavigation #mainNav .inactiveSearchButton,
#header.stickyNavigation.reducedStickyNavigation #mainNav .inactiveSearchButton > a,
#header.stickyNavigation.reducedStickyNavigation #mainNav .SearchButton,
#header.stickyNavigation.reducedStickyNavigation #mainNav ul li,
#header.stickyNavigation.reducedStickyNavigation #mainNav .SearchButton > a,
#header.stickyNavigation.reducedStickyNavigation #mainNav ul li a {
  height: 50px;
  line-height: 50px; }

#header.stickyNavigation.reducedStickyNavigation #mainNav .SearchButton a:before {
  line-height: 50px; }

#header.stickyNavigation.reducedStickyNavigation #mainNav ul {
  transition: height .4s; }

#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm,
#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm input {
  height: 50px; }

#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm .searchText {
  height: 50px;
  line-height: 50px; }

#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm label {
  top: 1px; }

#header.stickyNavigation.reducedStickyNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer {
  transition: height 0; }

#header.stickyNavigation.reducedStickyNavigation .autoCompleteMenu {
  top: 50px; }

#header.stickyNavigation.notransition,
#header.stickyNavigation.notransition #logo,
#header.stickyNavigation.notransition #logo img,
#header.stickyNavigation.notransition > .wrapper,
#header.stickyNavigation.notransition .headerWrapper,
#header.stickyNavigation.notransition #mainNav,
#header.stickyNavigation.notransition #mainNav ul,
#header.stickyNavigation.notransition #uberMenu {
  transition: none; }

#header.stickyNavigation.notransition #mainNav .wrapper ul li.current-menu-item a,
#header.stickyNavigation.notransition #mainNav .wrapper ul li.current-page-ancestor a,
#header.stickyNavigation.notransition #mainNav .wrapper ul li a > span,
#header.stickyNavigation.notransition #mainNav ul li.active a,
#header.stickyNavigation.notransition #mainNav ul li a,
#header.stickyNavigation.notransition #mainNav ul li {
  transition: none; }

#header.stickyNavigation.notransition .autoCompleteMenu,
#header.stickyNavigation.notransition #mainNav .inactiveSearchButton,
#header.stickyNavigation.notransition #mainNav .inactiveSearchButton > a,
#header.stickyNavigation.notransition #mainNav .SearchButton, #header.stickyNavigation.notransition #mainNav .SearchButton > a,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer div,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .SubNavigationDivider {
  transition: none; }

#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .SubNavigationButtons,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .SubNavigationMainList li a {
  transition: color .4s; }

.experience-tour-2019 #menu-item-7668 a {
  background-color: #d6dada;
  color: #0c121c; }

.experience-tour-2019 #menu-item-7645 a {
  background-color: #e4e8e8 !important;
  border-bottom: 1px solid #dee2e2 !important; }

.experience-tour-2017 #menu-item-7642 a {
  background-color: #d6dada;
  color: #0c121c; }

.experience-tour-2017 #menu-item-7645 a {
  background-color: #e4e8e8 !important;
  border-bottom: 1px solid #dee2e2 !important; }

.experience-tour-2015 #menu-item-7643 a {
  background-color: #d6dada;
  color: #0c121c; }

.experience-tour-2015 #menu-item-7645 a {
  background-color: #e4e8e8 !important;
  border-bottom: 1px solid #dee2e2 !important; }

.experience-tour-2013 #menu-item-7636 a {
  background-color: #d6dada;
  color: #0c121c; }

.experience-tour-2013 #menu-item-7645 a {
  background-color: #e4e8e8 !important;
  border-bottom: 1px solid #dee2e2 !important; }

.sonstiges #menu-item-7637 a {
  background-color: #d6dada;
  color: #0c121c; }

.sonstiges #menu-item-7645 a {
  background-color: #e4e8e8 !important;
  border-bottom: 1px solid #dee2e2 !important; }

.new-menu-style #header > .wrapper {
  height: 87px; }

.new-menu-style #header.stickyNavigation.reducedStickyNavigation > .wrapper {
  height: 50px; }

.new-menu-style #header #logo {
  border-right: none;
  height: 87px;
  width: 172px; }
  .new-menu-style #header #logo a img {
    margin-top: -32px;
    width: 100%;
    max-width: 89px;
    height: auto; }

.new-menu-style #header.stickyNavigation.reducedStickyNavigation #logo {
  height: 49px; }

.new-menu-style #uberMenu {
  display: none; }

.new-menu-style #header #mainNav {
  border-top: none;
  top: 0;
  left: 139px;
  margin-top: 0; }

.new-menu-style #header.stickyNavigation.reducedStickyNavigation #mainNav {
  margin-top: 0; }

.new-menu-style #mainNav .wrapper ul {
  height: 87px; }

.new-menu-style #header.stickyNavigation #mainNav ul li {
  height: 87px;
  width: auto;
  border-right: none; }

.new-menu-style #header.stickyNavigation.reducedStickyNavigation #mainNav ul li {
  height: 50px; }

.new-menu-style #header.stickyNavigation #mainNav ul li a {
  padding: 0 10px;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 1.73px; }
  .new-menu-style #header.stickyNavigation #mainNav ul li a:hover {
    background-color: transparent;
    text-decoration: underline; }

.new-menu-style #header.stickyNavigation.reducedStickyNavigation #mainNav ul li a {
  padding-bottom: 0; }

.new-menu-style #mainNav .wrapper ul li.current-menu-item a,
.new-menu-style #mainNav .wrapper ul li.current-page-ancestor a {
  border-bottom: none; }
  .new-menu-style #mainNav .wrapper ul li.current-menu-item a:hover,
  .new-menu-style #mainNav .wrapper ul li.current-page-ancestor a:hover {
    background-color: #e4e8e8 !important;
    text-decoration: underline; }

.new-menu-style #mainNav .SearchButton {
  height: 87px;
  border-bottom: none; }

.new-menu-style #mainNav .SearchButton a.switch {
  height: 87px;
  line-height: 87px;
  border-bottom: none; }

.new-menu-style #mainNavSearchForm input {
  height: 87px; }

.new-menu-style #mainNavSearchForm label {
  top: 32px; }

.new-menu-style #mainNav .close-mainSearch {
  height: 87px;
  line-height: 87px;
  border-bottom: none; }

.new-menu-style #mainNav .close-mainSearch > span {
  display: none; }

@media screen and (min-width: 1px) and (max-width: 899px) {
  .new-menu-style #header > .wrapper {
    height: 87px;
    border-bottom: none; }
  .new-menu-style #header #logo {
    border-right: none;
    height: 87px;
    width: 120px; }
    .new-menu-style #header #logo a img {
      margin-top: -32px;
      margin-left: -34px;
      width: 100%;
      max-width: 89px;
      height: auto; }
  .new-menu-style #header #mainNav {
    left: 154px; }
  .new-menu-style #header.stickyNavigation #mainNav ul li {
    height: 87px; }
  .new-menu-style #header.stickyNavigation.reducedStickyNavigation #mainNav ul li {
    height: 87px; }
  .new-menu-style #header.stickyNavigation #mainNav ul li a {
    padding-bottom: 0; }
  .new-menu-style #mainNav .wrapper ul li.current-menu-item a,
  .new-menu-style #mainNav .wrapper ul li.current-page-ancestor a {
    border-bottom: none; }
  .new-menu-style #mainNav .wrapper {
    margin-right: 0; }
  .new-menu-style #mainNav .MenuButton {
    display: none;
    width: auto;
    height: 87px;
    border: none;
    left: 0;
    right: auto; }
    .new-menu-style #mainNav .MenuButton:hover {
      background-color: transparent;
      border: none; }
      .new-menu-style #mainNav .MenuButton:hover a span {
        text-decoration: underline; }
  .new-menu-style #mainNav .MenuButton a.switch {
    width: auto;
    height: 87px;
    line-height: 84px;
    border-bottom: none;
    color: #0c121c; }
    .new-menu-style #mainNav .MenuButton a.switch span {
      font-weight: 600;
      font-size: 10px;
      letter-spacing: 1.73px;
      margin-left: 20px; }
  .new-menu-style #mainNav .MenuButton #menuButtonIcon {
    vertical-align: middle;
    display: inline-block; }
  .new-menu-style #mainNav .SearchButton {
    display: block;
    height: 87px;
    border-bottom: none; }
  .new-menu-style #mainNav .SearchButton a.switch {
    height: 87px;
    line-height: 87px;
    border-bottom: none; }
  .new-menu-style #mainNav .close-mainSearch {
    height: 87px;
    border-bottom: none; } }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .new-menu-style #header > .wrapper {
    height: 50px;
    border-bottom: none; }
  .new-menu-style #header #logo {
    border-right: none;
    height: 49px;
    width: 91px;
    left: 50%;
    transform: translateX(-50%); }
    .new-menu-style #header #logo a img {
      height: 42px;
      width: 59px;
      margin-left: -30px;
      margin-top: -21px;
      border-bottom: none; }
  .new-menu-style #header #mainNav {
    left: 14px; }
  .new-menu-style #header.stickyNavigation #mainNav ul {
    height: 50px; }
  .new-menu-style #header.stickyNavigation #mainNav ul li {
    height: 50px; }
  .new-menu-style #header.stickyNavigation.reducedStickyNavigation #mainNav ul li {
    height: 50px; }
  .new-menu-style #header.stickyNavigation #mainNav ul li a {
    padding-bottom: 0; }
  .new-menu-style #mainNav .wrapper ul li.current-menu-item a,
  .new-menu-style #mainNav .wrapper ul li.current-page-ancestor a {
    border-bottom: none; }
  .new-menu-style #mainNav .MenuButton {
    display: block;
    width: auto;
    height: 50px;
    border: none;
    left: 0;
    right: auto; }
    .new-menu-style #mainNav .MenuButton:hover {
      background-color: transparent;
      border: none; }
      .new-menu-style #mainNav .MenuButton:hover a span {
        text-decoration: underline; }
  .new-menu-style #mainNav .MenuButton a.switch {
    width: auto;
    height: 50px;
    line-height: 50px;
    border-bottom: none;
    color: #0c121c; }
    .new-menu-style #mainNav .MenuButton a.switch span {
      font-weight: 600;
      font-size: 10px;
      letter-spacing: 1.73px;
      margin-left: 6px; }
  .new-menu-style #mainNav .SearchButton {
    display: none;
    height: 50px;
    border-bottom: none; }
  .new-menu-style #mainNav .SearchButton a.switch {
    height: 50px;
    border-bottom: none; }
  .new-menu-style #mainNav .close-mainSearch {
    height: 50px;
    border-bottom: none; }
  .new-menu-style #mainNav .MenuButton {
    border: none; } }

.slideMenuTop #header {
  z-index: 21; }

.slideMenuTop #slideMenu {
  top: 50px; }

.slideMenuTop #slideMenu .close-slide {
  display: none; }

.slideMenuTop #slideMenu .navigation {
  left: 0; }

.slideMenuTop #SearchResults {
  left: 0; }

.slideMenuTop #fade-overlay {
  top: 50px; }

@font-face {
  font-family: 'lrex';
  src: url("../fonts/lrex.eot?79163078");
  src: url("../fonts/lrex.eot?79163078#iefix") format("embedded-opentype"), url("../fonts/lrex.woff2?79163078") format("woff2"), url("../fonts/lrex.woff?79163078") format("woff"), url("../fonts/lrex.ttf?79163078") format("truetype"), url("../fonts/lrex.svg?79163078#lrex") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'lrex';
    src: url('../font/lrex.svg?79163078#lrex') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "lrex";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-menu:before {
  content: '\e800'; }

/* '' */
.icon-search:before {
  content: '\e801'; }

/* '' */
.icon-share:before {
  content: '\e802'; }

/* '' */
.icon-blogger:before {
  content: '\e803'; }

/* '' */
.icon-facebook:before {
  content: '\e804'; }

/* '' */
.icon-googleplus:before {
  content: '\e805'; }

/* '' */
.icon-lenkrad:before {
  content: '\e806'; }

/* '' */
.icon-phone:before {
  content: '\e807'; }

/* '' */
.icon-arrow-down:before {
  content: '\e808'; }

/* '' */
.icon-arrow-up:before {
  content: '\e809'; }

/* '' */
.icon-close:before {
  content: '\e80a'; }

/* '' */
.icon-slide-left:before {
  content: '\e80b'; }

/* '' */
.icon-slide-right:before {
  content: '\e80c'; }

/* '' */
.icon-mail:before {
  content: '\e80d'; }

/* '' */
.icon-info:before {
  content: '\e80e'; }

/* '' */
.icon-arrow-left:before {
  content: '\e80f'; }

/* '' */
.icon-minus:before {
  content: '\e810'; }

/* '' */
.icon-instagram:before {
  content: '\e811'; }

/* '' */
.icon-pinterest:before {
  content: '\e812'; }

/* '' */
.icon-twitter:before {
  content: '\e813'; }

/* '' */
.icon-youtube:before {
  content: '\e814'; }

/* '' */
.icon-gutschein:before {
  content: '\e815'; }

/* '' */
.icon-download:before {
  content: '\e816'; }

/* '' */
.icon-location:before {
  content: '\e817'; }

/* '' */
.icon-quotation-end:before {
  content: '\e818'; }

/* '' */
.icon-quotation-start:before {
  content: '\e819'; }

/* '' */
.icon-car:before {
  content: '\e81a'; }

/* '' */
.icon-film:before {
  content: '\e81b'; }

/* '' */
.icon-plus:before {
  content: '\e81c'; }

/* '' */
.icon-question:before {
  content: '\e81d'; }

/* '' */
.icon-arrow-right:before {
  content: '\e81e'; }

/* '' */
.icon-tick:before {
  content: '\e81f'; }

/* '' */
.icon-group:before {
  content: '\e820'; }

/* '' */
.icon-highlight-begleitung:before {
  content: '\e821'; }

/* '' */
.icon-highlight-unterbringungsstil:before {
  content: '\e822'; }

/* '' */
.icon-scrolldown:before {
  content: '\e90d'; }

/* '' */
[class^="icon-"], [class*=" icon-"] {
  font-family: 'lrex';
  font-style: normal;
  font-weight: normal;
  /* fix buttons height */
  line-height: 1em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */ }

.icon-menu {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe800;&nbsp;'); }

.icon-search {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe801;&nbsp;'); }

.icon-share {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe802;&nbsp;'); }

.icon-blogger {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe803;&nbsp;'); }

.icon-facebook {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe804;&nbsp;'); }

.icon-googleplus {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe805;&nbsp;'); }

.icon-lenkrad {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe806;&nbsp;'); }

.icon-phone {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe807;&nbsp;'); }

.icon-arrow-down {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe808;&nbsp;'); }

.icon-arrow-up {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe809;&nbsp;'); }

.icon-close {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe80a;&nbsp;'); }

.icon-slide-left {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe80b;&nbsp;'); }

.icon-slide-right {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe80c;&nbsp;'); }

.icon-mail {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe80d;&nbsp;'); }

.icon-info {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe80e;&nbsp;'); }

.icon-arrow-left {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe80f;&nbsp;'); }

.icon-minus {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe810;&nbsp;'); }

.icon-instagram {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe811;&nbsp;'); }

.icon-pinterest {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe812;&nbsp;'); }

.icon-twitter {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe813;&nbsp;'); }

.icon-youtube {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe814;&nbsp;'); }

.icon-gutschein {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe815;&nbsp;'); }

.icon-download {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe816;&nbsp;'); }

.icon-location {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe817;&nbsp;'); }

.icon-quotation-end {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe818;&nbsp;'); }

.icon-quotation-start {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe819;&nbsp;'); }

.icon-car {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe81a;&nbsp;'); }

.icon-film {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe81b;&nbsp;'); }

.icon-plus {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe81c;&nbsp;'); }

.icon-question {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe81d;&nbsp;'); }

.icon-arrow-right {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe81e;&nbsp;'); }

.icon-tick {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe81f;&nbsp;'); }

.icon-group {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe820;&nbsp;'); }

/*! jQuery UI - v1.11.1 - 2014-10-16
* http://jqueryui.com
* Includes: core.css, accordion.css, menu.css, selectmenu.css
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-clearfix {
  min-height: 0;
  /* support: IE7 */ }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
  /* support: IE8 */ }

.ui-front {
  z-index: 100; }

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important; }

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-accordion .ui-accordion-header {
  display: block;
  cursor: pointer;
  position: relative;
  margin: 2px 0 0 0;
  padding: .5em .5em .5em .7em;
  min-height: 0;
  /* support: IE7 */
  font-size: 100%; }

.ui-accordion .ui-accordion-icons {
  padding-left: 2.2em; }

.ui-accordion .ui-accordion-icons .ui-accordion-icons {
  padding-left: 2.2em; }

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  left: .5em;
  top: 50%;
  margin-top: -8px; }

.ui-accordion .ui-accordion-content {
  padding: 1em 2.2em;
  border-top: 0;
  overflow: auto; }

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: none; }

.ui-menu .ui-menu {
  position: absolute; }

.ui-menu .ui-menu-item {
  color: #000;
  position: relative;
  margin: 0;
  padding: 3px 1em 3px .4em;
  cursor: pointer;
  min-height: 0;
  /* support: IE7 */
  /* support: IE10, see #8844 */
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"); }

.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0; }

.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: -1px; }

/* icon support */
.ui-menu-icons {
  position: relative; }

.ui-menu-icons .ui-menu-item {
  padding-left: 2em; }

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0; }

/* right-aligned */
.ui-menu .ui-menu-icon {
  left: auto;
  right: 0; }

.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: none; }

.ui-selectmenu-menu .ui-menu {
  background-color: #fff;
  border: 1px solid #000;
  overflow: auto;
  /* Support: IE7 */
  overflow-x: hidden;
  padding-bottom: 1px; }

.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
  padding: 2px 0.4em;
  margin: 0.5em 0 0 0;
  height: auto;
  border: 0; }

.ui-selectmenu-open {
  display: block; }

.ui-selectmenu-button {
  height: 38px;
  border: 1px solid #000;
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  cursor: pointer; }

.ui-selectmenu-button span.ui-icon {
  right: 0.5em;
  left: auto;
  margin-top: -8px;
  position: absolute;
  top: 50%; }

.ui-selectmenu-button span.ui-selectmenu-text {
  text-align: left;
  padding: 0.6em 2.1em 0.4em 1em;
  display: block;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* Weiterentwicklung Kopfbereich */
/*

class = "kopfbereich $typ $height $align $style "

$typ
default = schlicht, nix
.mitbild = Bild im Hintergrund

$height
default = schlicht (wie Unterseiten, Impressum...)
.fullheight = volle Höhe (Hauptseiten) --> Scrolldown muss vorhanden sein
.subheight = 400px --> kein Scrolldown erfordlich, --> keine vertikale Zentrierung

$align
Kopfbereich kann folgende Textpositionierung haben:
default = zentriert
.linksbuendig = linksbuendig

$style = darkestgrey
(zum vererben von style-Angaben für smartphone-Version!) 

.lazy:before {
	content:'';
	width: 700px;
	height: 467px;
	display: block;
	background-color: blue;
}
loading customisation*/
.loading {
  background-size: 40px !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  transition: none !important;
  position: relative; }

.grey .lazy:before, .grey .lazy:after, .darkestgrey .lazy:before, .darkestgrey .lazy:after {
  background-color: #fff !important; }

.lazy:before, .lazy:after {
  content: '';
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.9;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  transform: scale(0);
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out; }

.kopfbereich .lazy:before, .kopfbereich .lazy:after {
  top: 6%;
  left: 5%; }

.zambezi-logo .spinner, .zambezi-logo .double-bounce1, .zambezi-logo .double-bounce2 {
  display: none !important; }

.zambezi-logo:before, .zambezi-logo:after {
  display: none !important;
  width: 0px;
  height: 0px; }

.kopf-content .spinner {
  display: none !important; }

.lazy:after {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
  content: ''; }

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px; }

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  transform: scale(0);
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out; }

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

.img-loaded {
  display: none; }

.loaded:before, .loaded:after {
  content: none !important; }

.Slider-Team .spinner {
  margin-top: 90px !important;
  top: 0; }

.page-template-page-tour-2019-mit-location-php .GridListWrapper .spinner, .page-template-page-tour-2017-peru-map .GridListWrapper .spinner {
  margin-top: -30%; }

.page-template-page-events .GridListWrapper .spinner, .page-template-page-reisen .GridListWrapper .spinner {
  margin-top: -20px !important; }

.page-template-page-fahrangebote-php
.darkestgrey .double-bounce1, .darkestgrey .double-bounce2 {
  /*background-color: #fff !important;*/ }

.page-template-page-tour-2019-mit-location-php .GridListWrapper .double-bounce1, .page-template-page-tour-2019-mit-location-php .GridListWrapper .double-bounce2, .page-template-page-tour-2017-peru-map .GridListWrapper .double-bounce2, .page-template-page-tour-2017-peru-map .GridListWrapper .double-bounce1 {
  background-color: #fff !important; }

.block .image {
  position: relative; }

.kopfbereich .home .light a {
  display: block !important; }

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

/* Allgemein */
.kopfbereich {
  width: 100%;
  text-align: center;
  padding: 80px 90px;
  position: relative; }

.kopfbereich.with-map {
  padding: 0px 90px 0px 90px; }

.kopfbereich.subheight {
  height: 500px; }

@media screen and (min-width: 1px) and (max-width: 1600px) {
  .kopfbereich.subheight {
    height: 400px; } }

.kopfbereich.fullheight {
  min-height: 400px; }

@media screen and (max-width: 740px) {
  .kopfbereich.fullheight {
    min-height: 100px; } }

.kopfbereich.linksbuendig {
  text-align: left; }

@media (max-width: 740px) {
  .kopfbereich {
    padding: 40px 30px; }
  .kopfbereich.mitbild {
    height: auto !important;
    min-height: auto !important; }
  .kopfbereich.with-map {
    padding: 0px 30px; } }

.centered-broschure {
  display: inline !important; }

.secondaryLinkWithStyle {
  position: relative !important; }

.background-teaser-image-home {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  z-index: 4;
  pointer-events: none;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100;Neutral+Density */
  background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, black 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
  /* IE6-9 */ }

/* Hero-Headline */
.kopfbereich h1 {
  padding: 0;
  font-family: lr_ot4, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  line-height: 1.1em;
  max-width: 800px;
  /* zu pruefen */
  margin: 0 auto 0 auto;
  /* zu pruefen */
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  max-width: 100%;
  padding: 0; }

@media screen and (max-width: 740px) {
  .kopfbereich h1 {
    font-size: 24px; } }

@media (max-width: 768px) {
  .page-template-page-fahrangebote .kopf-content, .page-template-page-reisen-php .kopf-content {
    max-width: 700px !important; } }

/* Absatz */
.kopfbereich p {
  margin: 30px auto 0 auto;
  max-width: 800px;
  font-size: 16px; }

.kopfbereich.darkestgrey p, .kopfbereich.grey p {
  color: #cbcbcb; }

@media screen and (max-width: 740px) {
  .kopfbereich p {
    margin: 20px auto 0 auto;
    font-size: 14px; } }

/* Hintergrundbild */
.kopfbereich.mitbild {
  padding: 0;
  background: black; }

.kopfbereich.mitbild .kopf-bild {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover; }

@media screen and (max-width: 740px) {
  .kopfbereich.mitbild .kopf-bild {
    position: relative;
    height: 250px; } }

@media screen and (min-width: 1px) and (max-width: 600px) {
  .kopfbereich.mitbild .kopf-bild {
    height: 250px; } }

@media screen and (min-width: 1px) and (max-width: 390px) {
  .kopfbereich.mitbild .kopf-bild {
    height: 150px; } }

@media screen and (min-width: 1px) and (max-width: 390px) {
  .kopfbereich.mitbild.fullheight .kopf-bild {
    height: 250px; } }

.kopfbereich.mitbild .dark-image {
  opacity: 0.9;
  background-color: #000; }

.kopfbereich.mitbild .kopf-content-wrap {
  display: inline-block;
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
  vertical-align: middle; }

@media screen and (max-width: 740px) {
  .kopfbereich.mitbild .kopf-content-wrap {
    display: block;
    position: relative;
    height: auto;
    padding: 30px 20px; } }

@media screen and (max-width: 740px) {
  .kopfbereich.mitbild.bildschmal .kopf-content-wrap {
    padding: 0px; } }

.kopfbereich.mitbild .kopf-content {
  display: inline-block;
  margin: 0 auto;
  max-width: 1000px;
  vertical-align: middle;
  padding: 0 50px;
  max-width: 840px; }

.kopfbereich.with-map .kopf-content {
  padding: 20px 0px 12px 0px; }

.kopfbereich.mitbild.subheight .kopf-content {
  vertical-align: top;
  padding-top: 80px;
  max-width: 650px;
  /* war vorher 560px */ }

.page-id-2650 .kopfbereich.mitbild.subheight .kopf-content,
.page-id-2547 .kopfbereich.mitbild.subheight .kopf-content,
.page-id-2583 .kopfbereich.mitbild.subheight .kopf-content,
.page-id-2675 .kopfbereich.mitbild.subheight .kopf-content {
  max-width: 720px; }

@media (max-width: 900px) {
  .kopfbereich.mitbild.subheight .kopf-content {
    padding-top: 40px; } }

@media (max-width: 740px) {
  .kopfbereich.mitbild.subheight .kopf-content {
    padding-top: 0;
    text-align: center; } }

/* mittig etwas ausgleichen wegen Scrolldown */
.kopfbereich.mitbild.fullheight .kopf-content {
  margin-bottom: 50px; }

.home .kopfbereich.mitbild.fullheight .kopf-content {
  margin-bottom: 0; }

@media (max-width: 900px) {
  .kopfbereich.mitbild.mitscrolldown .kopf-content {
    margin-bottom: 0;
    /* hier gibt es keinen scrolldown */ } }

.page-template-page-tour-above-and-beyond .kopfbereich.mitbild.fullheight .kopf-content {
  margin-bottom: 150px; }

@media screen and (max-width: 900px) {
  .kopfbereich.mitbild .kopf-content {
    padding: 0 40px; } }

@media screen and (max-width: 740px) {
  .kopfbereich.mitbild .kopf-content {
    padding: 0;
    display: block;
    margin: 0 auto !important;
    width: 100%; } }

/* vertically centered helper */
.mittighelper {
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

@media screen and (max-width: 740px) {
  .mittighelper {
    display: none; } }

/* Headline */
.kopfbereich.mitbild h1 {
  font-size: 48px;
  font-family: lr_ot4,Arial,Helvetica,sans-serif;
  font-weight: 500;
  line-height: 1.1em;
  letter-spacing: 0.15em;
  margin-bottom: 0; }

@media screen and (max-width: 740px) {
  .kopfbereich.mitbild h1 {
    font-size: 26px; } }

.with-map {
  background-color: #fff;
  color: #363636; }

/* Absatz */
.kopfbereich.mitbild p {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.12em;
  margin: 20px auto 0 auto;
  max-width: 600px;
  color: white; }

.kopfbereich.mitbild.linksbuendig p {
  margin-left: 0;
  margin-right: 0; }

@media screen and (max-width: 740px) {
  .kopfbereich.mitbild p {
    font-size: 14px;
    letter-spacing: 0;
    max-width: none;
    color: #cbcbcb; } }

/* Basis-Schatten */
.kopfbereich .kopf-content {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.3); }

.kopfbereich .kopf-content.dark-shadow {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.7); }

.kopfbereich.with-map .kopf-content {
  text-shadow: none; }

.kopfbereich.with-map.with-tour-logo-small .kopf-content {
  padding: 10px 0 5px 0; }

/* Weitere Inhalte im kopf-content */
.kopf-logo {
  max-width: 100%; }

.tour-logo {
  width: 350px;
  height: auto;
  margin: 0 auto; }

@media screen and (max-width: 740px) {
  .tour-logo {
    width: 300px;
    margin: 40px auto; } }

@media screen and (min-width: 1px) and (max-width: 480px) {
  .tour-logo {
    width: 115px;
    margin: 20px auto; } }

@media screen and (min-width: 740px) and (max-height: 700px) {
  .tour-logo {
    width: 250px; } }

.ie9 .tour-logo[src*=".svg"] {
  width: 100%; }

/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .tour-logo[src*=".svg"] {
    width: 100%; } }

.tour-logo-small {
  width: auto;
  height: 80px;
  margin: 0 auto; }

.record-logo {
  width: 350px;
  height: auto;
  margin: 0 auto 40px; }

@media screen and (max-width: 1600px) {
  .record-logo {
    width: 300px; } }

@media screen and (max-width: 740px) {
  .record-logo {
    position: absolute;
    top: -215px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    margin: 0 auto; } }

@media screen and (min-width: 740px) and (max-height: 790px) {
  .record-logo {
    width: 200px;
    margin: 20px auto; } }

.ie9 .record-logo[src*=".svg"] {
  width: 100%; }

/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .record-logo[src*=".svg"] {
    width: 100%; } }

.zambezi-logo {
  width: 700px;
  height: auto;
  margin: 0 auto; }

@media screen and (max-width: 1600px) {
  .zambezi-logo {
    width: 500px; } }

@media screen and (max-width: 740px) {
  .zambezi-logo {
    width: 210px;
    position: absolute;
    top: -240px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto; } }

@media screen and (min-width: 740px) and (max-height: 700px) {
  .zambezi-logo {
    width: 210px;
    margin: 0 auto 20px; } }

.ie9 .zambezi-logo[src*=".svg"] {
  width: 100%; }

/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .zambezi-logo[src*=".svg"] {
    width: 100%; } }

.zambezi-logo-small {
  max-width: 430px; }

@media screen and (max-width: 1600px) {
  .zambezi-logo-small {
    max-width: 290px; } }

.abt-logo {
  max-width: 75%; }

.dmax-banner-desktop {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9; }

@media screen and (max-width: 740px) {
  .dmax-banner-desktop {
    display: none; } }

.dmax-banner-mobile {
  position: relative;
  margin: 0 auto;
  display: block;
  height: auto;
  margin-bottom: -6px; }

.dmax-banner-mobile img {
  max-width: 100%;
  width: 100%;
  height: auto; }

@media screen and (min-width: 741px) {
  .dmax-banner-mobile {
    display: none; } }

.kopf-content small {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-weight: 500; }

.with-map .kopf-content small {
  /*font-size: 11px;*/ }

@media screen and (max-width: 740px) {
  .kopf-content small {
    font-size: 10px; } }

.kopf-content .button-wrap {
  margin-top: 20px; }

.kopf-content .button {
  min-width: 200px;
  margin-top: 10px; }

/* Text mit Linien oben und unten */
p.prominent {
  margin-top: 20px;
  max-width: 70%; }

p.prominent em {
  color: white;
  display: inline-block;
  font-style: normal;
  font-family: lr_ot4, Arial, Helvetica, sans-serif;
  line-height: 60px;
  font-size: 30px;
  letter-spacing: 2px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 0 5px; }

@media screen and (max-width: 740px) {
  p.prominent em {
    line-height: 40px;
    font-size: 12px; } }

/* Countdown */
.countdown-wrap {
  margin-top: 40px; }

@media screen and (min-width: 1px) and (max-width: 480px) {
  .countdown-wrap {
    margin-top: 0px;
    padding-bottom: 20px; } }

.countdown-wrap p {
  color: white;
  display: block;
  font-style: normal;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  line-height: 20px;
  font-size: 16px !important;
  letter-spacing: 0 !important; }

.countdown-wrap em {
  color: white;
  display: inline-block;
  font-style: normal;
  font-weight: bold !important;
  font-family: lr_ot4, Arial, Helvetica, sans-serif;
  line-height: 60px;
  font-size: 37px !important;
  letter-spacing: 0 !important; }

@media screen and (min-width: 1px) and (max-width: 480px) {
  .countdown-wrap em {
    line-height: 24px;
    font-size: 20px !important; } }

.countdown-wrap .countdown-period {
  font-size: 19px !important; }

@media screen and (min-width: 1px) and (max-width: 480px) {
  .countdown-wrap .countdown-period {
    font-size: 14px !important; } }

/* ----------------------------------------- Scrolldown-Button */
#scrolldown-wrap {
  margin: 0 auto;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center; }

.ScrollDown {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #0c121c;
  color: #fff;
  text-decoration: none;
  text-align: center;
  transition: background-color .5s,color .5s;
  position: relative;
  border: 0;
  z-index: 20; }

@media (max-width: 900px) {
  #scrolldown-wrap {
    display: none; } }

@media (max-height: 600px) {
  #scrolldown-wrap {
    display: none; } }

.ScrollDown:after {
  font-family: 'lrex',sans-serif;
  content: '\e90d';
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  font-size: 25px;
  position: relative;
  top: -1px; }

.ScrollDown:focus,
.ScrollDown:hover {
  background-color: #fff;
  color: #0c121c; }

#stoerer-wrap {
  position: absolute;
  right: 10%;
  top: 10%;
  border: 1px solid #fff; }

@media screen and (max-width: 768px) {
  #stoerer-wrap {
    right: auto;
    top: auto;
    margin: 0 auto;
    bottom: 10px;
    left: 50%;
    display: inline-block;
    transform: translateX(-50%); } }

@media screen and (max-width: 600px) {
  #stoerer-wrap {
    position: relative;
    right: auto;
    top: auto;
    margin: 0 auto;
    bottom: 10px;
    left: auto;
    display: inline-block;
    transform: translateX(0); } }

#stoerer-wrap .ScrollDown {
  font-size: 18px;
  display: block;
  height: auto;
  line-height: 25px;
  padding: 15px;
  background: #E05206; }

#stoerer-wrap .ScrollDown:focus,
#stoerer-wrap .ScrollDown:hover {
  background: #4e4e4e; }

#stoerer-wrap .ScrollDown:after {
  line-height: 25px;
  height: 25px; }

@media screen and (max-width: 600px) {
  #stoerer-wrap .ScrollDown {
    font-size: 14px;
    line-height: 19px;
    padding: 10px; } }

.page-template-page-tour-2017-peru .ScrollDown {
  background: #E05206;
  border: 1px solid #fff; }

.page-template-page-tour-2017-peru .ScrollDown:focus,
.page-template-page-tour-2017-peru .ScrollDown:hover {
  background: #4e4e4e; }

.page-template-page-tour-2019-mit-location .kopf-content h2 {
  font-weight: bold; }

/* ----------------------------------------- Video */
#video {
  position: absolute;
  bottom: 0px;
  right: 0px;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden; }

.video-js {
  width: auto !important;
  height: auto !important; }

video,
.video-js .vjs-tech {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%; }

/* .kopfbereich.mitbild.darkestgrey für mobile Farben invertieren */
@media (max-width: 740px) {
  .kopfbereich.mitbild.darkestgrey,
  .kopfbereich.mitbild {
    background-color: #fff !important;
    background: #fff !important;
    color: #000 !important; }
    .kopfbereich.mitbild.darkestgrey h1, .kopfbereich.mitbild.darkestgrey h2, .kopfbereich.mitbild.darkestgrey h3, .kopfbereich.mitbild.darkestgrey h4, .kopfbereich.mitbild.darkestgrey h5, .kopfbereich.mitbild.darkestgrey h6, .kopfbereich.mitbild.darkestgrey p, .kopfbereich.mitbild.darkestgrey a:not(.button),
    .kopfbereich.mitbild h1,
    .kopfbereich.mitbild h2,
    .kopfbereich.mitbild h3,
    .kopfbereich.mitbild h4,
    .kopfbereich.mitbild h5,
    .kopfbereich.mitbild h6,
    .kopfbereich.mitbild p,
    .kopfbereich.mitbild a:not(.button) {
      color: #000 !important; }
  .kopfbereich .kopf-content {
    background-image: none !important;
    text-shadow: none;
    text-align: center; } }

/* Slider */
.slick-slider {
  cursor: grab; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

/*.slick-loading .slick-list { background: white url("../ajax-loader.gif") center center no-repeat; }*/
.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  zoom: 1; }

.slick-track:before, .slick-track:after {
  content: "";
  display: table; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }

.slick-slide img {
  display: block;
  pointer-events: none; }

.white .slick-slide img {
  border-radius: 50%; }

.team-2015.white .slick-slide img {
  border-radius: 0; }

.white .image-module-slider .slick-slide img {
  border-radius: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev, .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent; }

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: 'icons',sans-serif;
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.white .slick-prev:before,
.white .slick-next:before {
  color: black; }

.light .slick-prev:before,
.light .slick-next:before {
  color: black; }

.darkestgrey .slick-prev:before,
.darkestgrey .slick-next:before {
  color: white; }

.slick-prev {
  left: 0px !important; }

.slick-prev:before {
  content: '\e00b'; }

.slick-next {
  right: 0px !important; }

.slick-next:before {
  content: '\e00c'; }

/* Dots */
.slick-slider {
  margin-bottom: 18px; }

.sep {
  font-size: 20px;
  padding-top: 3px;
  margin: 0 !important; }

#currentSlide {
  margin-right: 0px; }

.totalSlides {
  margin-left: 0px !important; }

.slick-dots {
  list-style: none;
  display: inline-block;
  text-align: center;
  padding: 0;
  margin: auto;
  display: block !important;
  font-size: 12px; }

.slick-dots li {
  vertical-align: middle;
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer; }

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\2022";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75; }

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px; }

[dir="rtl"] .slick-next:before {
  content: "\2190"; }

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto; }

[dir="rtl"] .slick-prev:before {
  content: "\2192"; }

[dir="rtl"] .slick-slide {
  float: right; }

.counter {
  text-align: center; }

.tablesorter-default {
  width: 100%;
  font: 12px/18px Arial,Sans-serif;
  color: #333;
  background-color: #fff;
  border-spacing: 0;
  margin: 10px 0 15px;
  text-align: left; }

.tablesorter-default th, .tablesorter-default thead td {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border-collapse: collapse;
  border-bottom: #ccc 2px solid;
  padding: 0; }

.tablesorter-default tfoot td, .tablesorter-default tfoot th {
  border: 0; }

.tablesorter-default .header, .tablesorter-default .tablesorter-header {
  background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
  background-position: center right;
  background-repeat: no-repeat;
  cursor: pointer;
  white-space: normal;
  padding: 4px 20px 4px 4px; }

.tablesorter-default thead .headerSortUp, .tablesorter-default thead .tablesorter-headerAsc, .tablesorter-default thead .tablesorter-headerSortUp {
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
  border-bottom: #000 2px solid; }

.tablesorter-default thead .headerSortDown, .tablesorter-default thead .tablesorter-headerDesc, .tablesorter-default thead .tablesorter-headerSortDown {
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
  border-bottom: #000 2px solid; }

.tablesorter-default thead .sorter-false {
  background-image: none;
  cursor: default;
  padding: 4px; }

.tablesorter-default tfoot .tablesorter-headerAsc, .tablesorter-default tfoot .tablesorter-headerDesc, .tablesorter-default tfoot .tablesorter-headerSortDown, .tablesorter-default tfoot .tablesorter-headerSortUp {
  border-top: #000 2px solid; }

.tablesorter-default td {
  background-color: #fff;
  border-bottom: #ccc 1px solid;
  padding: 4px;
  vertical-align: top; }

.tablesorter-default tbody > tr.even:hover > td, .tablesorter-default tbody > tr.hover > td, .tablesorter-default tbody > tr.odd:hover > td, .tablesorter-default tbody > tr:hover > td {
  background-color: #fff;
  color: #000; }

.tablesorter-default .tablesorter-processing {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-image: url(data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=) !important; }

.tablesorter-default tr.odd > td {
  background-color: #dfdfdf; }

.tablesorter-default tr.even > td {
  background-color: #efefef; }

.tablesorter-default tr.odd td.primary {
  background-color: #bfbfbf; }

.tablesorter-default td.primary, .tablesorter-default tr.even td.primary {
  background-color: #d9d9d9; }

.tablesorter-default tr.odd td.secondary {
  background-color: #d9d9d9; }

.tablesorter-default td.secondary, .tablesorter-default tr.even td.secondary {
  background-color: #e6e6e6; }

.tablesorter-default tr.odd td.tertiary {
  background-color: #e6e6e6; }

.tablesorter-default td.tertiary, .tablesorter-default tr.even td.tertiary {
  background-color: #f2f2f2; }

caption {
  background-color: #fff; }

.tablesorter-default .tablesorter-filter-row {
  background-color: #eee; }

.tablesorter-default .tablesorter-filter-row td {
  background-color: #eee;
  border-bottom: #ccc 1px solid;
  line-height: normal;
  text-align: center;
  -webkit-transition: line-height .1s ease;
  -moz-transition: line-height .1s ease;
  -o-transition: line-height .1s ease;
  transition: line-height .1s ease; }

.tablesorter-default .tablesorter-filter-row .disabled {
  opacity: .5;
  cursor: not-allowed; }

.tablesorter-default .tablesorter-filter-row.hideme td {
  padding: 2px;
  margin: 0;
  line-height: 0;
  cursor: pointer; }

.tablesorter-default .tablesorter-filter-row.hideme * {
  height: 1px;
  min-height: 0;
  border: 0;
  padding: 0;
  margin: 0;
  /* don't use visibility: hidden because it disables tabbing */
  opacity: 0; }

.tablesorter-default input.tablesorter-filter, .tablesorter-default select.tablesorter-filter {
  width: 95%;
  height: auto;
  margin: 4px auto;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #bbb;
  color: #333;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: height .1s ease;
  -moz-transition: height .1s ease;
  -o-transition: height .1s ease;
  transition: height .1s ease; }

.tablesorter .filtered {
  display: none; }

.tablesorter .tablesorter-errorRow td {
  text-align: center;
  cursor: pointer;
  background-color: #e6bf99; }

.desktop-only {
  display: block; }

.mobile-only {
  display: none !important; }

@media (max-width: 1024px) {
  .desktop-only {
    display: none !important; }
  .mobile-only {
    display: block !important; } }

/* required styles */
#map-container {
  width: 100%;
  /*height: 600px;*/
  background-color: #ddd;
  padding: 0;
  background-image: url("../images/texture-grey-mist.png");
  background-repeat: repeat;
  position: relative; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #map-container {
    padding-bottom: 10px; } }

#map-container .headings {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #map-container .headings {
    position: relative; } }

#map-container .headings .heading {
  font-weight: bold;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.7); }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #map-container .headings .heading {
    color: #000;
    font-size: 24px;
    line-height: 1.1em;
    letter-spacing: 0.15em;
    font-weight: 100;
    text-align: center;
    margin: 0 auto 30px auto;
    text-shadow: none; } }

#map-wrapper {
  height: 600px; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #map-wrapper {
    height: 300px; } }

.pop-info h1 {
  width: 100%;
  color: #000;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
  /*padding-top:10px;*/ }

.leaflet-popup-content {
  min-width: 251px !important;
  width: 251px; }

.leaflet-popup-content .pop-info p {
  width: 100%;
  margin: 10px 0;
  color: #222; }

.leaflet-container a.leaflet-popup-close-button {
  display: none; }

.mapbutton {
  margin-top: 5px; }

.pop-info p span {
  color: #000; }

#map-popup-mobile {
  display: none;
  width: 100%;
  height: auto;
  padding: 5px; }

#map-popup-mobile > .wrapper {
  padding: 5px; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #map-popup-mobile {
    display: block; } }

@media screen and (min-width: 741px) {
  .map-fixed-popups #map-popup-mobile {
    display: block;
    width: auto;
    position: absolute;
    top: 82px;
    left: 10px; } }

#map-popup-mobile > .wrapper > .mobi-map-item {
  display: none;
  background-color: #FFFFFF;
  padding: 5px; }

@media screen and (min-width: 741px) {
  .map-fixed-popups #map-popup-mobile > .wrapper > .mobi-map-item {
    width: 274px;
    padding: 10px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4); } }

#map-popup-mobile > .wrapper > .mobi-map-item.active {
  display: block; }

#map-popup-mobile > .wrapper > .mobi-map-item > img {
  width: 100%; }

#map-popup-mobile .pop-info a {
  color: #222; }

@media screen and (min-width: 741px) {
  .map-fixed-popups #map-popup-mobile .pop-info p {
    margin: 10px 0;
    color: #222; } }

#map-nav {
  position: absolute;
  z-index: 6;
  left: 41px;
  margin-top: 5px;
  width: 160px;
  top: 0px;
  /*display: none;*/ }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #map-nav {
    width: 100%;
    position: relative;
    left: 0;
    background-color: #FFFFFF;
    display: inline; } }

@media screen and (min-width: 741px) {
  .map-fixed-popups #map-nav,
  .map-zoomcontrol-bottom #map-nav {
    left: 15px;
    margin-top: 15px; } }

#map-nav .wrapper {
  clear: both;
  overflow: hidden;
  width: 100%; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  #map-nav .wrapper {
    margin: 0 auto;
    width: 124px; } }

#map-nav .wrapper a:before {
  font-family: 'icons',sans-serif;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  text-transform: none;
  width: 1em;
  margin-right: .2em;
  margin-left: .2em;
  margin-top: -2px;
  font-size: 32px;
  line-height: 52px;
  font-weight: 900; }

#map-nav .wrapper a {
  float: left;
  height: 52px;
  width: 52px;
  margin: 5px;
  background-color: #fff;
  text-align: center;
  color: #000; }

#map-nav .wrapper a:hover {
  background-color: #f4f4f4; }

.left-open-bigicon-:before {
  content: '\e00b'; }

.right-open-bigicon-:before {
  content: '\e00c'; }

.triangle-pointer {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 17.3px 10px 0;
  border-color: transparent #ffffff transparent transparent;
  line-height: 0px;
  _border-color: #000000 #ffffff #000000 #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000'); }

/* END Custom classes */
.leaflet-marker-pane > .leaflet-marker-icon {
  color: #999;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  background-color: #999;
  left: 6px;
  overflow: hidden;
  top: 2px; }

.map-tour-peru .leaflet-marker-pane > .leaflet-marker-icon,
.map-tour-kaza .leaflet-marker-pane > .leaflet-marker-icon {
  color: #000;
  background-color: #000;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  border: none;
  left: 6px;
  overflow: hidden;
  top: 2px; }

.leaflet-marker-pane > .leaflet-marker-icon.passed {
  color: #000;
  background-color: #000; }

.leaflet-marker-pane > .leaflet-marker-icon.is-flightDay,
.leaflet-marker-pane > .leaflet-marker-icon.passed.is-flightDay {
  color: #E05206;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  background-color: #E05206;
  left: 6px;
  overflow: hidden;
  top: 2px;
  background-image: url("../images/airplane.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center; }

.leaflet-marker-pane > .leaflet-marker-icon:hover {
  background-color: #E05206; }

.leaflet-marker-pane > .leaflet-marker-icon.active {
  color: #E05206 !important;
  background-color: #E05206 !important;
  opacity: 1 !important;
  z-index: 10000000 !important; }

.map-tour-peru .leaflet-marker-pane > .leaflet-marker-icon.active,
.map-tour-kaza .leaflet-marker-pane > .leaflet-marker-icon.active {
  width: 25px !important;
  height: 25px !important;
  border: 4px solid #fff !important;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4) !important;
  top: -3px;
  left: 0px; }

.leaflet-marker-icon:before {
  color: #000;
  font-size: 17px;
  line-height: 17px; }

.leaflet-marker-icon-active:before {
  color: #E05206;
  font-size: 17px;
  line-height: 17px;
  margin-top: -19px;
  display: none; }

.leaflet-marker-icon-active {
  display: none; }

.leaflet-marker-icon:hover:before {
  color: #FFF; }

.leaflet-marker-icon:hover .leaflet-marker-icon-active {
  display: block; }

.leaflet-marker-pane > .leaflet-marker-icon.is-noBubbleDay,
.leaflet-marker-icon-active.is-noBubbleDay {
  display: none !important; }

.leaflet-map-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-pane,
.leaflet-tile-container,
.leaflet-overlay-pane,
.leaflet-shadow-pane,
.leaflet-marker-pane,
.leaflet-popup-pane,
.leaflet-overlay-pane svg,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0; }

.leaflet-container {
  overflow: hidden;
  -ms-touch-action: none; }

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none; }

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block; }

/* map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container img {
  max-width: none !important; }

/* stupid Android 2 doesn't understand "max-width: none" properly */
.leaflet-container img.leaflet-image-layer {
  max-width: 15000px !important; }

.leaflet-tile {
  filter: inherit;
  visibility: hidden; }

.leaflet-tile-loaded {
  visibility: inherit; }

.leaflet-zoom-box {
  width: 0;
  height: 0; }

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none; }

.leaflet-tile-pane {
  z-index: 2; }

.leaflet-objects-pane {
  z-index: 3; }

.leaflet-overlay-pane {
  z-index: 4; }

.leaflet-shadow-pane {
  z-index: 5; }

.leaflet-marker-pane {
  z-index: 6; }

.leaflet-popup-pane {
  z-index: 7; }

.leaflet-vml-shape {
  width: 1px;
  height: 1px; }

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute; }

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 7;
  pointer-events: auto; }

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1;
  pointer-events: none; }

.leaflet-top {
  top: 0; }

.leaflet-right {
  right: 0; }

.leaflet-bottom {
  bottom: 0; }

.leaflet-left {
  left: 0; }

.leaflet-control {
  float: left;
  clear: both; }

.leaflet-right .leaflet-control {
  float: right; }

.leaflet-top .leaflet-control {
  margin-top: 10px; }

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px; }

.leaflet-left .leaflet-control {
  margin-left: 10px; }

.leaflet-right .leaflet-control {
  margin-right: 10px; }

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile,
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

.leaflet-fade-anim .leaflet-tile-loaded,
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1); }

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none; }

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden; }

/* cursors */
.leaflet-clickable {
  cursor: pointer; }

.leaflet-container {
  cursor: -webkit-grab;
  cursor: -moz-grab; }

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto; }

.leaflet-dragging .leaflet-container,
.leaflet-dragging .leaflet-clickable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing; }

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0; }

.leaflet-container a {
  color: #0078A8; }

.leaflet-container a.leaflet-active {
  outline: 2px solid orange; }

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5); }

/* general typography */
.leaflet-container {
  font: 12px/1.5 Avenir, Arial, Helvetica, sans-serif; }

.leaflet-container .leaflet-popup-content > img {
  width: 251px !important;
  height: 166px !important; }

/* general toolbar styles */
.leaflet-bar {
  box-shadow: none;
  border-radius: 0px; }

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black; }

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block; }

.leaflet-bar a:hover {
  background-color: #f4f4f4; }

.leaflet-bar a:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

.leaflet-bar a:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none; }

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb; }

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px; }

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px; }

.leaflet-control-zoom-out {
  font-size: 20px; }

.leaflet-touch .leaflet-control-zoom-in {
  font-size: 22px; }

.leaflet-touch .leaflet-control-zoom-out {
  font-size: 24px; }

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px; }

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px; }

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px; }

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px; }

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none; }

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative; }

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff; }

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px; }

.leaflet-control-layers label {
  display: block; }

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px; }

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0; }

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333; }

.leaflet-control-attribution a {
  text-decoration: none; }

.leaflet-control-attribution a:hover {
  text-decoration: underline; }

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px; }

.leaflet-left .leaflet-control-scale {
  margin-left: 5px; }

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px; }

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5); }

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px; }

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777; }

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none; }

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box; }

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center; }

.map-tour-kaza .leaflet-popup:not(.inverted) {
  left: 35px !important; }

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left; }

.leaflet-popup-content {
  margin: 10px;
  line-height: 1.4; }

.leaflet-popup-content p {
  margin: 18px 0; }

.leaflet-popup-tip-container {
  margin-left: -17px;
  height: 34px;
  width: 17px;
  top: 30px;
  position: absolute;
  overflow: hidden; }

.leaflet-popup-tip {
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-right: 17px solid #FFFFFF;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  position: relative; }

.leaflet-popup-content-wrapper {
  background: white;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4); }

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent; }

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999; }

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd; }

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1; }

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678); }

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px; }

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999; }

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666; }

.inverted {
  left: -305px !important; }

.inverted .leaflet-popup-tip-container {
  margin-left: 274px;
  height: 34px;
  width: 17px;
  top: 30px;
  position: absolute;
  overflow: hidden; }

.inverted .leaflet-popup-tip {
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid #FFFFFF;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  position: relative; }

.map-fixed-popups .leaflet-left,
.map-zoomcontrol-bottom .leaflet-left {
  left: auto !important;
  right: 10px !important; }

.map-fixed-popups .leaflet-top,
.map-zoomcontrol-bottom .leaflet-top {
  top: auto !important;
  bottom: 25px !important; }

/*!
Video.js Default Styles (http://videojs.com)
Version 4.10.2
Create your own skin at http://designer.videojs.com
*/
/* SKIN
================================================================================
The main class name for all skin-specific styles. To make your own skin,
replace all occurances of 'vjs-default-skin' with a new name. Then add your new
skin name to your video tag instead of the default skin.
e.g. <video class="video-js my-skin-name">
*/
.vjs-default-skin {
  color: #cccccc; }

/* Custom Icon Font
--------------------------------------------------------------------------------
The control icons are from a custom font. Each icon corresponds to a character
(e.g. "\e001"). Font icons allow for easy scaling and coloring of icons.
*/
@font-face {
  font-family: 'VideoJS';
  src: url("../fonts/vjs.eot");
  src: url("../fonts/vjs.eot?#iefix") format("embedded-opentype"), url("../fonts/vjs.woff") format("woff"), url("../fonts/vjs.ttf") format("truetype"), url("../fonts/vjs.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Base UI Component Classes
--------------------------------------------------------------------------------
*/
/* Slider - used for Volume bar and Seek bar */
.vjs-default-skin .vjs-slider {
  /* Replace browser focus hightlight with handle highlight */
  outline: 0;
  position: relative;
  cursor: pointer;
  padding: 0;
  /* background-color-with-alpha */
  background-color: #333333;
  background-color: rgba(51, 51, 51, 0.9); }

.vjs-default-skin .vjs-slider:focus {
  /* box-shadow */
  -webkit-box-shadow: 0 0 2em #ffffff;
  -moz-box-shadow: 0 0 2em #ffffff;
  box-shadow: 0 0 2em #ffffff; }

.vjs-default-skin .vjs-slider-handle {
  position: absolute;
  /* Needed for IE6 */
  left: 0;
  top: 0; }

.vjs-default-skin .vjs-slider-handle:before {
  content: "\e009";
  font-family: VideoJS;
  font-size: 1em;
  line-height: 1;
  text-align: center;
  text-shadow: 0em 0em 1em #fff;
  position: absolute;
  top: 0;
  left: 0;
  /* Rotate the square icon to make a diamond */
  /* transform */
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

/* Control Bar
--------------------------------------------------------------------------------
The default control bar that is a container for most of the controls.
*/
.vjs-default-skin .vjs-control-bar {
  /* Start hidden */
  display: none;
  position: absolute;
  /* Place control bar at the bottom of the player box/video.
     If you want more margin below the control bar, add more height. */
  bottom: 0;
  /* Use left/right to stretch to 100% width of player div */
  left: 0;
  right: 0;
  /* Height includes any margin you want above or below control items */
  height: 3.0em;
  /* background-color-with-alpha */
  background-color: #07141e;
  background-color: rgba(7, 20, 30, 0.7); }

/* Show the control bar only once the video has started playing */
.vjs-default-skin.vjs-has-started .vjs-control-bar {
  display: block;
  /* Visibility needed to make sure things hide in older browsers too. */
  visibility: visible;
  opacity: 1;
  /* transition */
  -webkit-transition: visibility 0.1s, opacity 0.1s;
  -moz-transition: visibility 0.1s, opacity 0.1s;
  -o-transition: visibility 0.1s, opacity 0.1s;
  transition: visibility 0.1s, opacity 0.1s; }

/* Hide the control bar when the video is playing and the user is inactive  */
.vjs-default-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  display: block;
  visibility: hidden;
  opacity: 0;
  /* transition */
  -webkit-transition: visibility 1s, opacity 1s;
  -moz-transition: visibility 1s, opacity 1s;
  -o-transition: visibility 1s, opacity 1s;
  transition: visibility 1s, opacity 1s; }

.vjs-default-skin.vjs-controls-disabled .vjs-control-bar {
  display: none; }

.vjs-default-skin.vjs-using-native-controls .vjs-control-bar {
  display: none; }

/* The control bar shouldn't show after an error */
.vjs-default-skin.vjs-error .vjs-control-bar {
  display: none; }

/* Don't hide the control bar if it's audio */
.vjs-audio.vjs-default-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1;
  visibility: visible; }

/* IE8 is flakey with fonts, and you have to change the actual content to force
fonts to show/hide properly.
  - "\9" IE8 hack didn't work for this
  - Found in XP IE8 from http://modern.ie. Does not show up in "IE8 mode" in IE9
*/
@media \0screen {
  .vjs-default-skin.vjs-user-inactive.vjs-playing .vjs-control-bar :before {
    content: ""; } }

/* General styles for individual controls. */
.vjs-default-skin .vjs-control {
  outline: none;
  position: relative;
  float: left;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 3.0em;
  width: 4em; }

/* Font button icons */
.vjs-default-skin .vjs-control:before {
  font-family: VideoJS;
  font-size: 1.5em;
  line-height: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); }

/* Replacement for focus outline */
.vjs-default-skin .vjs-control:focus:before,
.vjs-default-skin .vjs-control:hover:before {
  text-shadow: 0em 0em 1em #ffffff; }

.vjs-default-skin .vjs-control:focus {
  /*  outline: 0; */
  /* keyboard-only users cannot see the focus on several of the UI elements when
  this is set to 0 */ }

/* Hide control text visually, but have it available for screenreaders */
.vjs-default-skin .vjs-control-text {
  /* hide-visually */
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/* Play/Pause
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-play-control {
  width: 5em;
  cursor: pointer; }

.vjs-default-skin .vjs-play-control:before {
  content: "\e001"; }

.vjs-default-skin.vjs-playing .vjs-play-control:before {
  content: "\e002"; }

/* Playback toggle
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-playback-rate .vjs-playback-rate-value {
  font-size: 1.5em;
  line-height: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); }

.vjs-default-skin .vjs-playback-rate.vjs-menu-button .vjs-menu .vjs-menu-content {
  width: 4em;
  left: -2em;
  list-style: none; }

/* Volume/Mute
-------------------------------------------------------------------------------- */
.vjs-default-skin .vjs-mute-control,
.vjs-default-skin .vjs-volume-menu-button {
  cursor: pointer;
  float: right; }

.vjs-default-skin .vjs-mute-control:before,
.vjs-default-skin .vjs-volume-menu-button:before {
  content: "\e006"; }

.vjs-default-skin .vjs-mute-control.vjs-vol-0:before,
.vjs-default-skin .vjs-volume-menu-button.vjs-vol-0:before {
  content: "\e003"; }

.vjs-default-skin .vjs-mute-control.vjs-vol-1:before,
.vjs-default-skin .vjs-volume-menu-button.vjs-vol-1:before {
  content: "\e004"; }

.vjs-default-skin .vjs-mute-control.vjs-vol-2:before,
.vjs-default-skin .vjs-volume-menu-button.vjs-vol-2:before {
  content: "\e005"; }

.vjs-default-skin .vjs-volume-control {
  width: 5em;
  float: right; }

.vjs-default-skin .vjs-volume-bar {
  width: 5em;
  height: 0.6em;
  margin: 1.1em auto 0; }

.vjs-default-skin .vjs-volume-level {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.5em;
  /* assuming volume starts at 1.0 */
  width: 100%;
  background: #66a8cc url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAP0lEQVQIHWWMAQoAIAgDR/QJ/Ub//04+w7ZICBwcOg5FZi5iBB82AGzixEglJrd4TVK5XUJpskSTEvpdFzX9AB2pGziSQcvAAAAAAElFTkSuQmCC) -50% 0 repeat; }

.vjs-default-skin .vjs-volume-bar .vjs-volume-handle {
  width: 0.5em;
  height: 0.5em;
  /* Assumes volume starts at 1.0. If you change the size of the
     handle relative to the volume bar, you'll need to update this value
     too. */
  left: 4.5em; }

.vjs-default-skin .vjs-volume-handle:before {
  font-size: 0.9em;
  top: -0.2em;
  left: -0.2em;
  width: 1em;
  height: 1em; }

/* The volume menu button is like menu buttons (captions/subtitles) but works
    a little differently. It needs to be possible to tab to the volume slider
    without hitting space bar on the menu button. To do this we're not using
    display:none to hide the slider menu by default, and instead setting the
    width and height to zero. */
.vjs-default-skin .vjs-volume-menu-button .vjs-menu {
  display: block;
  width: 0;
  height: 0;
  border-top-color: transparent; }

.vjs-default-skin .vjs-volume-menu-button .vjs-menu .vjs-menu-content {
  height: 0;
  width: 0; }

.vjs-default-skin .vjs-volume-menu-button:hover .vjs-menu,
.vjs-default-skin .vjs-volume-menu-button .vjs-menu.vjs-lock-showing {
  border-top-color: rgba(7, 40, 50, 0.5);
  /* Same as ul background */ }

.vjs-default-skin .vjs-volume-menu-button:hover .vjs-menu .vjs-menu-content,
.vjs-default-skin .vjs-volume-menu-button .vjs-menu.vjs-lock-showing .vjs-menu-content {
  height: 2.9em;
  width: 10em; }

/* Progress
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-progress-control {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  font-size: 0.3em;
  height: 1em;
  /* Set above the rest of the controls. */
  top: -1em;
  /* Shrink the bar slower than it grows. */
  /* transition */
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; }

/* On hover, make the progress bar grow to something that's more clickable.
    This simply changes the overall font for the progress bar, and this
    updates both the em-based widths and heights, as wells as the icon font */
.vjs-default-skin:hover .vjs-progress-control {
  font-size: .9em;
  /* Even though we're not changing the top/height, we need to include them in
      the transition so they're handled correctly. */
  /* transition */
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; }

/* Box containing play and load progresses. Also acts as seek scrubber. */
.vjs-default-skin .vjs-progress-holder {
  height: 100%; }

/* Progress Bars */
.vjs-default-skin .vjs-progress-holder .vjs-play-progress,
.vjs-default-skin .vjs-progress-holder .vjs-load-progress,
.vjs-default-skin .vjs-progress-holder .vjs-load-progress div {
  position: absolute;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  /* updated by javascript during playback */
  width: 0;
  /* Needed for IE6 */
  left: 0;
  top: 0; }

.vjs-default-skin .vjs-play-progress {
  /*
    Using a data URI to create the white diagonal lines with a transparent
      background. Surprisingly works in IE8.
      Created using http://www.patternify.com
    Changing the first color value will change the bar color.
    Also using a paralax effect to make the lines move backwards.
      The -50% left position makes that happen.
  */
  background: #66a8cc url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAP0lEQVQIHWWMAQoAIAgDR/QJ/Ub//04+w7ZICBwcOg5FZi5iBB82AGzixEglJrd4TVK5XUJpskSTEvpdFzX9AB2pGziSQcvAAAAAAElFTkSuQmCC) -50% 0 repeat; }

.vjs-default-skin .vjs-load-progress {
  background: #646464;
  background: rgba(255, 255, 255, 0.2); }

/* there are child elements of the load progress bar that represent the
   specific time ranges that have been buffered */
.vjs-default-skin .vjs-load-progress div {
  background: #787878;
  background: rgba(255, 255, 255, 0.1); }

.vjs-default-skin .vjs-seek-handle {
  width: 1.5em;
  height: 100%; }

.vjs-default-skin .vjs-seek-handle:before {
  padding-top: 0.1em; }

/* Live Mode
--------------------------------------------------------------------------------
*/
.vjs-default-skin.vjs-live .vjs-time-controls,
.vjs-default-skin.vjs-live .vjs-time-divider,
.vjs-default-skin.vjs-live .vjs-progress-control {
  display: none; }

.vjs-default-skin.vjs-live .vjs-live-display {
  display: block; }

/* Live Display
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-live-display {
  display: none;
  font-size: 1em;
  line-height: 3em; }

/* Time Display
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-time-controls {
  font-size: 1em;
  /* Align vertically by making the line height the same as the control bar */
  line-height: 3em; }

.vjs-default-skin .vjs-current-time {
  float: left; }

.vjs-default-skin .vjs-duration {
  float: left; }

/* Remaining time is in the HTML, but not included in default design */
.vjs-default-skin .vjs-remaining-time {
  display: none;
  float: left; }

.vjs-time-divider {
  float: left;
  line-height: 3em; }

/* Fullscreen
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-fullscreen-control {
  width: 3.8em;
  cursor: pointer;
  float: right; }

.vjs-default-skin .vjs-fullscreen-control:before {
  content: "\e000"; }

/* Switch to the exit icon when the player is in fullscreen */
.vjs-default-skin.vjs-fullscreen .vjs-fullscreen-control:before {
  content: "\e00b"; }

/* Big Play Button (play button at start)
--------------------------------------------------------------------------------
Positioning of the play button in the center or other corners can be done more
easily in the skin designer. http://designer.videojs.com/
*/
.vjs-default-skin .vjs-big-play-button {
  left: 0.5em;
  top: 0.5em;
  font-size: 3em;
  display: block;
  z-index: 2;
  position: absolute;
  width: 4em;
  height: 2.6em;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  opacity: 1;
  /* Need a slightly gray bg so it can be seen on black backgrounds */
  /* background-color-with-alpha */
  background-color: #07141e;
  background-color: rgba(7, 20, 30, 0.7);
  border: 0.1em solid #3b4249;
  /* border-radius */
  -webkit-border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  border-radius: 0.8em;
  /* box-shadow */
  -webkit-box-shadow: 0px 0px 1em rgba(255, 255, 255, 0.25);
  -moz-box-shadow: 0px 0px 1em rgba(255, 255, 255, 0.25);
  box-shadow: 0px 0px 1em rgba(255, 255, 255, 0.25);
  /* transition */
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; }

/* Optionally center */
.vjs-default-skin.vjs-big-play-centered .vjs-big-play-button {
  /* Center it horizontally */
  left: 50%;
  margin-left: -2.1em;
  /* Center it vertically */
  top: 50%;
  margin-top: -1.4000000000000001em; }

/* Hide if controls are disabled */
.vjs-default-skin.vjs-controls-disabled .vjs-big-play-button {
  display: none; }

/* Hide when video starts playing */
.vjs-default-skin.vjs-has-started .vjs-big-play-button {
  display: none; }

/* Hide on mobile devices. Remove when we stop using native controls
    by default on mobile  */
.vjs-default-skin.vjs-using-native-controls .vjs-big-play-button {
  display: none; }

.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin .vjs-big-play-button:focus {
  outline: 0;
  border-color: #fff;
  /* IE8 needs a non-glow hover state */
  background-color: #505050;
  background-color: rgba(50, 50, 50, 0.75);
  /* box-shadow */
  -webkit-box-shadow: 0 0 3em #ffffff;
  -moz-box-shadow: 0 0 3em #ffffff;
  box-shadow: 0 0 3em #ffffff;
  /* transition */
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s; }

.vjs-default-skin .vjs-big-play-button:before {
  content: "\e001";
  font-family: VideoJS;
  /* In order to center the play icon vertically we need to set the line height
     to the same as the button height */
  line-height: 2.6em;
  text-shadow: 0.05em 0.05em 0.1em #000;
  text-align: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%; }

.vjs-error .vjs-big-play-button {
  display: none; }

/* Error Display
--------------------------------------------------------------------------------
*/
.vjs-error-display {
  display: none; }

.vjs-error .vjs-error-display {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.vjs-error .vjs-error-display:before {
  content: 'X';
  font-family: Arial;
  font-size: 4em;
  color: #666666;
  /* In order to center the play icon vertically we need to set the line height
     to the same as the button height */
  line-height: 1;
  text-shadow: 0.05em 0.05em 0.1em #000;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.5em;
  width: 100%; }

.vjs-error-display div {
  position: absolute;
  bottom: 1em;
  right: 0;
  left: 0;
  font-size: 1.4em;
  text-align: center;
  padding: 3px;
  background: #000000;
  background: rgba(0, 0, 0, 0.5); }

.vjs-error-display a,
.vjs-error-display a:visited {
  color: #F4A460; }

/* Loading Spinner
--------------------------------------------------------------------------------
*/
.vjs-loading-spinner {
  /* Should be hidden by default */
  display: none;
  position: absolute;
  bottom: 40px;
  left: 5%;
  top: 6%;
  margin-left: -0.5em;
  font-size: 4em;
  line-height: 1;
  width: 1em;
  height: 1em;
  opacity: 0.5; }

/* Show the spinner when waiting for data and seeking to a new time */
.vjs-waiting .vjs-loading-spinner,
.vjs-seeking .vjs-loading-spinner {
  display: block;
  /* only animate when showing because it can be processor heavy */
  /* animation
  -webkit-animation: spin 1.5s infinite linear;
  -moz-animation: spin 1.5s infinite linear;
  -o-animation: spin 1.5s infinite linear;
  animation: spin 1.5s infinite linear; */ }

/* Errors are unrecoverable without user interaction so hide the spinner */
.vjs-error .vjs-loading-spinner {
  display: none;
  /* ensure animation doesn't continue while hidden */
  /* animation */
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none; }

.vjs-loading-spinner:before, .vjs-loading-spinner:after {
  content: '';
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out; }

.vjs-loading-spinner:after {
  content: '';
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg); } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(359deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

/* Menu Buttons (Captions/Subtitles/etc.)
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-menu-button {
  float: right;
  cursor: pointer; }

.vjs-default-skin .vjs-menu {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0em;
  /* (Width of vjs-menu - width of button) / 2 */
  width: 0em;
  height: 0em;
  margin-bottom: 3em;
  border-left: 2em solid transparent;
  border-right: 2em solid transparent;
  border-top: 1.55em solid #000000;
  /* Same width top as ul bottom */
  border-top-color: rgba(7, 40, 50, 0.5);
  /* Same as ul background */ }

/* Button Pop-up Menu */
.vjs-default-skin .vjs-menu-button .vjs-menu .vjs-menu-content {
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 10em;
  bottom: 1.5em;
  /* Same bottom as vjs-menu border-top */
  max-height: 15em;
  overflow: auto;
  left: -5em;
  /* Width of menu - width of button / 2 */
  /* background-color-with-alpha */
  background-color: #07141e;
  background-color: rgba(7, 20, 30, 0.7);
  /* box-shadow */
  -webkit-box-shadow: -0.2em -0.2em 0.3em rgba(255, 255, 255, 0.2);
  -moz-box-shadow: -0.2em -0.2em 0.3em rgba(255, 255, 255, 0.2);
  box-shadow: -0.2em -0.2em 0.3em rgba(255, 255, 255, 0.2); }

.vjs-default-skin .vjs-menu-button:hover .vjs-control-content .vjs-menu,
.vjs-default-skin .vjs-control-content .vjs-menu.vjs-lock-showing {
  display: block; }

.vjs-default-skin .vjs-menu-button ul li {
  list-style: none;
  margin: 0;
  padding: 0.3em 0 0.3em 0;
  line-height: 1.4em;
  font-size: 1.2em;
  text-align: center;
  text-transform: lowercase; }

.vjs-default-skin .vjs-menu-button ul li.vjs-selected {
  background-color: #000; }

.vjs-default-skin .vjs-menu-button ul li:focus,
.vjs-default-skin .vjs-menu-button ul li:hover,
.vjs-default-skin .vjs-menu-button ul li.vjs-selected:focus,
.vjs-default-skin .vjs-menu-button ul li.vjs-selected:hover {
  outline: 0;
  color: #111;
  /* background-color-with-alpha */
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.75);
  /* box-shadow */
  -webkit-box-shadow: 0 0 1em #ffffff;
  -moz-box-shadow: 0 0 1em #ffffff;
  box-shadow: 0 0 1em #ffffff; }

.vjs-default-skin .vjs-menu-button ul li.vjs-menu-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1em;
  line-height: 2em;
  padding: 0;
  margin: 0 0 0.3em 0;
  font-weight: bold;
  cursor: default; }

/* Subtitles Button */
.vjs-default-skin .vjs-subtitles-button:before {
  content: "\e00c"; }

/* Captions Button */
.vjs-default-skin .vjs-captions-button:before {
  content: "\e008"; }

/* Chapters Button */
.vjs-default-skin .vjs-chapters-button:before {
  content: "\e00c"; }

.vjs-default-skin .vjs-chapters-button.vjs-menu-button .vjs-menu .vjs-menu-content {
  width: 24em;
  left: -12em; }

/* Replacement for focus outline */
.vjs-default-skin .vjs-captions-button:focus .vjs-control-content:before,
.vjs-default-skin .vjs-captions-button:hover .vjs-control-content:before {
  /* box-shadow */
  -webkit-box-shadow: 0 0 1em #ffffff;
  -moz-box-shadow: 0 0 1em #ffffff;
  box-shadow: 0 0 1em #ffffff; }

/*
REQUIRED STYLES (be careful overriding)
================================================================================
When loading the player, the video tag is replaced with a DIV,
that will hold the video tag or object tag for other playback methods.
The div contains the video playback element (Flash or HTML5) and controls,
and sets the width and height of the video.

** If you want to add some kind of border/padding (e.g. a frame), or special
positioning, use another containing element. Otherwise you risk messing up
control positioning and full window mode. **
*/
.video-js {
  background-color: #000;
  position: relative;
  padding: 0;
  /* Start with 10px for base font size so other dimensions can be em based and
     easily calculable. */
  font-size: 10px;
  /* Allow poster to be vertially aligned. */
  vertical-align: middle;
  /*  display: table-cell; */
  /*This works in Safari but not Firefox.*/
  /* Provide some basic defaults for fonts */
  font-weight: normal;
  font-style: normal;
  /* Avoiding helvetica: issue #376 */
  font-family: Arial, sans-serif;
  /* Turn off user selection (text highlighting) by default.
     The majority of player components will not be text blocks.
     Text areas will need to turn user selection back on. */
  /* user-select */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Playback technology elements expand to the width/height of the containing div
    <video> or <object> */
.video-js .vjs-tech {
  /*
 position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
*/
  /* von Sami angefuegt */
  position: absolute;
  bottom: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden; }

/* Fix for Firefox 9 fullscreen (only if it is enabled). Not needed when
   checking fullScreenEnabled. */
.video-js:-moz-full-screen {
  position: absolute; }

/* Fullscreen Styles */
body.vjs-full-window {
  padding: 0;
  margin: 0;
  height: 100%;
  /* Fix for IE6 full-window. http://www.cssplay.co.uk/layouts/fixed.html */
  overflow-y: auto; }

.video-js.vjs-fullscreen {
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  /* IE6 full-window (underscore hack) */
  _position: absolute; }

.video-js:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important; }

.video-js.vjs-fullscreen.vjs-user-inactive {
  cursor: none; }

/* Poster Styles */
.vjs-poster {
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.vjs-poster img {
  display: block;
  margin: 0 auto;
  max-height: 100%;
  padding: 0;
  width: 100%; }

/* Hide the poster after the video has started playing */
.video-js.vjs-has-started .vjs-poster {
  display: none; }

/* Don't hide the poster if we're playing audio */
.video-js.vjs-audio.vjs-has-started .vjs-poster {
  display: block; }

/* Hide the poster when controls are disabled because it's clickable
    and the native poster can take over */
.video-js.vjs-controls-disabled .vjs-poster {
  display: none; }

/* Hide the poster when native controls are used otherwise it covers them */
.video-js.vjs-using-native-controls .vjs-poster {
  display: none; }

/* Text Track Styles */
/* Overall track holder for both captions and subtitles */
.video-js .vjs-text-track-display {
  text-align: center;
  position: absolute;
  bottom: 4em;
  /* Leave padding on left and right */
  left: 1em;
  right: 1em; }

/* Move captions down when controls aren't being shown */
.video-js.vjs-user-inactive.vjs-playing .vjs-text-track-display {
  bottom: 1em; }

/* Individual tracks */
.video-js .vjs-text-track {
  display: none;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 0.1em;
  /* Transparent black background, or fallback to all black (oldIE) */
  /* background-color-with-alpha */
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.5); }

.video-js .vjs-subtitles {
  color: #ffffff; }

.video-js .vjs-captions {
  color: #ffcc66; }

.vjs-tt-cue {
  display: block; }

/* Increase font-size when fullscreen */
.video-js.vjs-fullscreen .vjs-text-track {
  font-size: 3em; }

/* Hide disabled or unsupported controls */
.vjs-default-skin .vjs-hidden {
  display: none; }

.vjs-lock-showing {
  display: block !important;
  opacity: 1;
  visibility: visible; }

/*  In IE8 w/ no JavaScript (no HTML5 shim), the video tag doesn't register.
    The .video-js classname on the video tag also isn't considered.
    This optional paragraph inside the video tag can provide a message to users
    about what's required to play video. */
.vjs-no-js {
  padding: 20px;
  color: #ccc;
  background-color: #333;
  font-size: 18px;
  font-family: Arial, sans-serif;
  text-align: center;
  width: 300px;
  height: 150px;
  margin: 0px auto; }

.vjs-no-js a,
.vjs-no-js a:visited {
  color: #F4A460; }

/* -----------------------------------------------------------------------------
The original source of this file lives at
https://github.com/videojs/video.js/blob/master/src/css/video-js.less */
/* Magnific Popup CSS */
/* ANGEPASST */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.mfp-bg.textbox {
  opacity: 0.6 !important;
  /* because of chrome 34 bug */
  filter: alpha(opacity=50); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  z-index: 10000 !important;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* padding: 0 8px; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  /*  width: 100%; */
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.mfp-open .mfp-figure {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -ms-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #cccccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #cccccc; }

.mfp-preloader a:hover {
  color: white; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close {
  font-family: Arial,Baskerville,monospace;
  font-size: 28px;
  line-height: 44px;
  text-align: center; }

button.mfp-close:active {
  top: 1px !important; }

.leiste > button.mfp-close {
  font-size: 28px;
  font-style: normal;
  height: 44px;
  line-height: 44px;
  opacity: 0.65;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 44px;
  border: none;
  color: #333; }

@media only screen and (min-width: 0px) and (max-width: 400px) {
  .leiste > button.mfp-close {
    color: #00173a; } }

.leiste > button.mfp-close:hover {
  opacity: 1;
  color: #000; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.popup2 .mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: white;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.popup2 .mfp-close:hover, .popup2 .mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.popup2 .mfp-close:active {
  top: 1px; }

.popup2 .mfp-close-btn-in .mfp-close {
  color: #00173A; }

.popup2 .mfp-image-holder .mfp-close, .popup2 .mfp-iframe-holder .mfp-close {
  color: white;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

#tourcamp-form-wrapper {
  background-color: #fff;
  color: #000;
  padding: 10px 20px 10px 20px; }

@media only screen and (min-width: 0px) and (max-width: 768px) {
  #tourcamp-form-wrapper {
    max-width: 320px; } }

#tourcamp-form-wrapper h2, #tourcamp-form-wrapper label {
  color: #000; }

#tourcamp-form-wrapper #vorname, #tourcamp-form-wrapper #nachname, #tourcamp-form-wrapper #email {
  padding: 10px 5px;
  margin-bottom: 10px; }

@media only screen and (min-width: 0px) and (max-width: 768px) {
  #tourcamp-form-wrapper #submittourcamp, #tourcamp-form-wrapper #vorname, #tourcamp-form-wrapper #nachname, #tourcamp-form-wrapper #email {
    max-width: 280px; }
  #tourcamp-form-wrapper #submittourcamp {
    padding: 12px 12px 11px; } }

#tourcamp-form-wrapper #submittourcamp {
  -webkit-appearance: button;
  border-radius: 0; }

#tourcamp-form-wrapper .leiste {
  height: 20px; }

#tourcamp-form-wrapper .tourcamp-desc {
  max-width: 320px;
  display: block;
  margin-bottom: 20px; }

#tourcamp-form-wrapper .mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #000;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

#tourcamp-form-wrapper .mfp-close:hover, #tourcamp-form-wrapper .mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

#tourcamp-form-wrapper .mfp-close:active {
  top: 1px; }

#tourcamp-form-wrapper .mfp-close-btn-in .mfp-close {
  color: #00173A; }

#tourcamp-form-wrapper .mfp-image-holder .mfp-close, #tourcamp-form-wrapper .mfp-iframe-holder .mfp-close {
  color: white;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-content ul {
  padding-left: 20px !important; }

.mfp-content li {
  list-style-type: disc; }

.mfp-content p {
  margin-bottom: 15px !important; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #cccccc;
  font-size: 12px;
  line-height: 18px; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after, .mfp-arrow .mfp-a {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before, .mfp-arrow .mfp-b {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
  border-right: 17px solid white;
  margin-left: 31px; }

.mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
  border-left: 17px solid white;
  margin-left: 39px; }

.mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
  border-left: 27px solid #3f3f3f; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 90%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: black; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

.mfp-gallery img.mfp-img {
  padding: 140px 70px; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .mfp-gallery img.mfp-img {
    padding: 100px 0px; } }

/* Small caption */
.spepecialfigure .mfp-title {
  margin-top: -140px;
  padding: 0px 0px 0px 75px !important;
  font-size: 10px !important; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .spepecialfigure .mfp-title {
    margin-top: -100px;
    padding: 5px 0px 0px 5px !important; } }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444444; }

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-gallery .mfp-figure:after {
  top: 0;
  bottom: 0;
  background: #333 url(../images/texture-darkest-grey.png) repeat 0 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-gallery .mfp-bottom-bar {
  top: 0;
  height: 70px;
  background-color: #000000;
  margin-top: 0; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .mfp-gallery .mfp-bottom-bar {
    height: 50px; } }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery button.mfp-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  line-height: 70px;
  color: white;
  z-index: 9999; }

@media screen and (min-width: 1px) and (max-width: 740px) {
  .mfp-gallery button.mfp-close {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important; } }

.mfp-gallery .mfp-content {
  position: static; }

.mfp-gallery .mfp-title {
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 100;
  height: 70px;
  letter-spacing: 0.1em;
  line-height: 3em;
  padding: 10px 30px 10px 40px;
  text-transform: uppercase; }

@media (max-width: 740px) {
  .mfp-gallery .mfp-title {
    padding: 17px 20px;
    font-size: 12px;
    width: 75%;
    line-height: 1.25em;
    height: 50px; } }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    /* padding-left: 6px;
    padding-right: 6px; */ } }

.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

/* 

====== 3d unfold ======

*/
.mfp-3d-unfold {
  /* start state */
  /* animate in */
  /* animate out */ }

.mfp-3d-unfold .mfp-content {
  perspective: 2000px; }

.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
  transform: rotateY(-60deg); }

.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  transition: all 0.5s; }

.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: rotateY(0deg); }

.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  transform: rotateY(60deg);
  opacity: 0; }

.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0; }

/* 

====== custom-popup ======

*/
.custom-popup-wrap {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333 url(/images/texture-darkest-grey.png) repeat 0 0; }

.custom-popup-header {
  background: #000;
  *zoom: 1; }

.custom-popup-header:before {
  content: " ";
  display: table; }

.custom-popup-header:after {
  content: " ";
  display: table;
  clear: both; }

.custom-popup-header-title {
  display: block;
  float: left;
  height: 70px;
  padding: 10px 30px 10px 40px;
  font-size: 16px;
  line-height: 3em;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 0.1em; }

@media (max-width: 740px) {
  .custom-popup-header-title {
    padding: 17px 20px;
    font-size: 12px;
    width: 75%;
    line-height: 1.25em;
    height: 50px; } }

.custom-popup-wrap .custom-popup-header-close {
  display: block;
  float: right; }

.custom-popup-wrap .custom-popup-header-close button, .mfp-gallery button.mfp-close {
  display: block;
  border: none;
  padding: 0;
  margin: 0;
  background: #000;
  font-size: 26px;
  width: 70px;
  line-height: 70px;
  color: #FFF;
  font-weight: normal;
  border-left: 1px solid #333;
  cursor: pointer;
  -webkit-transition: background-color .1s linear;
  -moz-transition: background-color .1s linear;
  transition: background-color .1s linear; }

@media (max-width: 740px) {
  .custom-popup-wrap .custom-popup-header-close button {
    line-height: 50px;
    width: 50px; } }

.custom-popup-wrap .custom-popup-header-close button:hover, .mfp-gallery button.mfp-close:hover {
  background: #4e4e4e;
  -webkit-transition: background-color .1s linear;
  -moz-transition: background-color .1s linear;
  transition: background-color .1s linear; }

.custom-popup-content {
  max-height: 720px;
  margin: 30px 0; }

.custom-popup-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 1000px;
  margin: 0 auto; }

@media (min-width: 1001px) {
  .custom-popup-container {
    padding-bottom: 563px; } }

.custom-popup-container iframe,
.custom-popup-container object,
.custom-popup-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* ------- Link in Popup */
.link-in-popup {
  max-width: 577px;
  padding: 35px 45px; }

@media screen and (min-width: 1px) and (max-width: 415px) {
  .link-in-popup {
    max-width: 100%;
    padding: 35px 20px; } }

.link-in-popup .leiste > button.mfp-close {
  font-size: 46px;
  height: 52px;
  color: #373737;
  opacity: 1; }

.link-in-popup .leiste > button.mfp-close:hover {
  opacity: 0.65;
  color: #333; }

.link-in-popup h2 {
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.25;
  text-transform: inherit;
  margin: 10px 0 25px 0;
  color: #373737; }

@media screen and (max-width: 740px) {
  .link-in-popup h2 {
    font-size: 20px; } }

.link-in-popup p {
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.015em;
  padding-bottom: 3px;
  color: #373737; }

.link-in-popup .button {
  margin-bottom: 40px; }

.link-in-popup .question-block {
  margin-bottom: 30px !important; }

.link-in-popup .phoneNumber a {
  color: #373737; }

.link-in-popup p.legal-info {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  color: #888; }

/* Media Popups */
/* aufgerufen via magnefic popup jquery plugin */
/* 
popups at lrex:
1) simple for text alerts and privacy policy
2) for videos
3) for gallerys

*/
.tour-video {
  /*    display: none; */
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333 url(/images/texture-darkest-grey.png) repeat 0 0; }

.tour-video-header {
  background: #000;
  *zoom: 1; }

.tour-video-header:before {
  content: " ";
  display: table; }

.tour-video-header:after {
  content: " ";
  display: table;
  clear: both; }

.video-header-title {
  display: block;
  float: left;
  height: 70px;
  padding: 10px 30px 10px 40px;
  font-size: 16px;
  line-height: 3em;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 0.1em; }

@media (max-width: 740px) {
  .video-header-title {
    padding: 17px 20px;
    font-size: 12px;
    width: 75%;
    line-height: 1.25em;
    height: 50px; } }

#video-modal .video-header-close {
  display: block;
  float: right; }

#video-modal .video-header-close button, .mfp-gallery button.mfp-close {
  display: block;
  border: none;
  padding: 0;
  margin: 0;
  background: #000;
  font-size: 26px;
  width: 70px;
  line-height: 70px;
  color: #FFF;
  font-weight: normal;
  border-left: 1px solid #333;
  cursor: pointer;
  -webkit-transition: background-color .1s linear;
  -moz-transition: background-color .1s linear;
  transition: background-color .1s linear; }

@media (max-width: 740px) {
  #video-modal .video-header-close button {
    line-height: 50px;
    width: 50px; } }

#video-modal .video-header-close button:hover, .mfp-gallery button.mfp-close:hover {
  background: #4e4e4e;
  -webkit-transition: background-color .1s linear;
  -moz-transition: background-color .1s linear;
  transition: background-color .1s linear; }

.tour-video-content {
  max-height: 720px;
  margin: 30px 0; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 1000px;
  margin: 0 auto; }

@media (min-width: 1001px) {
  .video-container {
    padding-bottom: 563px; } }

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.page-id-2547 .wp-video, .page-id-2547 video.wp-video-shortcode, .page-id-2547 .mejs-container, .page-id-2547 .mejs-overlay.load {
  width: 100% !important;
  height: 100% !important; }

.page-id-2547 .wp-video, .page-id-2547 video.wp-video-shortcode {
  max-width: 100% !important; }

/* ----------------------------- Layout */
.Article {
  background-color: #FFF;
  width: 100%; }

.sectionWrapper {
  padding: 80px 0;
  display: block;
  color: #393939;
  max-width: 680px;
  margin: 0 auto; }

@media (max-width: 740px) {
  .sectionWrapper {
    padding: 40px 30px; } }

.checkfrontWrapper {
  padding: 80px 30px;
  display: block;
  margin: 0 auto; }

@media (max-width: 740px) {
  .sectionWrapper {
    padding: 40px 10px; } }

.Article .default-wrap.block {
  height: 409px;
  margin-bottom: 20px; }
  @media screen and (max-width: 740px) {
    .Article .default-wrap.block {
      height: auto; } }

.Article .default-wrap.block.single-image {
  height: 370px;
  margin-bottom: 20px; }
  @media screen and (max-width: 740px) {
    .Article .default-wrap.block.single-image {
      height: auto; } }
  .Article .default-wrap.block.single-image .slick-slider {
    height: 100%;
    margin-bottom: 0; }

.Article .auto-ratio .default-wrap.block.single-image {
  height: auto; }

/* ----------------------------- Typo (der Unterseiten) */
.Article .entry-content div {
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.015em;
  color: #393939; }

.Article p {
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.015em;
  margin: 20px 0;
  color: #393939;
  max-width: 100%; }
  .Article p:first-child {
    margin-top: 0; }
  .Article p:last-child {
    margin-bottom: 0; }

.Article h2 {
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.1;
  margin: 10px 0 20px 0; }

@media screen and (max-width: 740px) {
  .Article h2 {
    font-size: 20px; } }

.Article a:not(.category-link) {
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.015em;
  color: #333;
  text-decoration: underline; }

/* ----------------------------- SEO-Text */
.seotext {
  background: #f8f8f8; }

.seotext .sectionWrapper, .seotext .sectionWrapper p {
  max-width: 1000px; }

@media (max-width: 1020px) {
  .seotext .sectionWrapper {
    padding: 40px 10px; } }

.seotext h1 {
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.1;
  margin: 10px 0 30px 0;
  text-transform: uppercase; }

.seotext h2 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.015em;
  text-transform: none; }

/* ----------------------------- Artikelbild */
.wp-post-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px; }

.image-figure,
.image-wrap {
  max-width: 100%;
  margin: 40px 0 40px; }
  .image-figure img,
  .image-wrap img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto; }

.image-figure .image-wrap {
  margin: 0; }

.image-figure figcaption {
  background-color: #f2f2f2;
  padding: 5px; }

.image-module-wrap {
  margin: 40px 0 40px; }
  .image-module-wrap img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto; }

/* ----------------------------- Gallery */
.gallery {
  margin-top: 30px;
  border-top: 1px solid #ccc;
  padding-top: 70px; }

@media screen and (max-width: 740px) {
  .gallery {
    margin-top: 10px;
    padding-top: 30px; } }

.gallery dl, .gallery dt {
  margin: 0;
  padding: 0; }

.gallery a {
  display: block;
  line-height: 0;
  /* einheitliche abstaende */ }

.gallery img {
  height: 130px;
  width: auto; }

.gallery .gallery-item {
  float: left;
  height: 130px;
  margin: 0 15px 15px 0; }

.gallery img {
  border: 0 !important; }

@media screen and (max-width: 740px) {
  .gallery .gallery-item {
    height: 100px; }
  .gallery img {
    height: 100px; } }

@media screen and (max-width: 400px) {
  .gallery .gallery-item {
    height: 70px; }
  .gallery img {
    height: 70px; } }

/* ----------------------------- Search Result */
.Article.search-result h3 {
  margin-top: 20px;
  padding-bottom: 5px; }

.Article.search-result p {
  margin-top: 0; }

/* ----------------------------- Blog */
.GridListWrapper.blog-wrapper .blog-items {
  margin-left: -10px;
  margin-right: -10px; }

.GridListWrapper.blog-wrapper .juicer-feed .blog-item {
  float: left !important;
  background: transparent !important;
  margin: 0 !important;
  padding: 0 10px 20px;
  border: none !important;
  opacity: 1 !important;
  font-family: Avenir,sans-serif !important; }
  .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-poster {
    background: #fff; }
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-poster a {
      display: block; }
  .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image {
    display: block;
    overflow: hidden;
    background-color: #f9f9f9;
    position: relative; }
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 66.4418212479%; }
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image > .inner-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image > img,
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image > .img,
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image > video,
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image > .background-video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%; }
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image.ie-object-fit {
      background-size: cover;
      background-position: center center; }
      .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image.ie-object-fit > img,
      .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image.ie-object-fit > .img {
        opacity: 0; }
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image.ie-object-fit-video {
      overflow: hidden; }
      .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image.ie-object-fit-video > video,
      .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image.ie-object-fit-video > .background-video {
        top: 50%;
        left: 50%;
        height: auto;
        width: auto;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%); }
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image img {
      margin-bottom: 0;
      transition: all .3s linear;
      text-indent: -10000px; }
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-image:hover img {
      transform: scale(1.05, 1.05); }
  .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-text {
    background: #fff; }
    .GridListWrapper.blog-wrapper .juicer-feed .blog-item .j-text a {
      display: block; }

.GridListWrapper.blog-wrapper .load-more-button {
  width: 250px;
  display: block; }

h2.secondaryHeading.blogHeading {
  font-family: lr_ot4,Arial,Helvetica,sans-serif;
  text-transform: uppercase;
  letter-spacing: .15em;
  line-height: 1.1em;
  max-width: 800px;
  margin: 0 auto;
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  max-width: 100%;
  padding: 0;
  margin-bottom: 50px; }

/* ----------------------------- Single Post */
.post-head-section .section-pre-headline {
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px; }

.post-head-section .author-wrap {
  margin-top: 20px; }

.author-image {
  display: inline-block;
  border-radius: 50%;
  border-color: white;
  width: 30px !important;
  height: 30px;
  margin: 0 11px 0 0;
  padding: 0; }

.author-headline {
  display: inline-block;
  line-height: 30px;
  vertical-align: top;
  margin: 0; }

.article-body {
  width: 100%; }
