/* required styles */
#map-container {
	width: 100%;
	/*height: 600px;*/
	background-color: #ddd; 
	padding: 0;
    background-image: url("../images/texture-grey-mist.png");
    background-repeat: repeat;
    position: relative;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
	#map-container {
		padding-bottom: 10px;
	}
}
#map-container .headings {
	position: absolute;
	top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;	
}
@media screen and (min-width: 1px) and (max-width: 740px) {
	#map-container .headings {
		position: relative;
	}
}
#map-container .headings .heading{
	font-weight: bold;
	text-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}
@media screen and (min-width: 1px) and (max-width: 740px) {
	#map-container .headings .heading {
		color: #000;
	  font-size: 24px;
	  line-height: 1.1em;
	  letter-spacing: 0.15em;
	  font-weight: 100;
    text-align: center;
    margin: 0 auto 30px auto;	   
    text-shadow: none; 
	}
}
#map-wrapper{
	height: 600px;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
	#map-wrapper{
		height: 300px;
		//display: none;
	}
}
.pop-info h1{
    width: 100%;
    color: #000;
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    /*padding-top:10px;*/
}
.leaflet-popup-content{
	min-width: 251px !important;
	width: 251px;
}
.leaflet-popup-content .pop-info p{
    width: 100%; 
    margin: 10px 0;
    color: #222;
}
.leaflet-container a.leaflet-popup-close-button{
    display: none;
}
.mapbutton {
    margin-top: 5px;
}
.pop-info p span{
    color: #000;
}
#map-popup-mobile{
	display: none; 
    width:100%;
    height: auto;
    padding: 5px;
}
#map-popup-mobile > .wrapper{
	padding: 5px;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
	#map-popup-mobile{ 
	    display: block; 
	}
}
@media screen and (min-width: 741px) {
	.map-fixed-popups #map-popup-mobile {
		display: block;
		width: auto;
		position: absolute;
		top: 82px;
		left: 10px;
	}
}
#map-popup-mobile > .wrapper > .mobi-map-item{
	display: none;
	background-color: #FFFFFF;
	padding: 5px;
}
@media screen and (min-width: 741px) {
	.map-fixed-popups #map-popup-mobile > .wrapper > .mobi-map-item {
		width: 274px;
		padding: 10px;
		box-shadow: 0 3px 14px rgba(0,0,0,0.4);
	}
}
#map-popup-mobile > .wrapper  > .mobi-map-item.active{
	display: block; 
}
#map-popup-mobile > .wrapper  > .mobi-map-item > img{
	width: 100%;
}
#map-popup-mobile .pop-info a{
    color: #222;
}
@media screen and (min-width: 741px) {
	.map-fixed-popups #map-popup-mobile .pop-info p {
		margin: 10px 0;
    color: #222;
	}
}
#map-nav{
    position: absolute;
    z-index: 6;
    left: 41px;    
    margin-top: 5px;
    width:160px;
    top: 0px;
    /*display: none;*/
}
@media screen and (min-width: 1px) and (max-width: 740px) {
	#map-nav{
	    width:100%;
	    position: relative;
	    left: 0;
	    background-color: #FFFFFF;
	    display: inline;
	}
}
@media screen and (min-width: 741px) {
	.map-fixed-popups #map-nav,
	.map-zoomcontrol-bottom #map-nav {
		left: 15px;
	  margin-top: 15px;
	}
}
#map-nav .wrapper{
    clear: both;
    overflow: hidden;
    width: 100%;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
	#map-nav .wrapper{ 
	    margin: 0 auto; 
	    width: 124px;
	}
}
#map-nav .wrapper a:before{
    font-family: 'icons',sans-serif;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    text-transform: none;
    width: 1em;
    margin-right: .2em;
    margin-left: .2em;
    margin-top: -2px;
    font-size: 32px;
    line-height: 52px;
    font-weight: 900;
}
#map-nav .wrapper a{
    float: left;
    height: 52px;
    width: 52px;
    margin: 5px;
    background-color: #fff;
    text-align: center;
    color: #000;
}
#map-nav .wrapper a:hover{
	background-color: #f4f4f4;
}
.left-open-bigicon-:before {
    content: '\e00b';
}
.right-open-bigicon-:before {
    content: '\e00c';
}

.triangle-pointer{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 17.3px 10px 0;
	border-color: transparent #ffffff transparent transparent;
	line-height: 0px;
	_border-color: #000000 #ffffff #000000 #000000;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}

/* END Custom classes */

.leaflet-marker-pane > .leaflet-marker-icon{
	color: #999;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: none;
	background-color: #999 ;
	left: 6px;
    overflow: hidden;
    top: 2px;
}

.map-tour-peru .leaflet-marker-pane > .leaflet-marker-icon,
.map-tour-kaza .leaflet-marker-pane > .leaflet-marker-icon {
	//color: #E05206;
	//background-color: #E05206 ;
	color: #000;
	background-color: #000;	
	width: 15px !important;
	height: 15px !important;
	border-radius: 50%;
	border: none;
	left: 6px;
    overflow: hidden;
    top: 2px;
}

.leaflet-marker-pane > .leaflet-marker-icon.passed{
	color: #000;
	background-color: #000 ;
}

.leaflet-marker-pane > .leaflet-marker-icon.is-flightDay,
.leaflet-marker-pane > .leaflet-marker-icon.passed.is-flightDay{
	color: #E05206;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: none;
	background-color: #E05206 ;
	left: 6px;
    overflow: hidden;
    top: 2px;
  background-image: url("../images/airplane.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
}
.leaflet-marker-pane > .leaflet-marker-icon:hover{
	background-color:#E05206;
}
.leaflet-marker-pane > .leaflet-marker-icon.active{
	color: #E05206 !important;
	background-color: #E05206 !important;
	opacity: 1 !important;
	z-index: 10000000 !important;
}
.map-tour-peru .leaflet-marker-pane > .leaflet-marker-icon.active,
.map-tour-kaza .leaflet-marker-pane > .leaflet-marker-icon.active {
	width: 25px !important;
  height: 25px !important;
  border: 4px solid #fff !important;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4) !important;
  top: -3px;
  left: 0px;
}

.leaflet-marker-icon:before{
	color: #000;
	font-size: 17px;
	line-height: 17px; 
}
.leaflet-marker-icon-active:before{
	color: #E05206;
	font-size: 17px;
	line-height: 17px;     
	margin-top: -19px;
	display: none;
}
.leaflet-marker-icon-active{
	display: none;
}
.leaflet-marker-icon:hover:before{
	color: #FFF;
}
.leaflet-marker-icon:hover .leaflet-marker-icon-active{
	display: block;
}
.leaflet-marker-pane > .leaflet-marker-icon.is-noBubbleDay,
.leaflet-marker-icon-active.is-noBubbleDay {
	display: none !important;
}
.leaflet-map-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-pane,
.leaflet-tile-container,
.leaflet-overlay-pane,
.leaflet-shadow-pane,
.leaflet-marker-pane,
.leaflet-popup-pane,
.leaflet-overlay-pane svg,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
	position: absolute;
	left: 0;
	top: 0;
	}
.leaflet-container {
	overflow: hidden;
	-ms-touch-action: none;
	}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
	-webkit-user-drag: none;
	}
.leaflet-marker-icon,
.leaflet-marker-shadow {
	display: block;
	}
/* map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container img {
	max-width: none !important;
	}
/* stupid Android 2 doesn't understand "max-width: none" properly */
.leaflet-container img.leaflet-image-layer {
	max-width: 15000px !important;
	}
.leaflet-tile {
	filter: inherit;
	visibility: hidden;
	}
.leaflet-tile-loaded {
	visibility: inherit;
	}
.leaflet-zoom-box {
	width: 0;
	height: 0;
	}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
	-moz-user-select: none;
	}

.leaflet-tile-pane    { z-index: 2; }
.leaflet-objects-pane { z-index: 3; }
.leaflet-overlay-pane { z-index: 4; }
.leaflet-shadow-pane  { z-index: 5; }
.leaflet-marker-pane  { z-index: 6; }
.leaflet-popup-pane   { z-index: 7; }

.leaflet-vml-shape {
	width: 1px;
	height: 1px;
	}
.lvml {
	behavior: url(#default#VML);
	display: inline-block;
	position: absolute;
	}


/* control positioning */

.leaflet-control {
	position: relative;
	z-index: 7;
	pointer-events: auto;
	}
.leaflet-top,
.leaflet-bottom {
	position: absolute;
	z-index: 1;
	pointer-events: none;
	}
.leaflet-top {
	top: 0;
	}
.leaflet-right {
	right: 0;
	}
.leaflet-bottom {
	bottom: 0;
	}
.leaflet-left {
	left: 0;
	}
.leaflet-control {
	float: left;
	clear: both;
	}
.leaflet-right .leaflet-control {
	float: right;
	}
.leaflet-top .leaflet-control {
	margin-top: 10px;
	}
.leaflet-bottom .leaflet-control {
	margin-bottom: 10px;
	}
.leaflet-left .leaflet-control {
	margin-left: 10px;
	}
.leaflet-right .leaflet-control {
	margin-right: 10px;
	}


/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile,
.leaflet-fade-anim .leaflet-popup {
	opacity: 0;
	-webkit-transition: opacity 0.2s linear;
	   -moz-transition: opacity 0.2s linear;
	     -o-transition: opacity 0.2s linear;
	        transition: opacity 0.2s linear;
	}
.leaflet-fade-anim .leaflet-tile-loaded,
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
	opacity: 1;
	}

.leaflet-zoom-anim .leaflet-zoom-animated {
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
	   -moz-transition:    -moz-transform 0.25s cubic-bezier(0,0,0.25,1);
	     -o-transition:      -o-transform 0.25s cubic-bezier(0,0,0.25,1);
	        transition:         transform 0.25s cubic-bezier(0,0,0.25,1);
	}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated {
	-webkit-transition: none;
	   -moz-transition: none;
	     -o-transition: none;
	        transition: none;
	}

.leaflet-zoom-anim .leaflet-zoom-hide {
	visibility: hidden;
	}


/* cursors */

.leaflet-clickable {
	cursor: pointer;
	}
.leaflet-container {
	cursor: -webkit-grab;
	cursor:    -moz-grab;
	}
.leaflet-popup-pane,
.leaflet-control {
	cursor: auto;
	}
.leaflet-dragging .leaflet-container,
.leaflet-dragging .leaflet-clickable {
	cursor: move;
	cursor: -webkit-grabbing;
	cursor:    -moz-grabbing;
	}


/* visual tweaks */

.leaflet-container {
	background: #ddd;
	outline: 0;
	}
.leaflet-container a {
	color: #0078A8;
	}
.leaflet-container a.leaflet-active {
	outline: 2px solid orange;
	}
.leaflet-zoom-box {
	border: 2px dotted #38f;
	background: rgba(255,255,255,0.5);
	}


/* general typography */
.leaflet-container {
	font: 12px/1.5 Avenir, Arial, Helvetica, sans-serif;
	}
 .leaflet-container .leaflet-popup-content > img{
    width: 251px !important;
    height: 166px !important;
}

/* general toolbar styles */

.leaflet-bar {
	box-shadow: none;
	border-radius: 0px;
	}
.leaflet-bar a,
.leaflet-bar a:hover {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	width: 26px;
	height: 26px;
	line-height: 26px;
	display: block;
	text-align: center;
	text-decoration: none;
	color: black;
	}
.leaflet-bar a,
.leaflet-control-layers-toggle {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	display: block;
	}
.leaflet-bar a:hover {
	background-color: #f4f4f4;
	}
.leaflet-bar a:first-child {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	}
.leaflet-bar a:last-child {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-bottom: none;
	}
.leaflet-bar a.leaflet-disabled {
	cursor: default;
	background-color: #f4f4f4;
	color: #bbb;
	}

.leaflet-touch .leaflet-bar a {
	width: 30px;
	height: 30px;
	line-height: 30px;
	}


/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
	font: bold 18px 'Lucida Console', Monaco, monospace;
	text-indent: 1px;
	}
.leaflet-control-zoom-out {
	font-size: 20px;
	}

.leaflet-touch .leaflet-control-zoom-in {
	font-size: 22px;
	}
.leaflet-touch .leaflet-control-zoom-out {
	font-size: 24px;
	}


/* layers control */

.leaflet-control-layers {
	box-shadow: 0 1px 5px rgba(0,0,0,0.4);
	background: #fff;
	border-radius: 5px;
	}
.leaflet-control-layers-toggle {
	background-image: url(images/layers.png);
	width: 36px;
	height: 36px;
	}
.leaflet-retina .leaflet-control-layers-toggle {
	background-image: url(images/layers-2x.png);
	background-size: 26px 26px;
	}
.leaflet-touch .leaflet-control-layers-toggle {
	width: 44px;
	height: 44px;
	}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
	display: none;
	}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
	display: block;
	position: relative;
	}
.leaflet-control-layers-expanded {
	padding: 6px 10px 6px 6px;
	color: #333;
	background: #fff;
	}
.leaflet-control-layers-selector {
	margin-top: 2px;
	position: relative;
	top: 1px;
	}
.leaflet-control-layers label {
	display: block;
	}
.leaflet-control-layers-separator {
	height: 0;
	border-top: 1px solid #ddd;
	margin: 5px -10px 5px -6px;
	}


/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
	background: #fff;
	background: rgba(255, 255, 255, 0.7);
	margin: 0;
	}
.leaflet-control-attribution,
.leaflet-control-scale-line {
	padding: 0 5px;
	color: #333;
	}
.leaflet-control-attribution a {
	text-decoration: none;
	}
.leaflet-control-attribution a:hover {
	text-decoration: underline;
	}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
	font-size: 11px;
	}
.leaflet-left .leaflet-control-scale {
	margin-left: 5px;
	}
.leaflet-bottom .leaflet-control-scale {
	margin-bottom: 5px;
	}
.leaflet-control-scale-line {
	border: 2px solid #777;
	border-top: none;
	line-height: 1.1;
	padding: 2px 5px 1px;
	font-size: 11px;
	white-space: nowrap;
	overflow: hidden;
	-moz-box-sizing: content-box;
	     box-sizing: content-box;

	background: #fff;
	background: rgba(255, 255, 255, 0.5);
	}
.leaflet-control-scale-line:not(:first-child) {
	border-top: 2px solid #777;
	border-bottom: none;
	margin-top: -2px;
	}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
	border-bottom: 2px solid #777;
	}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	box-shadow: none;
	}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: 2px solid rgba(0,0,0,0.2);
	background-clip: padding-box;
	}


/* popup */

.leaflet-popup {
	position: absolute;
	text-align: center;
	}

.map-tour-kaza .leaflet-popup:not(.inverted) {
	left: 35px !important;
}

.leaflet-popup-content-wrapper {
	padding: 1px;
	text-align: left; 
	}
.leaflet-popup-content {
	margin: 10px;
	line-height: 1.4;
	}
.leaflet-popup-content p {
	margin: 18px 0;
	}
.leaflet-popup-tip-container {
	margin-left: -17px;
	height: 34px;
	width: 17px;
	top: 30px;
	position: absolute;
	overflow: hidden;
	}
.leaflet-popup-tip {
	width: 0; 
	height: 0; 
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent; 
	border-right:17px solid #FFFFFF; 
	box-shadow: 0 3px 14px rgba(0,0,0,0.4);
	position: relative;
	} 
.leaflet-popup-content-wrapper{
	background: white;
	box-shadow: 0 3px 14px rgba(0,0,0,0.4);
	}
.leaflet-container a.leaflet-popup-close-button {
	position: absolute;
	top: 0;
	right: 0;
	padding: 4px 4px 0 0;
	text-align: center;
	width: 18px;
	height: 14px;
	font: 16px/14px Tahoma, Verdana, sans-serif;
	color: #c3c3c3;
	text-decoration: none;
	font-weight: bold;
	background: transparent;
	}
.leaflet-container a.leaflet-popup-close-button:hover {
	color: #999;
	}
.leaflet-popup-scrolled {
	overflow: auto;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	}

.leaflet-oldie .leaflet-popup-content-wrapper {
	zoom: 1;
	}
.leaflet-oldie .leaflet-popup-tip {
	width: 24px;
	margin: 0 auto;

	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
	}
.leaflet-oldie .leaflet-popup-tip-container {
	margin-top: -1px;
	}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
	border: 1px solid #999;
	}


/* div icon */

.leaflet-div-icon {
	background: #fff;
	border: 1px solid #666;
	}

.inverted {
	left: -305px !important;
}
.inverted .leaflet-popup-tip-container {
	margin-left: 274px;
	height: 34px;
	width: 17px;
	top: 30px;
	position: absolute;
	overflow: hidden;
	}
.inverted .leaflet-popup-tip {
	width: 0; 
	height: 0; 
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent; 
	border-left:17px solid #FFFFFF; 
	box-shadow: 0 3px 14px rgba(0,0,0,0.4);
	position: relative;
	}

.map-fixed-popups .leaflet-left,
.map-zoomcontrol-bottom .leaflet-left {
    left: auto !important;
    right: 10px !important;
}

.map-fixed-popups .leaflet-top,
.map-zoomcontrol-bottom .leaflet-top {
    top: auto !important;
    bottom: 25px !important;
}