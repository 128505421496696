/* Media Popups */

/* aufgerufen via magnefic popup jquery plugin */
/* 
popups at lrex:
1) simple for text alerts and privacy policy
2) for videos
3) for gallerys

*/

.tour-video {
 /*    display: none; */
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #333 url(/images/texture-darkest-grey.png) repeat 0 0;
}

.tour-video-header {
    background: #000;
    *zoom: 1;
}

.tour-video-header:before {
    content: " ";
    display: table;
}

.tour-video-header:after {
    content: " ";
    display: table;
    clear: both;
}

.video-header-title {
    display: block;
    float: left;
    height: 70px;
    padding: 10px 30px 10px 40px;
    font-size: 16px;
    line-height: 3em;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 0.1em;
}

@media (max-width: 740px) {
    .video-header-title {
        padding: 17px 20px;
        font-size: 12px;
        width: 75%;
        line-height: 1.25em;
        height: 50px;
    }
}

#video-modal .video-header-close {
    display: block;
    float: right;
}

#video-modal .video-header-close button, .mfp-gallery button.mfp-close {
    display: block;
    border: none;
    padding: 0;
    margin: 0;
    background: #000;
    font-size: 26px;
    width: 70px;
    line-height: 70px;
    color: #FFF;
    font-weight: normal;
    border-left: 1px solid #333;
    cursor: pointer;
    -webkit-transition: background-color .1s linear;
    -moz-transition: background-color .1s linear;
    transition: background-color .1s linear;
}

@media (max-width: 740px) {
   #video-modal .video-header-close button {
        line-height: 50px;
        width: 50px;
    }
}

#video-modal .video-header-close button:hover, .mfp-gallery button.mfp-close:hover {
    background: #4e4e4e;
    -webkit-transition: background-color .1s linear;
    -moz-transition: background-color .1s linear;
    transition: background-color .1s linear;
}

.tour-video-content {
    max-height: 720px;
    margin: 30px 0;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    max-width: 1000px;
    margin: 0 auto;
}

@media (min-width: 1001px) {
    .video-container {
        padding-bottom: 563px;
    }
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



.page-id-2547 .wp-video, .page-id-2547 video.wp-video-shortcode, .page-id-2547 .mejs-container, .page-id-2547 .mejs-overlay.load {
    width: 100% !important;
    height: 100% !important;
}
.page-id-2547 .wp-video, .page-id-2547 video.wp-video-shortcode {
    max-width: 100% !important;
}
.page-id-2547 .mejs-controls {
    //display: none;
}