html.noScroll, html.noScroll body {
/*    bottom: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;*/
    overflow: hidden;
}
#header ul, #header li,
#slideMenu ul, #slideMenu li {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video{
    border: 0 none;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
} 
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
*/

* {
    box-sizing: border-box;
}
#page{
    margin: 0 auto;
    max-width: 2000px;
    width: 100%;
    padding: 0;
    position: relative;
    padding-top: 0px;
}

#content{
    min-height: 400px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

/* ----------------- slideMenu  g   */

#slideMenu{
    position: fixed;
     top:0;
     bottom: 0;
    z-index: 20;
    display: none;
    -webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;

}
#slideMenu.open{
    display: block;
}
#slideMenu .wrapper{
    background-color: #222;
    background-image: url("../images/texture-darkest-grey.png");
    background-repeat: repeat; 
    float: left;
    height: 100%;
}
.lightTheme #slideMenu .wrapper{
    background-color: #e4e8e8; 
    background-image: none;
    background-repeat: no-repeat;
}
#slideMenu .close-slide{
    float: left;
    width: 50px; 
    height: 50px;          
    border-bottom: 1px solid #454545;
    background-color: #4e4e4e;
    z-index: 2;
    cursor: pointer;
    position: relative;
}
.lightTheme #slideMenu .close-slide{
    border-bottom: 1px solid #eef1f1;
    background-color: #fff;
}
#slideMenu .close-slide a{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #ffffff;
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    line-height: 50px;
    margin: 0;
    text-align: center;
    text-decoration: inherit;
    text-indent: 0;
    text-transform: none;
    vertical-align: middle;
    width: 50px;
}
.lightTheme #slideMenu .close-slide a{
    color: #0c121c;
}

#slideMenu .close-slide .icon-menu:before{
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    line-height: 50px;
    margin: 0;
    text-align: center;
    text-indent: 0;
    text-decoration: inherit;
    text-transform: none;
    vertical-align: middle;
    width: 50px;
    font-size: 14px;
    color: #FFFFFF;
    -webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;
    
}

.lightTheme #slideMenu .close-slide .icon-menu:before{
    color: #0c121c;
    
}
#SlideSearch {
    background-color: #0c121c;
    float: left;
    height: 50px;
    position: relative;
    width: 100%;
}
.lightTheme #SlideSearch {
    background-color: #fff;
}
#SlideSearch:before{
    font-size: 10px;
    line-height: 50px;
    padding-left: 20px;
    position: absolute;
}
.lightTheme #SlideSearch:before{
    color: #0c121c;
}
#SlideSearch > span{
    display: block;
    height: 50px;
    overflow: hidden;
    padding: 15px;
}
#SlideInput{
    background-color: #242424;
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    border: medium none;
    color: #fff;
    display: block;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 11px;
    height: 21px;
    letter-spacing: 0.1em;
    line-height: 21px;
    outline: medium none;
    padding: 0 10px 0 25px;
    width: 100%;
}
.lightTheme #SlideInput{
    background-color: #fff;
    color: #0c121c;
}

#slideMenu .navigation{
    bottom: 0;
    left: 50px;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 50px;
}
#slideMenu > .wrapper ul {
    float: left;
    width: 100%;
}
/* --  slideMenu ul.first  ------------------              */

@media screen  and (min-width: 1px) and (min-width: 741px) {
    #slideMenu ul.first{
        display: none;
    }
}
#slideMenu > .wrapper ul.first li{
    
}
#slideMenu > .wrapper ul.first > li {
    background: none repeat scroll 0 0 #0c121c;
    border-top: 1px solid #333;
    clear: both;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.lightTheme #slideMenu > .wrapper ul.first > li {
    background: none repeat scroll 0 0 #fff;
    border-top: 1px solid #eef1f1;
}
#slideMenu > .wrapper ul.first > li a{
    display: block;
    font-size: 10px;
    letter-spacing: 0.15em;
    line-height: 1.3em;
    padding: 18px 55px 18px 25px;
    text-decoration: none;
    color: white;
} 
.lightTheme #slideMenu > .wrapper ul.first > li a{
    color: #4a4f54;
    font-weight: bold;
}

#slideMenu > .wrapper ul.first > li.current-menu-item,
#slideMenu > .wrapper ul.first > li.current-menu-parent {
    background-color: #4e4e4e;
}
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item,
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-parent {
    background-color: #e4e8e8;
}
#slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-item, 
#slideMenu > .wrapper ul.first > li.current-menu-parent li.current-menu-parent 
{
    border-top: 1px solid #4e4e4e ;
}
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-item, 
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-parent li.current-menu-parent 
{
    border-top: 1px solid #e4e8e8;
}
#slideMenu > .wrapper ul.first > li.current-menu-item li,
#slideMenu > .wrapper ul.first > li.current-menu-parent li {
    border-top: 1px solid #454545 ;
}
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item li,
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-parent li {
    border-top: 1px solid #eef1f1;
}
#slideMenu > .wrapper ul.first > li.current-menu-item > a, 
#slideMenu > .wrapper ul.first > li.current-menu-parent > a, 
#slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-item a,
#slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-parent a
{
    background-color: #333;
}  
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item > a, 
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-parent > a, 
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-item a,
.lightTheme #slideMenu > .wrapper ul.first > li.current-menu-item li.current-menu-parent a
{
    background-color: #e4e8e8;
}

#slideMenu > .wrapper ul.first > li ul li a{
    background-color: #4e4e4e;
} 
.lightTheme #slideMenu > .wrapper ul.first > li ul li a{
    background-color: #e4e8e8;
    border-top: 1px solid #d8dcdc;
}     
#slideMenu > .wrapper ul.first > li ul li a:before{
    font-size: 7px;
    padding-right: 4px;
}
#slideMenu > .wrapper ul.first li a:hover{
    background-color: #333;
    background-image:none;
    color: #FFF;
}
.lightTheme #slideMenu > .wrapper ul.first li a:hover{
    background-color: #e4e8e8;
    color: #0c121c;
}
#slideMenu > .wrapper ul.first > li a i{
    float: right;
}
#slideMenu > .wrapper ul.first > li a i:before{
    font-size: 21px;
    position: absolute;
    right: 25px;
    top: 17px;
}
#slideMenu > .wrapper ul.first > li > ul{
    text-transform: uppercase;
    display: none;
    height: auto;
    overflow: hidden;
}
/* --  slideMenu ul.second  ------------------              */
#slideMenu > .wrapper ul.second li{
    text-transform: uppercase;
    height: auto;
    width: 100%;
}
#slideMenu > .wrapper ul.second li a{
    background-color: #222;
    background-image: url("../images/texture-darkest-grey.png");
    background-repeat: repeat;
    color: #cbcbce;
    display: block;
    font-size: 9px;
    letter-spacing: 0.15em;
    line-height: 50px;
    padding: 0 25px;
    text-decoration: none;
} 
.lightTheme #slideMenu > .wrapper ul.second li a{
    background-image: none; 
    color: #4a4f54;
    background-color: #e4e8e8;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.3em;
}      
#slideMenu > .wrapper ul.second li a:hover{
    background-color: #4e4e4e;
    background-image:none;
}
.lightTheme #slideMenu > .wrapper ul.second li a:hover{
    background-color: #e4e8e8;
    color: #0c121c;
}
#slideMenu > .wrapper ul.second li a i:before{
    font-size: 17px;
    margin-right: 10px;
    text-align: center;
    width: 18px;
}
/* --  slideMenu ul.third  ------------------              */
#slideMenu > .wrapper ul.third{
    font-family: Avenir,sans-serif;
    padding: 13px 0;
}
.lightTheme #slideMenu > .wrapper ul.third{
    background-color: #e4e8e8;
}
 #slideMenu > .wrapper ul.third li a span{
    padding-left: 4px;
}        
#slideMenu > .wrapper ul.third li a:hover span{
    color: #4e4e4e;
    padding-left: 4px;
}
.lightTheme #slideMenu > .wrapper ul.third li a:hover span{
    color: #0c121c;
    padding-left: 4px;
}
#slideMenu > .wrapper ul.third li a:before{
    font-size: 7px; 
}
#slideMenu > .wrapper ul.third li a{
    padding: 13px 0;
    color: #cbcbce;
    display: block;
    font-size: 9px;
    letter-spacing: 0.15em;
    padding: 11px 25px;
    text-decoration: none;
    text-transform: uppercase;
}
.lightTheme #slideMenu > .wrapper ul.third li a{
    color: #4a4f54;
    background-color: #e4e8e8;
    font-size: 10px;
    font-weight: bold;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    letter-spacing: 0.3em;
}
#slideMenu .cancel.switch, #searchResults{
    display: none;
}
#slideMenu .cancel{ 
    background-color: #0c121c;
    border: medium none;
    color: #fff;
    cursor: pointer;
    font-size: 10px;
    height: 21px;
    line-height: 12px;
    letter-spacing: 0.15em;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 20px;
    width: 60px;
    text-transform: uppercase;
    font-family: Avenir,Arial,Helvetica,sans-serif;
}
.lightTheme #slideMenu .cancel{ 
    background-color: #fff;
    color: #65696e;
}
#SearchResults{
    top: 50px;
    position: absolute;
    left: 50px;
    right: 0;
    /* first we dont need this because we dont have a live search */
    background-color: #ededed;
    background-image: url("../images/texture-grey-mist.png");
    z-index: 12;

}

.searchFormContainer {
    width: 500px;
    margin: 0 auto;
    position: relative;
}
@media screen and (max-width: 740px) and (min-width: 1px) {
    .searchFormContainer {
        width: auto;
        max-width: 350px;
    }
}
.searchFormContainer .searchform .textfield {
    font-family: "avenir","arial","helvetica",sans-serif;
    border: none;
    font-size: 16px;
    padding: 12px 0px 12px 15px;
    height: 45px;
    width: 100%;
    color: #4a4f54;
    margin: 0 0 20px;    
}
@media screen and (max-width: 740px) and (min-width: 1px) {
    .searchFormContainer .searchButton {
        width: 100%;
        float: none;
        height: 45px;
    }
}

/*    fade-overlay   ------------------------------        */
#fade-overlay{
    background-color: none;
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    margin: 0 auto;
    max-width: 2000px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 11;
    -webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

#fade-overlay.open{
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

/*    header   ------------------------------        */
#header{
    background-color: #0c121c;
    height: auto;
    margin: 0 auto;
    max-width: 2000px;
    width: 100%;
    z-index: 9;
}
.lightTheme #header{
    background-color: #fff;
}
#header > .wrapper{
    position: relative;
    height: 108px;
}

 
/*    header logo   ------------------------------        */

#header #logo {
    height: 108px;
    width: 189px;
    border-right: 1px solid #333;
}
.lightTheme #header #logo {
    border-right: 1px solid #dee2e2;
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    #header #logo {
        height: 49px;
        width: 91px;
        background-color: #0c121c;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 10;
        left: 0;
    }
    .lightTheme #header #logo {
        background-color: #fff;
        border-bottom: 1px solid #dee2e2;
    }
}
#header #logo > .wrapper{
    height: 100%;
    width: 100%;
    position: relative;
} 
#header #logo a {
    position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     z-index: 10;
}
#header #logo a img{
    height: 84px;
    width: 118px;
    margin-left: -59px;
    margin-top: -42px;
    top:50%;
    left: 50%;
    position: absolute; 
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    #header #logo a img{
        height: 42px;
        width: 59px;
        margin-left: -30px;
        margin-top: -21px;
    }
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    #landrover .headerWrapper {
        height: 50px;
    }
}
#mainNav > .wrapper{
    margin-right: 70px;
}

@media screen and (min-width: 1px) and  (max-width: 899px) {
    #mainNav .wrapper{
        margin-right: 50px;
    }
}
@media screen and (min-width: 741px) and  (min-width: 899px) {
    #mainNav .wrapper ul > li{
        display: block !important;
        width: 20%;
        float: left;
    }
}
#mainNav .wrapper ul li.current-menu-item a,
#mainNav .wrapper ul li.current-page-ancestor a
{ 
    background-color: #4e4e4e;
    border-bottom:1px solid #454545;
}

/* highlighting experience-tour hauptnavigation */
body.page-id-2082 li#menu-item-2839 a, /* australien */
body.page-id-17 li#menu-item-2839 a, /*All touren */
body.page-id-426 li#menu-item-2839 a /* Bildband */

{
    background-color: #e4e8e8 !important;
    border-bottom:1px solid #454545;
}

.lightTheme #mainNav .wrapper ul li.current-menu-item a,
.lightTheme #mainNav .wrapper ul li.current-page-ancestor a
{ 
    background-color: #e4e8e8;
    border-bottom:1px solid #dee2e2;
}
#mainNav .wrapper ul li a:hover {
    background-color: #4e4e4e;
    -webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}
.lightTheme #mainNav .wrapper ul li a:hover {
    background-color: #e4e8e8;
}
@media screen and (min-width: 1px) and  (max-width: 740px) {
    #mainNav .wrapper ul li{
        display: none;
    }
    
    #mainNav .MenuButton{
       /* border-left:none !important; */
    }
}
#header #uberMenu{
    position: absolute;
    top: 0;
    right: 0;
}
#mainNav .wrapper ul{
    height: 70px;
}
@media screen and (min-width: 1px) and (max-width: 899px) {

    .MenuButton:hover {
        border-bottom: 1px solid #454545;
        background-color: #4e4e4e;
        -webkit-transition: background-color 200ms linear;
        -moz-transition: background-color 200ms linear;
        -o-transition: background-color 200ms linear;
        -ms-transition: background-color 200ms linear;
        transition: background-color 200ms linear;
    }
    .lightTheme .MenuButton:hover {
        border-bottom: 1px solid #eef1f1;
        background-color: #e4e8e8;
    }
    .MenuButton.open {
        border-bottom: 1px solid #454545;
        background-color: #4e4e4e;
    }
    .lightTheme .MenuButton.open {
        border-bottom: 1px solid #eef1f1;
        background-color: #e4e8e8;
    }
    #header{
        position: fixed;
        top: 0;
    }
    #header #uberMenu{
        display: none;
    }
    #header > .wrapper{
        height: 50px;
    }
    .lightTheme #header > .wrapper{
        border-bottom: 1px solid #dee2e2;
    }
    #mainNav .SearchButton{
        display: none;
    } 
    #mainNav .wrapper ul{
        height: 50px;
    }
}

#mainNav .wrapper ul li a{
    font-size: 10px;
    letter-spacing: 0.35em;    
} 
@media screen and (min-width: 1px) and (max-width: 899px) {
    #mainNav .wrapper ul li a{
        font-size: 9px;
    }
} 
.SearchButton.ready .switch:hover ,
.SearchButton .close-mainSearch:hover{
    cursor: pointer;
    border-bottom: 1px solid #454545;
    background-color: #4e4e4e;
    -webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.lightTheme .SearchButton.ready .switch:hover ,
.lightTheme .SearchButton .close-mainSearch:hover{
    border-bottom: 1px solid #eef1f1;
    background-color: #e4e8e8;
} 

#header #uberMenu li{
    float: left;
    height: 37px;
    line-height: 37px;
    position: relative;
    vertical-align: middle;
}
#header #uberMenu li a:hover{
    color: #FFFFFF;
}
.lightTheme #header #uberMenu li a:hover{
    color: #0c121c;
}
#header #uberMenu li a{margin-right: 3px;
    padding: 0 20px;
}
#header #uberMenu li a i{
    margin-right: 3px;
}
#header #uberMenu li a, #header #uberMenu li{
    text-transform: uppercase;
    color: #cbcbce;
    display: block;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 8px;
    letter-spacing: 0.3em;
    text-decoration: none;
}
.lightTheme #header #uberMenu li a, 
.lightTheme #header #uberMenu li{
    color: #0c121c;
    font-weight: bold;
}
#header #mainNav{
    position: absolute;
    right: 0;
    z-index: 8;
    left: 189px;
    top: 37px;
    border-top: 1px solid #333;
    text-transform: uppercase;

} 
.lightTheme #header #mainNav{
    border-top: 1px solid #dee2e2;

}
@media screen and (min-width: 1px) and (max-width: 899px) {
    #header #mainNav{
        border-top: 0 none;
        left: 91px;
        top: 0;
    }
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    .lightTheme #header #mainNav{
        border-top: 0 none;
        left: 91px;
        top: 0;
    }
}
#mainNav .wrapper ul li{  
    margin-left: 0; 
    border-right: 1px solid #333;
    overflow: hidden;
    height: 100%;
    float: left;
    width: 20%;
}
.lightTheme #mainNav .wrapper ul li{  
    border-right: 1px solid #dee2e2;
}

@media screen and (min-width: 1px) and (max-width: 740px) {
    #mainNav .wrapper ul > li{
        width: auto;
        float: none;
        display: none;
    }
}
#mainNav .wrapper ul li a{
    height: 100%;
    width: 101%;
    background-color: #0c121c;
    border: medium none;
    color: #fff;
    display: table; 
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-weight: 700;
    letter-spacing: 0.35em;
    margin: 0; 
    padding: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}
.lightTheme #mainNav .wrapper ul li a{
    background-color: #fff;
    color: #0c121c;
    font-weight: 900;
    font-size: 9px;
    letter-spacing: 0.2em;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    #landrover .headerWrapper {
        height: 50px;
    } 

    #mainNav .wrapper ul li.current-menu-item {
        display: none;
    }
    #mainNav .wrapper ul > li{
        width: auto;
        float: none;
    } 
}
#mainNav .wrapper ul li a > span{
    display: table-cell; 
    vertical-align: middle;
    line-height: 12px;
}
.lightTheme #mainNav .wrapper ul li a > span{
    line-height: 14px;
    vertical-align: middle;
}
#mainNav .SearchButton{
    overflow: hidden;
    height: 70px;
    width: 70px;
    position: absolute;
    top:0;
    right: 0;
    background-color: #0c121c;
    -webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;
    
}
.lightTheme #mainNav .SearchButton{
    background-color: #fff;
}
#mainNav .close-mainSearch{
    height: 70px;
    width: 70px;
    float: left;
    overflow: hidden;
    display: none;
}
#mainNav .close-mainSearch > span{
    text-indent: -999em;
}
#mainNavSearchForm{

    background-color: #0c121c;
    display: block;
    float: left;
    height: 70px;
    overflow: hidden;
    position: relative;
    z-index: 2;
}
.lightTheme #mainNavSearchForm{
    background-color: #fff;
}
#mainNavSearchForm input{
    background-color: #0c121c;
    border: medium none;
    bottom: 0;
    color: #fff;
    display: block;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 20px;
    height: 70px;
    left: 0;
    letter-spacing: 0.1em;
    line-height: 100%;
    outline: 0 none;
    padding: 0;
    position: absolute;
    right: 0;
    text-indent: 0;
    top: 0;
    width: 100%;
}
.lightTheme #mainNavSearchForm input{
    background-color: #fff;
    color: #0c121c;
}
#mainNavSearchForm label{
    color: #cbcbce;
    display: none;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 20px;
    letter-spacing: 0.1em;
    top: 25px;
    opacity: 0.75;
    position: relative;
    z-index: 2;
    text-transform: none;
}
#mainNavSearchForm label.open{
    display: block;
}
#mainNav .MenuButton{
    display: none;
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    #mainNav .MenuButton{
        overflow: hidden;
        height: 50px;
        width: 50px;
        position: absolute;
        top:0;
        right: 0;
        border-left: 1px solid #333;
        display: block;
    }  
    .lightTheme #mainNav .MenuButton{
        border-left: 1px solid #dee2e2;
    }  
}
    
#mainNav .MenuButton.open{
    border-bottom: 1px solid #454545;
    background-color: #4e4e4e;
}
.lightTheme #mainNav .MenuButton.open{
    border-bottom: 1px solid #eef1f1;
    background-color: #e4e8e8;
}
#mainNav .MenuButton a{
    cursor: pointer; 
    height: 50px;
    line-height: 50px;
    outline: 0 none;
    width: 50px;
    overflow: hidden;
    display: block;
}
#mainNav .MenuButton a:before{
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    line-height: 50px;
    margin: 0;
    text-align: center;
    text-indent: 0;
    text-decoration: inherit;
    text-transform: none;
    vertical-align: middle;
    width: 50px;
    font-size: 14px;
    color: #FFFFFF;
    -webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;
}
.lightTheme #mainNav .MenuButton a:before{
    color: #0c121c;
}
#mainNav .SearchButton.ready a.switch{
    cursor: pointer;
}
#mainNav .SearchButton a.switch{
    cursor: default;
    float: left;
    height: 70px;
    line-height: 70px;
    outline: 0 none;
    width: 70px;
}
#mainNav .SearchButton a:before{
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    line-height: 70px;
    margin: 0;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    vertical-align: middle;
    width: 70px;
    font-size: 18px;
    color: #FFFFFF;
}
.lightTheme #mainNav .SearchButton a:before{
    color: #0c121c;
}
/*  SubNavigation  ---------------------------------------     */

.SubNavigation{
    background: none repeat scroll 0 0 #4e4e4e;
    position: relative;
    width: 100%;
    z-index: 2;
}
.lightTheme .SubNavigation{
    background: none repeat scroll 0 0 #e4e8e8;
}
@media screen  and (min-width: 1px) and (max-width: 740px) {
    .SubNavigation{
        display: none;
    }
}
.SubNavigation .wrapper{
    height: 41px;
    position: relative;
}
.SubNavigation .wrapper ul{
    clear: both;
    overflow: hidden;
    display: flex;
}
.SubNavigation .wrapper ul li{
    float: left;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.2em;
    white-space: nowrap;
}
.lightTheme .SubNavigation .wrapper ul li{
    font-weight: 900;
}
.SubNavigation .wrapper ul li a{
    color: #fff;
    display: block;
    line-height: 41px;
    padding: 0 25px;
    text-decoration: none;
    text-transform: uppercase;
}
@media screen  and (min-width: 741px) and (max-width: 785px) {
    .SubNavigation .wrapper ul li a{
       padding: 0 19px;
    }
}
.lightTheme .SubNavigation .wrapper ul li a{
    color: #0c121c;
}
.SubNavigation .wrapper ul li a:focus, .SubNavigation .wrapper ul li a:hover {
    color: #cbcbce;
}
.lightTheme .SubNavigation .wrapper ul li a:focus, 
.lightTheme .SubNavigation .wrapper ul li a:hover {
    background-color: #d6dada;
    color: #0c121c;
}
.SubNavigation .wrapper ul li.current-menu-item a{
    background-color: #333;
    color: #cbcbce;
}
.lightTheme .SubNavigation .wrapper ul li.current-menu-item a{
    background-color: #d6dada;
    color: #0c121c;
}


/*  stickyNavigation  ---------------------------------------     */

#header.stickyNavigation {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 8;
    transition: all .4s;
}

#header.stickyNavigation.searchActive {
    position: absolute;
}

#header.stickyNavigation .headerWrapper {
    transition: all .4s;
}

#header.stickyNavigation #uberMenu {
    transition: all .4s;
}

#header.stickyNavigation #logo {
    transition: width .4s,height .4s;
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    .lightTheme #header.stickyNavigation #logo {
        border-bottom: 1px solid #fff;
    }
}

#header.stickyNavigation #logo img {
    transition: all .4s;
}

#header.stickyNavigation > .wrapper {
    transition: height .4s;
}

#header.stickyNavigation #mainNav {
    transition: all .4s;
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    #header.stickyNavigation #mainNav {
        top: 0;
        margin-top: -1px;
    }
}  

#header.stickyNavigation #mainNav ul li {
    height: 70px;
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    #header.stickyNavigation #mainNav ul li {
        height: 50px;
    }
}

#header.stickyNavigation #mainNav .wrapper ul li.current-menu-item a, 
#header.stickyNavigation #mainNav .wrapper ul li.current-page-ancestor a,
#header.stickyNavigation #mainNav .wrapper ul li a > span,
#header.stickyNavigation #mainNav ul li.active a,
#header.stickyNavigation #mainNav ul li a,
#header.stickyNavigation #mainNav ul li {
    transition: all .4s;
}

@media screen and (min-width: 1px) and (max-width: 899px) {
    .lightTheme #header.stickyNavigation .MenuButton {
        border-bottom: 1px solid #fff;
    }
}

#header.stickyNavigation #mainNav #mainNavSearchForm,
#header.stickyNavigation #mainNav #mainNavSearchForm input,
#header.stickyNavigation #mainNav .inactiveSearchButton,
#header.stickyNavigation #mainNav .inactiveSearchButton>a,
#header.stickyNavigation #mainNav .SearchButton,
#header.stickyNavigation #mainNav .SearchButton>a,
#header.stickyNavigation #mainNav .SearchButton a:before {
    transition: height .4s,line-height .4s;
}

#header.stickyNavigation .autoCompleteMenu {
    transition: top .4s;
}

#header.stickyNavigation .SubNavigation .SubNavigationContainer {
    transition: height .4s,opacity .4s;
}

#header.stickyNavigation .SubNavigation .SubNavigationContainer .SubNavigationDivider {
    transition: top .4s;
}

#header.stickyNavigation .SubNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer {
    -webkit-transition: height 0;
    -webkit-transition-delay: .4s;
    transition: height 0 .4s;
}

#header.stickyNavigation .SubNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer div {
    transition: line-height .2s;
}

#header.stickyNavigation .SubNavigation .SubNavigationContainer .SubNavigationMainList li a {
    transition: color .4s,line-height .2s;
}

#header.stickyNavigation .SubNavigation .SubNavigationContainer .SubNavigationButtons {
    transition: color .4s,top .4s;
}

#header.stickyNavigation.reducedStickyNavigation {
    opacity: .97;
    box-shadow: 0 0 2px rgba(0,0,0,.1);
}

#header.stickyNavigation.reducedStickyNavigation .headerWrapper {
    height: 50px;
}

#header.stickyNavigation.reducedStickyNavigation #uberMenu {
    margin-top: -70px;
}

#header.stickyNavigation.reducedStickyNavigation #uberMenu .ShoppingToolsDropdown {
    display: none!important;
}

#header.stickyNavigation.reducedStickyNavigation #logo {
    -webkit-transition: width .4s,height .4s;
    -webkit-transition-delay: .1s,0s;
    transition: width .4s .1s,height .4s;
    height: 49px;
}

@media screen and (min-width: 900px){
    #header.stickyNavigation.reducedStickyNavigation #logo img {
        width: 44px;
        height: 31px;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -20px;
    }
} 

#header.stickyNavigation.reducedStickyNavigation > .wrapper {
    height: 50px;
}

#header.stickyNavigation.reducedStickyNavigation #mainNav {
    -webkit-transition: top .4s,left .4s,margin .4s;
    -webkit-transition-delay: 0s,.1s,0s;
    transition: top .4s,left .4s .1s,margin .4s;
    top: 0;
    margin-top: -1px;
}


#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm,
#header.stickyNavigation.reducedStickyNavigation #mainNav .inactiveSearchButton,
#header.stickyNavigation.reducedStickyNavigation #mainNav .inactiveSearchButton>a,
#header.stickyNavigation.reducedStickyNavigation #mainNav .SearchButton,
#header.stickyNavigation.reducedStickyNavigation #mainNav ul li,
#header.stickyNavigation.reducedStickyNavigation #mainNav .SearchButton>a,
#header.stickyNavigation.reducedStickyNavigation #mainNav ul li a {
    height: 50px;
    line-height: 50px;
}

#header.stickyNavigation.reducedStickyNavigation #mainNav .SearchButton a:before {
    line-height: 50px;
}

#header.stickyNavigation.reducedStickyNavigation #mainNav ul {
    transition: height .4s;    
    //height: 50px;
}

#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm,
#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm input {
    height: 50px;
}

#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm .searchText {
    height: 50px;
    line-height: 50px;
}

#header.stickyNavigation.reducedStickyNavigation #mainNav #mainNavSearchForm label {
    top: 1px;
}

#header.stickyNavigation.reducedStickyNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer {
    transition: height 0;
}

#header.stickyNavigation.reducedStickyNavigation .autoCompleteMenu {
    top: 50px;
}

#header.stickyNavigation.notransition,
#header.stickyNavigation.notransition #logo,
#header.stickyNavigation.notransition #logo img,
#header.stickyNavigation.notransition > .wrapper,
#header.stickyNavigation.notransition .headerWrapper,
#header.stickyNavigation.notransition #mainNav,
#header.stickyNavigation.notransition #mainNav ul,
#header.stickyNavigation.notransition #uberMenu {
    transition: none;
}

#header.stickyNavigation.notransition #mainNav .wrapper ul li.current-menu-item a, 
#header.stickyNavigation.notransition #mainNav .wrapper ul li.current-page-ancestor a,
#header.stickyNavigation.notransition #mainNav .wrapper ul li a > span,
#header.stickyNavigation.notransition #mainNav ul li.active a,
#header.stickyNavigation.notransition #mainNav ul li a,
#header.stickyNavigation.notransition #mainNav ul li {
    transition: none; //background-color .4s;
}

#header.stickyNavigation.notransition .autoCompleteMenu,
#header.stickyNavigation.notransition #mainNav .inactiveSearchButton,
#header.stickyNavigation.notransition #mainNav .inactiveSearchButton>a,
#header.stickyNavigation.notransition #mainNav .SearchButton,#header.stickyNavigation.notransition #mainNav .SearchButton>a,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .navigationModelSwitcherButtonContainer div,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .SubNavigationDivider {
    transition: none;
}

#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .SubNavigationButtons,
#header.stickyNavigation.notransition .SubNavigation .SubNavigationContainer .SubNavigationMainList li a {
    transition: color .4s;
}

.experience-tour-2019 #menu-item-7668 a {
    background-color: #d6dada;
    color: #0c121c;
}

.experience-tour-2019 #menu-item-7645 a {
    background-color: #e4e8e8 !important;
    border-bottom: 1px solid #dee2e2 !important;
}

.experience-tour-2017 #menu-item-7642 a {
    background-color: #d6dada;
    color: #0c121c;
}

.experience-tour-2017 #menu-item-7645 a {
background-color: #e4e8e8 !important;
border-bottom: 1px solid #dee2e2 !important;
}

.experience-tour-2015 #menu-item-7643 a {
    background-color: #d6dada;
    color: #0c121c;
}

.experience-tour-2015 #menu-item-7645 a {
    background-color: #e4e8e8 !important;
    border-bottom: 1px solid #dee2e2 !important;
}

.experience-tour-2013 #menu-item-7636 a {
    background-color: #d6dada;
    color: #0c121c;
}

.experience-tour-2013 #menu-item-7645 a {
    background-color: #e4e8e8 !important;
    border-bottom: 1px solid #dee2e2 !important;
}

.sonstiges #menu-item-7637 a {
    background-color: #d6dada;
    color: #0c121c;
}

.sonstiges #menu-item-7645 a {
    background-color: #e4e8e8 !important;
    border-bottom: 1px solid #dee2e2 !important;
}

.new-menu-style {
    #header>.wrapper {
        height: 87px;
    }
    #header.stickyNavigation.reducedStickyNavigation>.wrapper {
        height: 50px;
    }    
    #header #logo {
        border-right: none;
        height: 87px;
        width: 172px;        

        a img {
            margin-top: -32px;
            width: 100%;
            max-width: 89px;
            height: auto;            
        }
    }    
    #header.stickyNavigation.reducedStickyNavigation #logo {
        height: 49px;
    }       
    #uberMenu {
        display: none;
    }
    #header #mainNav {
        border-top: none;
        top: 0;
        left: 139px;
        margin-top: 0;
    }
    #header.stickyNavigation.reducedStickyNavigation #mainNav {
        margin-top: 0;
    }
    #mainNav .wrapper ul {
        height: 87px;
    }    
    #header.stickyNavigation #mainNav ul li {
        height: 87px;
        width: auto;
        border-right: none;
    }    
    #header.stickyNavigation.reducedStickyNavigation #mainNav ul li {
        height: 50px;
    }     
    #header.stickyNavigation #mainNav ul li a {
        padding: 0 10px;
        //padding-bottom: 15px;
        font-weight: 600;
        font-size: 10px;        
        letter-spacing: 1.73px;

        &:hover {
            background-color: transparent;
            text-decoration: underline;
        }
    } 
    #header.stickyNavigation.reducedStickyNavigation #mainNav ul li a {
        padding-bottom: 0;
    }    
    #mainNav .wrapper ul li.current-menu-item a, 
    #mainNav .wrapper ul li.current-page-ancestor a {
        border-bottom: none;

        &:hover {
            background-color: #e4e8e8 !important;
            text-decoration: underline;
        }        
    }  
    #mainNav .SearchButton {
        height: 87px;
        border-bottom: none;
    }   
    #mainNav .SearchButton a.switch {
        height: 87px;
        line-height: 87px;
        border-bottom: none;
    }   
    #mainNavSearchForm input {
        height: 87px;
    }
    #mainNavSearchForm label {
        top: 32px;
    }
    #mainNav .close-mainSearch {
        height: 87px;
        line-height: 87px;
        border-bottom: none;
    }   
    #mainNav .close-mainSearch>span {
        display: none;
    }          
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    .new-menu-style {    
        #header>.wrapper {
            height: 87px;
            border-bottom: none;
        }        
        #header #logo {
            border-right: none;
            height: 87px;
            width: 120px;        
    
            a img {
                margin-top: -32px;
                margin-left: -34px;
                width: 100%;
                max-width: 89px;
                height: auto;            
            }
        }
        #header #mainNav {
            left: 154px;
        }   
        #header.stickyNavigation #mainNav ul li {
            height: 87px;
        }    
        #header.stickyNavigation.reducedStickyNavigation #mainNav ul li {
            height: 87px;
        }     
        #header.stickyNavigation #mainNav ul li a {
            padding-bottom: 0;
        }    
        #mainNav .wrapper ul li.current-menu-item a, 
        #mainNav .wrapper ul li.current-page-ancestor a {
            border-bottom: none;
        } 
        #mainNav .wrapper {
            margin-right: 0;
            //float: right;            
        }
        #mainNav .MenuButton {
            display: none;
            width: auto;
            height: 87px;
            border: none;
            left: 0;
            right: auto;

            &:hover {
                background-color: transparent;
                border: none;
                
                a span {
                    text-decoration: underline;
                }
            }                
        }   
        #mainNav .MenuButton a.switch {
            width: auto;
            height: 87px;
            line-height: 84px;
            border-bottom: none;
            color: #0c121c;

            span {
                font-weight: 600;
                font-size: 10px;
                letter-spacing: 1.73px;                
                margin-left: 20px;
            }
        }       
        #mainNav .MenuButton #menuButtonIcon {
            vertical-align: middle;
            display: inline-block;            
        }  
        #mainNav .SearchButton {
            display: block;
            height: 87px;
            border-bottom: none;
        }   
        #mainNav .SearchButton a.switch {
            height: 87px;
            line-height: 87px;
            border-bottom: none;
        }   
        #mainNav .close-mainSearch {
            height: 87px;
            border-bottom: none;
        }    
    }
} 

@media screen  and (min-width: 1px) and (max-width: 740px) {
    .new-menu-style {    
        #header>.wrapper {
            height: 50px;
            border-bottom: none;
        }        
        #header #logo {
            border-right: none;
            height: 49px;
            width: 91px;        
            left: 50%;
            transform: translateX(-50%);    

            a img {
                height: 42px;
                width: 59px;
                margin-left: -30px;
                margin-top: -21px;
                border-bottom: none;
            }
        }
        #header #mainNav {
            left: 14px;
        }  
        #header.stickyNavigation #mainNav ul {
            height: 50px;
        }          
        #header.stickyNavigation #mainNav ul li {
            height: 50px;
        }    
        #header.stickyNavigation.reducedStickyNavigation #mainNav ul li {
            height: 50px;
        }     
        #header.stickyNavigation #mainNav ul li a {
            padding-bottom: 0;
        }    
        #mainNav .wrapper ul li.current-menu-item a, 
        #mainNav .wrapper ul li.current-page-ancestor a {
            border-bottom: none;
        }  
        #mainNav .MenuButton {
            display: block;
            width: auto;
            height: 50px;
            border: none;
            left: 0;
            right: auto;

            &:hover {
                background-color: transparent;
                border: none;
                
                a span {
                    text-decoration: underline;
                }
            }                
        }   
        #mainNav .MenuButton a.switch {
            width: auto;
            height: 50px;
            line-height: 50px;
            border-bottom: none;
            color: #0c121c;

            span {
                font-weight: 600;
                font-size: 10px;
                letter-spacing: 1.73px;                
                margin-left: 6px;
            }
        } 
        #mainNav .SearchButton {
            display: none;
            height: 50px;
            border-bottom: none;
        }   
        #mainNav .SearchButton a.switch {
            height: 50px;
            border-bottom: none;
        }   
        #mainNav .close-mainSearch {
            height: 50px;
            border-bottom: none;
        }    
        #mainNav .MenuButton {
            border: none;
        }    
    }
}

.slideMenuTop {
    #header {
        z-index: 21;
    }
    #slideMenu {
        top: 50px;
    }    
    #slideMenu .close-slide {
        display: none;
    }     
    #slideMenu .navigation{
        //top: 100px;
        left: 0;
    }   
    #SearchResults{
        //top: 100px;
        left: 0;
    
    }   
    #fade-overlay {
        top: 50px;
    } 
}