@font-face {
  font-family: 'lrex';
  src: url('../fonts/lrex.eot?79163078');
  src: url('../fonts/lrex.eot?79163078#iefix') format('embedded-opentype'),
       url('../fonts/lrex.woff2?79163078') format('woff2'),
       url('../fonts/lrex.woff?79163078') format('woff'),
       url('../fonts/lrex.ttf?79163078') format('truetype'),
       url('../fonts/lrex.svg?79163078#lrex') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'lrex';
    src: url('../font/lrex.svg?79163078#lrex') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "lrex";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-menu:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e801'; } /* '' */
.icon-share:before { content: '\e802'; } /* '' */
.icon-blogger:before { content: '\e803'; } /* '' */
.icon-facebook:before { content: '\e804'; } /* '' */
.icon-googleplus:before { content: '\e805'; } /* '' */
.icon-lenkrad:before { content: '\e806'; } /* '' */
.icon-phone:before { content: '\e807'; } /* '' */
.icon-arrow-down:before { content: '\e808'; } /* '' */
.icon-arrow-up:before { content: '\e809'; } /* '' */
.icon-close:before { content: '\e80a'; } /* '' */
.icon-slide-left:before { content: '\e80b'; } /* '' */
.icon-slide-right:before { content: '\e80c'; } /* '' */
.icon-mail:before { content: '\e80d'; } /* '' */
.icon-info:before { content: '\e80e'; } /* '' */
.icon-arrow-left:before { content: '\e80f'; } /* '' */
.icon-minus:before { content: '\e810'; } /* '' */
.icon-instagram:before { content: '\e811'; } /* '' */
.icon-pinterest:before { content: '\e812'; } /* '' */
.icon-twitter:before { content: '\e813'; } /* '' */
.icon-youtube:before { content: '\e814'; } /* '' */
.icon-gutschein:before { content: '\e815'; } /* '' */
.icon-download:before { content: '\e816'; } /* '' */
.icon-location:before { content: '\e817'; } /* '' */
.icon-quotation-end:before { content: '\e818'; } /* '' */
.icon-quotation-start:before { content: '\e819'; } /* '' */
.icon-car:before { content: '\e81a'; } /* '' */
.icon-film:before { content: '\e81b'; } /* '' */
.icon-plus:before { content: '\e81c'; } /* '' */
.icon-question:before { content: '\e81d'; } /* '' */
.icon-arrow-right:before { content: '\e81e'; } /* '' */
.icon-tick:before { content: '\e81f'; } /* '' */
.icon-group:before { content: '\e820'; } /* '' */
.icon-highlight-begleitung:before { content: '\e821'; } /* '' */
.icon-highlight-unterbringungsstil:before { content: '\e822'; } /* '' */
.icon-scrolldown:before { content: '\e90d'; } /* '' */