/* Magnific Popup CSS */
/* ANGEPASST */



.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
 position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80); }
  .mfp-bg.textbox {
  opacity: 0.6 !important; /* because of chrome 34 bug */
  filter: alpha(opacity=50);    
  }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  z-index: 10000 !important;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* padding: 0 8px; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%; 
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
/*  width: 100%; */
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder{
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-o-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.mfp-open .mfp-figure {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -ms-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility:    hidden;
  -ms-backface-visibility:     hidden;
}

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #cccccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #cccccc; }
    .mfp-preloader a:hover {
      color: white; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close{
  font-family: Arial,Baskerville,monospace;
    font-size: 28px;
    line-height: 44px;
    text-align: center;
  }
button.mfp-close:active{
  top:1px !important;
}
.leiste > button.mfp-close{
    font-size: 28px;
    font-style: normal;
    height: 44px;
    line-height: 44px;
    opacity: 0.65;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    top: 0;
    width: 44px;
    border: none;
    color: #333;
} 
@media only screen and (min-width: 0px) and (max-width: 400px) {
  .leiste > button.mfp-close{
    color: #00173a;
  }
}
.leiste > button.mfp-close:hover{
  opacity: 1;
  color: #000;
}
button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }
button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.popup2 .mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: white;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
.popup2  .mfp-close:hover, .popup2 .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
 .popup2 .mfp-close:active {
    top: 1px; }

.popup2 .mfp-close-btn-in .mfp-close {
  color: #00173A; }

.popup2 .mfp-image-holder .mfp-close, .popup2 .mfp-iframe-holder .mfp-close {
  color: white;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }


#tourcamp-form-wrapper {
  background-color: #fff;
  color: #000;
  padding: 10px 20px 10px 20px;
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  #tourcamp-form-wrapper {
    max-width: 320px;
  }
}
#tourcamp-form-wrapper h2, #tourcamp-form-wrapper label {
  color: #000;
}

#tourcamp-form-wrapper #vorname, #tourcamp-form-wrapper #nachname, #tourcamp-form-wrapper #email {
    padding: 10px 5px;
    margin-bottom: 10px;
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  #tourcamp-form-wrapper #submittourcamp, #tourcamp-form-wrapper #vorname, #tourcamp-form-wrapper #nachname, #tourcamp-form-wrapper #email {
      max-width: 280px;
  }
  #tourcamp-form-wrapper #submittourcamp {
      padding: 12px 12px 11px;
  }
}
#tourcamp-form-wrapper #submittourcamp {
  -webkit-appearance: button;
  border-radius: 0;
}
#tourcamp-form-wrapper .leiste {
    height: 20px;
}
#tourcamp-form-wrapper .tourcamp-desc {
  max-width: 320px;
  display: block;
  margin-bottom: 20px;
}
#tourcamp-form-wrapper .mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #000;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
#tourcamp-form-wrapper  .mfp-close:hover, #tourcamp-form-wrapper .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
 #tourcamp-form-wrapper .mfp-close:active {
    top: 1px; }

#tourcamp-form-wrapper .mfp-close-btn-in .mfp-close {
  color: #00173A; }

#tourcamp-form-wrapper .mfp-image-holder .mfp-close, #tourcamp-form-wrapper .mfp-iframe-holder .mfp-close {
  color: white;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }


.mfp-content ul { 
    padding-left: 20px!important;
}
.mfp-content li { 
    list-style-type: disc;
}
.mfp-content p { 
    margin-bottom: 15px !important;
}



.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #cccccc;
  font-size: 12px;
  line-height: 18px; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after, .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before, .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
    border-right: 17px solid white;
    margin-left: 31px; }
  .mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
    border-left: 17px solid white;
    margin-left: 39px; }
  .mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3f3f3f; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 90%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: black; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }
.mfp-gallery img.mfp-img {
  padding: 140px 70px;
}
@media screen and (min-width: 1px) and (max-width: 740px)  {
.mfp-gallery img.mfp-img {
  padding: 100px 0px;
}
  
  }
/* Small caption */
.spepecialfigure .mfp-title {
  margin-top: -140px;
  padding: 0px 0px 0px 75px !important; 
  font-size: 10px !important;
}
@media screen and (min-width: 1px) and (max-width: 740px)  {
  .spepecialfigure .mfp-title {
    margin-top: -100px;
    padding: 5px 0px 0px 5px !important;
  }
  
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444444; }
  .mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }
.mfp-gallery .mfp-figure:after {
  top:0;
  bottom: 0;
  background: #333 url(../images/texture-darkest-grey.png) repeat 0 0;
}
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }
.mfp-gallery .mfp-bottom-bar{
  top:0;
  height: 70px;
  background-color: #000000;
  margin-top: 0;
}


@media screen and (min-width: 1px) and (max-width: 740px)  {
  .mfp-gallery .mfp-bottom-bar{
    height: 50px;
  }
  }
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery button.mfp-close{
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  line-height: 70px;
  color: white;
  z-index: 9999; }
  @media screen and (min-width: 1px) and (max-width: 740px)  {
    .mfp-gallery button.mfp-close{ 
      width: 50px !important;
      height: 50px !important;
      line-height: 50px !important; }
  }
.mfp-gallery .mfp-content{
  position: static;
}
.mfp-gallery .mfp-title{
  display: block;
    float: left;
    font-size: 16px;
    font-weight: 100;
    height: 70px;
    letter-spacing: 0.1em;
    line-height: 3em;
    padding: 10px 30px 10px 40px;
    text-transform: uppercase;
}
@media (max-width: 740px) {
    .mfp-gallery .mfp-title{
        padding: 17px 20px;
        font-size: 12px;
        width: 75%;
        line-height: 1.25em;
        height: 50px;
    }
}
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    /* padding-left: 6px;
    padding-right: 6px; */} }

.mfp-ie7 .mfp-img {
  padding: 0; }
.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }
.mfp-ie7 .mfp-container {
  padding: 0; }
.mfp-ie7 .mfp-content {
  padding-top: 44px; }
.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }
  
  
/* 

====== 3d unfold ======

*/
.mfp-3d-unfold {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-3d-unfold .mfp-content {
  perspective: 2000px;
}
.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
  transform: rotateY(-60deg);
}
.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  transition: all 0.5s;
}
.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: rotateY(0deg);
}
.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  transform: rotateY(60deg);
  opacity: 0;
}
.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0;
}


/* 

====== custom-popup ======

*/
.custom-popup-wrap {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #333 url(/images/texture-darkest-grey.png) repeat 0 0;
}

.custom-popup-header {
    background: #000;
    *zoom: 1;
}

.custom-popup-header:before {
    content: " ";
    display: table;
}

.custom-popup-header:after {
    content: " ";
    display: table;
    clear: both;
}

.custom-popup-header-title {
    display: block;
    float: left;
    height: 70px;
    padding: 10px 30px 10px 40px;
    font-size: 16px;
    line-height: 3em;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 0.1em;
}

@media (max-width: 740px) {
    .custom-popup-header-title {
        padding: 17px 20px;
        font-size: 12px;
        width: 75%;
        line-height: 1.25em;
        height: 50px;
    }
}

.custom-popup-wrap .custom-popup-header-close {
    display: block;
    float: right;
}

.custom-popup-wrap .custom-popup-header-close button, .mfp-gallery button.mfp-close {
    display: block;
    border: none;
    padding: 0;
    margin: 0;
    background: #000;
    font-size: 26px;
    width: 70px;
    line-height: 70px;
    color: #FFF;
    font-weight: normal;
    border-left: 1px solid #333;
    cursor: pointer;
    -webkit-transition: background-color .1s linear;
    -moz-transition: background-color .1s linear;
    transition: background-color .1s linear;
}

@media (max-width: 740px) {
   .custom-popup-wrap .custom-popup-header-close button {
        line-height: 50px;
        width: 50px;
    }
}

.custom-popup-wrap .custom-popup-header-close button:hover, .mfp-gallery button.mfp-close:hover {
    background: #4e4e4e;
    -webkit-transition: background-color .1s linear;
    -moz-transition: background-color .1s linear;
    transition: background-color .1s linear;
}

.custom-popup-content {
    max-height: 720px;
    margin: 30px 0;
}

.custom-popup-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    max-width: 1000px;
    margin: 0 auto;
}

@media (min-width: 1001px) {
    .custom-popup-container {
        padding-bottom: 563px;
    }
}

.custom-popup-container iframe,
.custom-popup-container object,
.custom-popup-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



/* ------- Link in Popup */
.link-in-popup {
  max-width: 577px;
  padding: 35px 45px;
}
@media screen and (min-width: 1px) and (max-width: 415px) {
  .link-in-popup {
    max-width: 100%;
    padding: 35px 20px;
  }
}
.link-in-popup .leiste > button.mfp-close {
  font-size: 46px;
  height: 52px;
  color: #373737;
  opacity: 1;
}
.link-in-popup .leiste > button.mfp-close:hover{
  opacity: 0.65;
  color: #333;
}
.link-in-popup h2 {
  font-family: Avenir,Arial,Helvetica,sans-serif;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.25;
  text-transform: inherit;
  margin: 10px 0 25px 0;
  color: #373737;
}
@media screen and (max-width: 740px) {
  .link-in-popup h2 {
      font-size: 20px;
  }
}
.link-in-popup p {
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.015em;
  padding-bottom: 3px;
  color: #373737;
}
.link-in-popup .button {
  margin-bottom: 40px;
}
.link-in-popup .question-block {
  margin-bottom: 30px !important;
}
.link-in-popup .phoneNumber a {
  color: #373737;
}

.link-in-popup p.legal-info {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  color: #888;
}

