/*
Theme Name: LREX
Author: enabee
Author URI: http://www.enabee.com/
Version: 1.0

*/

/* --------------------------------------------- Allgemein */

* {
    box-sizing: border-box;
     outline: 0;
}

body,html {
    overflow-x: hidden;
    font-family: 'Avenir',sans-serif;
    padding: 0;
    margin: 0;
    color:#fff;
    line-height: 1.5em;
    font-size: 14px;
    font-weight: 400;
}

/* Font Smoothing für content = ZU PRÜFEN !! */
#content {
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing: grayscale; /* zu PRÜFEN !!! */
}

body {
    overflow: hidden;
    background-color: #393939;
}

li {
    list-style-type: none;
}


a {
    text-decoration: none;
}

a:hover, a:active, a:focus {
     outline: 0;
}
a img {
    border:none;
}

sub {
}
sub, sup {
    font-size: 0.7em;
    position: relative;
}

.nobr { 
    white-space:nowrap; 
}

/*
input[type=text] {
    border: 1px solid #000;
    display:block;
    height: 40px;
    min-width: 240px;
    padding:0 10px;
}
*/

/* --------------------------------------------- Content-Style-Varianten */
/*
Varianten: 
1. white
2. light
3. grey
4. darkestgrey 
5. darkblue
*/

/* white */
.white {
    background-color: #fff;
    color:#000;
}
.white a {
    color:#000;
}

/* light */
.light {
    background-color: #e9ecec;
   // background-image: url("../images/texture-grey-mist.png");
    color:#000;
}

/* grey */
.grey, .darkgrey {
    background: #4a4f54; //url("../images/texture-dark-grey.png");
}
.grey a {
    color: white;
}

/* darkestgrey */
.darkestgrey {
    background: #3f4347; //url("../images/texture-darkest-grey.png");
}
.darkestgrey p {
    color: #cbcbcb;
}
.darkestgrey a {
    color: white;
}

.light a,
.light p,
.light h3 {
    color:#000;
}

.darkblue {
    background: #0c212e;
    color: #fff;
}




/* --------------------------------------------- Typo / Content */

p {
    max-width: 600px;
}

h2 {
    font-family: lr_ot4, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 1.1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto 0 auto; /* zu pruefen */
}

h1 a, h2 a, h3 a {
    text-transform: uppercase;
}

h2 {
    font-size: 25px;
    font-weight: 200;
    margin: 0 auto 2ex auto; /* zu pruefen */
}

@media screen and (max-width: 740px) {
    h2 {
     /*   font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0.02em;
        font-weight: 600;
        margin: 0 0 10px 0;*/
    }
}

h3 {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 10px;
    margin: 0;
}

.primaryHeading {
    font-size: 32px;
    padding: 0;
    margin: 0;
    text-align: center;
    max-width: 100%;
    font-weight: 500;
}

@media screen and (max-width: 740px) {
    .primaryHeading,
    .primaryHeading {
        font-size: 24px;
    }
} 

h2.secondaryHeading {
    font-size: 25px; /* war 32px */
    font-weight: 100;
    letter-spacing: 0.15em;
    line-height: 1.1em;
    text-align: center;
    margin: 0 auto 30px auto;

}

@media screen and (max-width: 740px) {
    h2.secondaryHeading {
        font-size: 24px;
        line-height: 1.1em;
        letter-spacing: 0.15em;
        font-weight: 100;
    }
}

h2.secondaryHeading + p {

    margin: 20px auto;
    max-width: 500px;
    text-align: center;
    display: block;
}

h3 {

    font-weight: bold;

}


/* Typo allgemein ??? */
p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5em;
    /* color: #c2c2c2; */
    letter-spacing: 0.015em;

}


.itemContent p {
    font-size: 20px;
    margin-top: 50px;
}

.clearfix {

    clear:both;

}

.center {
    display: block !important;
    float: none;
    margin: auto !important;
}



/* why? */
.Comparison {
    width:200px;
}

.total {
    background-color: #000;
    height:100%;
    top:0px;
    left:0px;
}

.separator {
    background-color: #c5c6c0;
    width:100%;
    margin: auto;
    height:1px;
}

/* --------------------------------------------- 2 Gesamtlayout --------------------------------------------- */

.pageWrapper {
    margin: 0 auto;
    max-width: 2000px;
    padding: 0;
    position: relative;
}

.new-cookie-notice {
    display: none;
    width: 100%;
    min-height: 90px;
    color: #fff;
    background: #4a4f54 !important;
    padding: 25px 50px 25px 20px;   
    margin: 0 auto;
    max-width: 2000px;
    position: relative;
}
@media (max-width: 1060px) {
    .new-cookie-notice {
       min-height: 160px;
    }
}
@media (max-width: 900px) {
    .new-cookie-notice {
       margin-top: 50px;
    }
}
.cookie-notice-text-wrap {
    float: left;
}
@media (max-width: 1060px) {
    .cookie-notice-text-wrap {
        float: none;
        margin-bottom: 15px;
    }
}
.cookie-notice-text-up {
    font-size: 20px;
    font-family: lr_ot4, Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    -moz-osx-font-smoothing: grayscale;
}
.cookie-notice-text-down {
    font-family: 'Avenir',sans-serif;
    font-size: 16px;
    font-weight: 400;  
    -moz-osx-font-smoothing: grayscale;  
}
.cookie-notice-button {
    float: right;
}
@media (max-width: 1060px) {
    .cookie-notice-button {
        float: none;
    }
}
#close-cookie-notice {
    font-size: 18px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
@media (min-width: 1060px) {
    #close-cookie-notice {
        top: 50%;
        transform: translateY(-50%);
    }
}
@media (max-width: 900px) {
    .cookie-notice-visible #page {
        padding-top: 0px !important;
    }
}
.iframe .new-cookie-notice {
    display: none !important;

}


/* --------------------------------------------- Borelabs Cookiebox */
#BorlabsCookieBox .bottom-center ._brlbs-bar-wrap._brlbs-position-fixed {
  bottom: 0;
}

#BorlabsCookieBox.options-open .bottom-center ._brlbs-bar-wrap._brlbs-position-fixed {
  top: 50%;
  transform: translate(0, -50%);  
  width: auto !important;
}
@media (max-width: 1200px) {
    #BorlabsCookieBox.options-open .bottom-center ._brlbs-bar-wrap._brlbs-position-fixed {
      top: 0;
      transform: translate(0, 0);  
      width: 100% !important;
    }
}  

@media (max-width: 1200px) {
    #BorlabsCookieBox.options-open .cookie-preference .container.not-visible {
      max-height: 100% !important;
    }
}

#BorlabsCookieBox.options-open .show-cookie-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.6);
    z-index: 999999;
}

#BorlabsCookieBox ._brlbs-bar, 
#BorlabsCookieBox ._brlbs-box {
    background: #fff;
    border-radius: 0;
    color: #444444;
}

#BorlabsCookieBox.options-open ._brlbs-bar, 
#BorlabsCookieBox.options-open ._brlbs-box {
    background: #fff;
    border-radius: 0;
    color: #0c121c;
    padding-top: 40px;
}

#BorlabsCookieBox.options-open ._brlbs-refuse {
    position: fixed;
    top: 1.3rem;
    right: 1rem; 
    color: #000; 

    a {
      font-size: 18px;
      display: block !important;
    }
}

#BorlabsCookieBox #CookieBoxTextHeadline {
    display: block;
}

#BorlabsCookieBox p {
    max-width: 100%;
}

#BorlabsCookieBox a {
    color: #4A4F54;
    &:hover {
        color: #005a2b;
    }
}

#BorlabsCookieBox #CookieBoxTextDescription a {
    text-decoration: underline;
}

#BorlabsCookieBox a.cursor.d-block {
  color: #555;
}

#BorlabsCookieBox .cookie-box ._brlbs-btn {
  border-radius: 0;
}

#BorlabsCookieBox .col-12.col-sm-9>ul,
#BorlabsCookieBox ._brlbs-bar ._brlbs-legal {
  display: none;
}

#BorlabsCookieBox .col-12.col-sm-9 {
  @media (min-width: 576px) {
      flex: 0 0 100%;
      max-width: 100%;
  }  
  @media (min-width: 992px) {
      flex: 0 0 60%;
      max-width: 60%;    
  }    
  @media (min-width: 1200px) {
      flex: 0 0 70%;
      max-width: 70%;
  }  
}
#BorlabsCookieBox .col-12.col-sm-3 {
  @media (min-width: 576px) {
      flex: 0 0 100%;
      max-width: 100%;
      flex-direction: row-reverse;
      display: flex;  
  } 
  @media (min-width: 992px) {
      flex: 0 0 40%;
      max-width: 40%;    
  }    
  @media (min-width: 1200px) {
      flex: 0 0 30%;
      max-width: 30%;    
  }  

  ._brlbs-manage {
    @media (max-width: 575px) {
      width: 100%;
      a {
        width: 100%;
      }      
    }      
    @media (min-width: 576px) {
      margin: 20px 0 0 0;
      width: 50%;
    }     
    @media (min-width: 1200px) {
      margin: 0 20px 0 0;  
      width: auto;    
    }    
  }

  ._brlbs-accept {
    @media (min-width: 576px) {
      margin: 20px 0 0 0;
      width: 50%;

      a {
        width: auto;
      }
    }    
    @media (min-width: 1200px) {
      width: auto;
      margin: 0;      
    }    
  }  
}


.GridListWrapper:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
}
.GridListWrapper {
    display: block;
    padding: 80px 90px;
    width: 100%;
}


.GridListWrapper .items {
}

.GridListWrapper .items .el img:not(.attachment-teaser-big):not(.j-poster-image) {
    height: auto;
    width: 100%;
    border:0;
    display: block;
    margin-bottom: 20px;
}

.page-template-page-reisen .GridListWrapper .items .el img:not(.attachment-teaser-big):not(.j-poster-image) {
    margin-bottom: -6px;
}

.page-template-page-fahrangebote .GridListWrapper .items .el img:not(.attachment-teaser-big):not(.j-poster-image) {
    margin-bottom: -6px;
}





.GridListWrapper .items .el .fadein {
    height: auto;
    width: 100%;
    border:0;
    display: block;
}

/* Fahrdisziplinen-Abwandlung */
.GridListWrapper.runde-bilder .el {
    text-align: center;
}
.GridListWrapper.runde-bilder .el img {
    width: 80%;
    margin: 2ex auto 4ex auto;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

@media (max-width: 740px) {
    .GridListWrapper .items .el img {
        margin-bottom: 15px;
    }
}

.GridListWrapper.bordered .items .el img {

    padding-bottom: 0px;
    float: left;
    margin-bottom: 0px;

}

.GridListWrapper .items[data-total="6"] .el {
    width: 16%;
    float: left;
}

.GridListWrapper .items[data-total="4"] .el {
    width: 25%;
   
}

.GridListWrapper .items[data-total="2"] .el {
    width: 49.9%;
   
}

.GridListWrapper .items[data-total="1"] .el {
    width: 90%;
    float: none;
    display: block;
    margin: auto
}

.GridListWrapper .simple-content .el {
    width: 50% !important;
    float: none;
    display: block;
    margin: auto;
}

@media (max-width: 740px) {
    .GridListWrapper .simple-content .el {
        width: auto !important;
    }
}


.GridListWrapper .items .el {

    position: relative;
    display: inline-block;
    float: left;
    vertical-align: top;
    width: 33.3%;
   
    overflow: hidden;
    
}

.GridListWrapper.padding .items .el {

    padding: 0 20px 30px 20px; /* padding unten war 40px */
    /* fuer events */

}

@media (max-width: 740px) {
    .GridListWrapper.padding .items .el {
        padding: 15px 0;
    }
}

.GridListWrapper .subHeading {
    max-width:600px;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.GridListWrapper .subHeadingSpecial {
    display: block;
    margin: 0 auto 40px auto; 
    text-align: center; 
    width: 100%; 
    max-width: 600px;
}

.GridListWrapper .headings .button-wrap {
    text-align: center;
    margin-bottom: 20px;
}

.GridListWrapper.light h3 a {
    text-transform: none;
}



.GridListWrapper.light.bordered h3,
.GridListWrapper.light.bordered h3 a {
    text-transform: uppercase;
}

.GridListWrapper.white p {
    color: #000;
    font-size: 14px;
    line-height: 1.5em;
}

.GridListWrapper .heading {
    max-width: 860px;
}

.twocol ul, .onecol ul {
padding-left: 1.2em;
}

.twocol li, .onecol li {
    list-style-type: inherit;
    /* list-style-type: cjk-ideographic; */
}



@media screen and (min-width: 1px) and (max-width: 1024px) {
    .GridListWrapper {
        padding: 40px;
    }
    .GridListWrapper .items[data-total="2"] .el,.GridListWrapper .items[data-total="4"] .el {
        width: 49.9% !important;
      
    }

    .GridListWrapper .items[data-total="3"] .el {

        width: 49.9% !important;

    }

    .GridListWrapper .items[data-total="6"] .el {
        width: 25% !important;
    }

}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .GridListWrapper {
        padding: 30px 15px;
    }
    .GridListWrapper.Program {
        padding: 30px;
    }    
     .GridListWrapper .items[data-total="2"] .el,.GridListWrapper .items[data-total="3"] .el,.GridListWrapper .items[data-total="4"] .el {
        width: 100%!important;
      
    }
}

.GridListWrapper.FullWrapper .heading {
    text-align: center;
}
.GridListWrapper.FullWrapper p {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    text-align: center;
}
.GridListWrapper.FullWrapper a {
    text-align: center;
    margin: 0 auto;
    display: table;
}

.GridListWrapper .freetext p {
    display: block;
    max-width: 800px;
    margin: 0 auto 10px auto;
}

/* Layout fuer Angebote + Reisen */

.bordered .box {
    position: relative;
    width:100%;
    border-right: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    float: left;
    padding: 30px 20px;
    box-sizing: border-box;
}

.box h3 {
    text-align: center;
    font-size: 18px;
}

.box h3 + p {

    padding-bottom: 20px;
    text-align: center;

}

.box a {
     text-align: center;
     margin: 20px auto 0 auto !important;
}

.box td {
    padding:5px 0;
}

.box .legal-info {
    padding: 20px 0;
    text-align: center;
    color: #666;
}

//.bordered .items > div:nth-child(3n+1) {
//    border-left: 1px solid #bfbfbf;
//}
//
//@media screen and (min-width: 1px) and (max-width: 1024px) {
//    .bordered .items > div:nth-child(3n+1) {
//        border-left:none;
//    }
//    
//    .bordered .items > div:nth-child(2n+1) {
//        border-left: 1px solid #bfbfbf;
//    }
//}
//@media screen and (min-width: 1px) and (max-width: 740px) {
//    .bordered .items > div:nth-child(2n+1) {
//         border-left:none;
//    }
//    .bordered .items > div:nth-child(n+1) {
//        border-left: 1px solid #bfbfbf;
//    }
//}

.block div.left.onecol,.block div.right.onecol {
   
    border: medium none;
    float: left;
    padding: 40px 40px 40px 50px;
    width: 33.333333%;
    height:100%;
}

@media (max-width: 740px) {
    .block div.left.onecol, .block div.right.onecol {
        padding: 40px 30px 40px !important;
    }
}

.block div.left.onecol > div ,.block div.right.onecol > div {
    
     display: table;
     height:100%;
     width:100%;
    
}

.block div.left.onecol > div > div,.block div.right.onecol > div > div{

    display: table-cell;
    vertical-align: middle;
    width:100%;
    
}

.block.secondary-teaser div.left.onecol > div, .block.secondary-teaser div.right.onecol > div {
    vertical-align: inherit;
}

.block div.left.onecol h2,.block div.right.onecol h2, .block div.left p, .block div.right p {
    margin-bottom: 20px;
}

@media screen and (min-width: 741px) {
    .block div.left.onecol h2,.block div.right.onecol h2 {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

@media screen and (max-width: 740px) {
    .block div.left.onecol h2,.block div.right.onecol h2 {
       /* font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0.02em;
        font-weight: 600;
        font-family: Avenir,Arial,Helvetica,sans-serif;*/
    }
}

#momente-teaser {
       
        background-size: 100%;
        background-position:center  80% ;
}

@media screen and (max-width: 1280px) {
    #momente-teaser {
        background-size: cover;
        background-position:center ;

    }
}


@media screen and (max-width: 900px) {
    #momente-teaser {
        background-size: 70% auto;
        background-position:center  80% ;

    }
}

@media screen and (max-width: 768px) {
    #momente-teaser {
        background-size: cover;
        background-position:center  80% ;

    }
}

.block div.left.onecol p,
.block div.right.onecol p {
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0.02em;
    color: #222;
}

.block.grey div.left.onecol p,
.block.grey div.right.onecol p,
.block.darkestgrey div.left.onecol p,
.block.darkestgrey div.right.onecol p {
    color: #fff;
}

.block.white div.left.onecol p,
.block.white div.right.onecol p {
    color: #363636;
}

.block .onecol p a {
    text-decoration: underline;
}
.block .onecol p a:hover {
    color: #e05206;
}

.block div.left.twocol {
    border: medium none;
    float: left;
    width: 66.6%;
    height:100%;
}

.block div.right.twocol {
    float: right;
    text-align: center;
    width: 66.66%;
    height:100%;
}

/* neu */
.block div.full.twocol {
    float: left;
    text-align: center;
    width: 100%;
    height:100%;
}

 .block div.right.onecol {
    float: right;
    padding: 40px 50px 40px 40px;
    width: 33.333333%;
    height:100%;
}

@media screen and (min-width: 901px) and (max-width: 1085px) {
    .block div.left.onecol h2, .block div.right.onecol h2 {
        font-size: 23px;
    }
    .block div.left.onecol, .block div.right.onecol {
        padding: 20px !important;
    }
}

.block .image {

    height:100%;
    background-size: cover;

}

.block .fullHeightImage-wrap {
    padding: 40px;
    background-size: auto;
}
.block .fullHeightImage {
    display: block;
    height:100%;
    max-height:100%;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);    
}

.block {

    height: 800px;

}
.page-template-page-formular-tourblogger .block {
    height: 600px;
}

@media screen and (min-width: 1px) and (max-width: 1600px) {

    .block {

        height: 600px;

     }
}
@media screen and (min-width: 1px) and (max-width: 1310px) {
    .page-template-page-tour-above-and-beyond .block {

        height: 700px;

     }
}
@media screen and (min-width: 1px) and (max-width: 1490px) {
    .page-template-page-formular-tourblogger .block {

        height: 780px;

     }
}
@media screen and (min-width: 1px) and (max-width: 1440px) {
    .page-template-page-tour-2017-peru-map .block {

        height: 780px;

     }
}
@media screen and (min-width: 1px) and (max-width: 1160px) {
    .page-template-page-tour-2017-peru .block {

        height: 768px;

     }
}
@media screen and (min-width: 1px) and (max-width: 925px) {
    .page-template-page-tour-2017-peru-map .block {

        height: 800px;

     }
}
@media screen and (min-width: 1px) and (max-width: 900px) {
    .page-template-page-tour-2017-peru .block,
    .page-template-page-tour-2017-peru-map .block,
    .page-template-page-formular-tourblogger .block,
    .page-template-page-tour-above-and-beyond .block {

        height: auto;

     }
}

@media screen and (min-width: 1px) and (max-width: 900px) {

    div.right, div.left {
        width: 100% !important;


    }

    .onecol {

        height:auto !important;

    }

    .block {

        height:auto;

     }

     .block .image {

        height:500px;

     }

}

@media screen and (min-width: 1px) and (max-width: 740px) {
    .block .image {
        height:250px;
     }
}

@media screen and (min-width: 1px) and (max-width: 320px) {
    .block .image {
        height:150px;
     }
}


.block:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
}

.block.videoblock {
    overflow: hidden;
}
.block .video-wrap {
    position: relative;

    .background-video {
        position: absolute;
        overflow: hidden;
        top:50%;
        left:50%;
        height:auto;
        width:auto;
        min-width:100%;
        min-height:100%;
        transform:translate(-50%, -50%);   

        @media screen and (max-width: 900px) {
            display: none;
        }       
    }
    .backgroundImage {

        @media screen and (min-width: 901px) {
            display: none !important;
        }           
    }

    .video-content-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;  
        left: 0;   

        @media screen and (max-width: 900px) {
            position: relative;
        } 

        .video-content {
            @media screen and (min-width: 901px) {
                position: absolute;
                top:50%;
                left:50%;
                transform:translate(-50%, -50%);
                max-width: 840px;  
            }               
            @media screen and (max-width: 900px) {
                position: relative;
                max-width: 100%;
                padding: 40px 30px;
            } 
                          
        }  
    } 
}

/* --------------------------------------------- 3 Element spezifische Styles --------------------------------------------- */


/* --------------------------- Startseite: Secondary Teaser Alt -- */

/* Layout */
.block.secondary-teaser {
  height:inherit;
}
.block.secondary-teaser .onecol {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height:300px;
  padding-top: 40px !important;
/*  -moz-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;*/
}
.secondary-teaser a:hover .onecol {
/* zoom effekt ? */
/*opacity: .6;*/
 }

.block.secondary-teaser .right.onecol {
    background-position:center top;    
}

.block.secondary-teaser > div > div {
    text-align: center;    
}
.block.secondary-teaser p {   
    display: block;
    margin: auto;
    max-width: 300px;
}
/* Content stylen */
.block.secondary-teaser .onecol h3, .block.secondary-teaser .onecol p {
  color: #FFFFFF !important;
  text-align: center;
}
.block.secondary-teaser .onecol.black h3, .block.secondary-teaser .onecol.black p {
  color: #000000 !important;
}
/* Typo */
.block.secondary-teaser .onecol h3 {
    text-transform: uppercase;
    margin-bottom: 10px;
    padding: 0;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1px;
}

/* --------------------------- Startseite: Secondary Teaser Neu -- */

/* Layout */
.block.secondary-teaser {
  height:inherit;
}
.block.secondary-teaser .secondary-teaser-wrap {
    border: none;
    width: 33.333333%;
    height: 100%;
    min-height: 300px;
    float: left;
    overflow: hidden;
    position: relative;
    background-color: #000;
}
@media screen and (min-width: 1px) and (max-width: 900px) {
    .block.secondary-teaser .secondary-teaser-wrap {
        width: 100% !important;
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .block.secondary-teaser .secondary-teaser-wrap {
        min-height: 370px;
    }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
    .block.secondary-teaser .secondary-teaser-wrap {
        min-height: 350px;
    }
}
.block.secondary-teaser .secondary-teaser-img {
    border: none;
    position: absolute;
    height: 100%;
    width: 100%;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    -moz-transform: scale(1,1);
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    
}
.block.secondary-teaser a:hover .secondary-teaser-img {
    -moz-transform: scale(1.2,1.2);
    -webkit-transform: scale(1.2,1.2);
    transform: scale(1.2,1.2);
    opacity: .9;
 }
 .block.secondary-teaser a:hover .secondary-teaser-img.scale-less {
    -moz-transform: scale(1.05,1.05);
    -webkit-transform: scale(1.05,1.05);
    transform: scale(1.05,1.05);
    opacity: .9;
 }


.block.secondary-teaser .secondary-teaser-text {
    position: relative;
    padding: 40px;
    z-index: 5;
}







/* Content stylen */
.block.secondary-teaser .secondary-teaser-text h3, 
.block.secondary-teaser .secondary-teaser-text p {
    color: #FFFFFF !important;
    text-align: center;
}
.block.secondary-teaser .secondary-teaser-text.black h3, 
.block.secondary-teaser .secondary-teaser-text.black p {
    color: #000000 !important;
}

/* Typo */
.block.secondary-teaser .secondary-teaser-text h3 {
    text-transform: uppercase;
    margin-bottom: 10px;
    padding: 0;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1px;
}
.block.secondary-teaser .secondary-teaser-text p {   
    display: block;
    margin: auto;
    max-width: 300px;
}

/* --------------------------- Startseite: Icon-Infos -- */

.StartPageCategories .items[data-total="2"] .el {
    padding: 0 60px 0 70px;
    position: relative;
}

.StartPageCategories .el a span {
    color: #cbcbce;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 5px;
    margin-left: -5px;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .StartPageCategories .el a span , .StartPageCategories .el h3, .StartPageCategories .el p, .StartPageCategories .el .secondaryLinkWithStyle {
        position: relative;
        text-align: center;
    }
    .StartPageCategories .el a span {
        padding-bottom: 10px;
    }
    .StartPageCategories .el .secondaryLinkWithStyle {
        display: block !important;
        padding: 0px !important;
        margin: 10px auto 30px !important;
    }
    .secondaryLinkWithStyle:before {
        left: -2px !important;
        position: relative !important;
        top: 0px !important;
    }
    .StartPageCategories .items[data-total="2"] .el {
    padding: 0px;
}
}

.StartPageCategories .el a:hover span:before {
    background-color: #3e3e3e;
    transition: background-color 300ms linear 0s;
}
.StartPageCategories .el a span:before {
    -moz-osx-font-smoothing: grayscale;
    background-color: #222;
    display: inline-block;
    font-size: 23px;
    font-weight: normal;
    height: 50px;
    line-height: 50px;
    margin-right: 8px;
    text-align: center;
    transition: background-color 300ms linear 0s;
    width: 50px;
}

/* Typo */
.StartPageCategories h3 a {
    margin-bottom: 10px;
    padding: 0;
    text-transform: uppercase !important;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1px;
}

/* --------------------------- Startseite: Icon-Infos -- */

.facts {
    margin: 20px 0 14px;
    padding: 0;
}

.facts li {
    position: relative;
    padding: 0 0 12px 35px;
    min-height: 40px;
}

.facts li .icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
}

.facts-card-wrap {
    max-width: 400px;
}

.carouselWrapper ul li {
    display: block;
    float: left;
    width: 33.3333%;
    padding:0 40px;
}

.carouselWrapper ul li img {

    width: 100%;

}

.cars {
    text-align: center;
}

.cars .headings {
    padding-bottom: 60px;
}

.cars .number {
    position: absolute;
    left:50px;
    /* top:-25px; ist nicht sichtbar wegen el=overflow hidden */
    font-size: 25px;
}

.pros, .specs, .prosText {
    padding: 0;
}

.pros li {
    position: relative;
    min-height: 25px;
    display: block;
    padding: 0 0 8px 22px;
}

.pros li .icon {
    position: absolute;
    top: 0px;
    left: 0;
}

#rtd-wrap .text p {
    color: #393939;
}

.prosText {
    margin-bottom: 20px;
}
.prosTextTeaser li {
    padding: 0 0 8px 0px;
}



/* ------------------------ Query Box */

.QueryBox {
    padding: 0 0 0 40px;
    vertical-align: middle;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .QueryBox {
        padding: 0 0 0 0;
    }
}





.QueryBox p {
    font-size: 14px !important;
}
.QueryBox h5 {
    clear: both;
    margin: 0 0 0 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.4;
}

.freitext-feld {
    margin-bottom: 20px;
    max-width: 400px;
}
.freitext-feld h5 {
    margin-bottom: 15px;
}
.freitext-feld p {
    margin-bottom: 15px;
}
.freitext-feld em {
    font-size: 11px;
    line-height: 15px;
    max-width: 300px;
    color: gray;
    display: block;
    clear: both;
}

.termine-wrap {
    margin-bottom: 20px;
}

.termine-wrap-remove {
    margin-top: -20px;
}

.preise-wrap {
    margin-bottom: 20px;
}
.preis-spalte1 {
    float: left;
}
.preis-spalte2 {
    padding-left: 20px;
    float: left;
}

.preis-zusatzinfo {
    font-size: 11px;
    line-height: 15px;
    max-width: 300px;
    color: gray;
    display: block;
    clear: both;
}
.page-id-2547 .preis-zusatzinfo {
    max-width: 350px;
}
.preis-zusatzinfo a {
    color: gray;
    text-decoration: underline;
}
.preis-zusatzinfo a:hover {
    color: #000;
}

.reiseleistungen-zusatzinfo {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4;
    max-width: 300px;
    color: #000;
    display: block;
    clear: both;
}
.reiseleistungen-zusatzinfo a {
    color: #000;
    text-decoration: underline;
}
.reiseleistungen-zusatzinfo a:hover {
    color: gray;
}

#reiseleistungen-popup ul {
    margin: 0;
    padding: 0 20px;
}
#reiseleistungen-popup li {
    list-style-type: disc;
}

.kontakt-aufforderung {
    font-weight: 600;
    margin-top: 20px;
    max-width: 400px;
}

.ansprechpartner {
    margin-top: 20px;
}

/* Formatierung Telefonblock - allgemein */
.phone-block {
    font-size: 14px !important;
}
.QueryBox .phone-block {
    margin-top: 20px;
}
.phoneNumber a {
    text-decoration: none !important;
}
.phoneNumber {
    font-weight: bold;
    display: block; 
    font-size: 20px;
}
.QueryBox .phoneNumber {
    margin-top: 10px;
}

.phoneNumber .icon-phone {
    font-size: 20px;
    width: 20px;
}
.phoneTime {
    font-size: 11px;
    color: gray;
    padding-left: 28px;
    display: block;
}


/* Dropdown */
.ui-icon {
   background-image: url('../images/dropdown.png');
   height:16px;
   width:14px;
}

.QueryBox .button,.QueryBox .button {
    margin-top: 0;
}

.ui-selectmenu-button {
    background-color: #fff;
    vertical-align: middle;
}

.selectpicker {
    margin: 0 10px;
    vertical-align: middle;
    width:55px;
}


/* Weitere Hinweise */
.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
  color: #333;
}

/* Karte und Untergründe */
.GridListWrapper.padding.karte-und-untergruende .items .el {
    padding: 0 20px 0 20px;
}

@media (max-width: 740px) {
    .GridListWrapper.padding.karte-und-untergruende .items .el {
        padding: 15px 0;
    }
}

.karten-image-wrap {
    //max-width: 450px;
    margin: 0 auto;
}
.icon-bereich {
    font-family: Avenir,sans-serif !important;
    padding: 0 0 0 40px;
    vertical-align: middle;
    border-left: 1px solid #3d535d;
}

@media (max-width: 740px) {
    .icon-bereich {
        padding: 0;
        border-left: none;
    }    
}
.abenteuercharakter-wrap {
    margin-bottom: 40px;
}
.abenteuer-icon {
    display: inline-block !important;
    width: 25px !important;
    margin-right: 5px;
    margin-bottom: 0px !important;  
    opacity: 0.2;  
}

.abenteuer-icon.full { 
    opacity: 1;  
}
.untergruende-wrap ul {
    padding-left: 0; 
    margin-top: 0; 
    margin-bottom: 0; 
}
.untergruende-wrap li {
    position: relative;
    padding-left: 60px;
    line-height: 50px;    
}
.untergruende-wrap .untergrund-icon {
    position: absolute;
    top: 0;
    left: -7px;
    width: 60px !important;
    margin-bottom: 0px !important;
}

/* ------- Timeline (Fahrangebote und Reisen) */

.Timeline {
    position: relative;
    padding-left: 30px;
    padding-top: 31px;
    margin-left: 50%;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .Timeline {
        padding-left: 0;
        padding-top: 0;
        margin-left: 0;
    }
}

.single-image-timeline .Timeline {
    margin-left: 0;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .GridListWrapper .items .el.single-image-timeline {
        overflow: initial;
    }
}

.Timeline li {
    position: relative;
    margin-bottom: 50px;
    list-style-type: none;
    z-index: 2;
}

.Timeline time {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 3px;
}

.Timeline .Timestop {
    padding-left: 63px;
    max-width: 448px;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .Timeline .Timestop {
        padding-left: 10px;
        max-width: 100%;
        margin-bottom: 53px;
    }
}
@media screen and (min-width: 1px) and (max-width: 400px) {
    .Timeline .Timestop {
        padding-left: 3px;
    }
}
.Timeline .Timestop.with-slider {
    min-height: 370px;
}
@media screen and (min-width: 1px) and (max-width: 980px) {
    .Timeline .Timestop.with-slider {
        min-height: 370px;
    }
}



.Timeline .Timestop:before {
    content: '';
    height: 20px;
    width: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    position: absolute;
    left: -21px;
    top: 3px;
}
.white .Timestop:before {
    background-color: #fff;
} 
.light .Timestop:before {
    //background-image: url("../images/texture-grey-mist.png");
    background-color: #e9ecec;
}
.grey .Timestop:before,
.darkgrey .Timestop:before {
    //background-image: url("../images/texture-dark-grey.png");
    background-color: #4a4f54;
}
.darkestgrey .Timestop:before {
    //background-image: url("../images/texture-darkest-grey.png");
    background-color: #3f4347;
}
.darkblue .Timestop:before {
    background-color: #0c212e;
} 

.Timeline .Timestop:after {
    content: '';
    background-color: #6dd14d;
    height: 10px;
    width: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: -16px;
    top: 8px;
}

.Timeline .line {
    display: block;
    background-color: #ccc;
    position: absolute;
    top: 31px;
    left: 28px;
    width:2px;
    height:100%;
    list-style-type: none;
    z-index: 1;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .Timeline .line {
        top: 0;
        left: 18px;
    }
}

.Timeline .head-stripe {
    width: 38px;
    height: 2px;
    background-color: #ccc;
    position: absolute;
    top: 12px;
    left: 3px;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .Timeline .head-stripe {
        display: none;
    }
}

.Timeline .Timestop ul {
    padding: 0;
}
.Timeline .Timestop ul li {
    margin-bottom: 0;
}
.Timeline .Timestop ul li ul {
    padding-left: 20px;
}
.Timeline-Slider-Wrapper {
    position: absolute;
    top: 11px;
    left: -430px;
    width: 365px;
    height: 340px;
}
@media screen and (min-width: 1px) and (max-width: 980px) {
    .Timeline-Slider-Wrapper {  
        left: -400px;
        width: 335px;
        height: 350px;      
    }
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .Timeline-Slider-Wrapper { 
        position: relative;
        max-width: 100%; 
        top: 15px;
        left: auto;     
    }
}
@media screen and (min-width: 1px) and (max-width: 320px) {
    .Timeline-Slider-Wrapper {
        width: 280px;
        height: 293px;      
    }
}


.Timeline-Single-Image-Wrap {
    margin: 0 auto;      
}

.Timeline-Single-Image-Wrap img {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.Timeline .Single-Slider {
    height: 100%;
    box-shadow: 0 0 6px 2px rgba(0,0,0,.1);
    padding: 12px 12px 70px 12px;
    background-color: #fff;
}

@media screen and (min-width: 1px) and (max-width: 740px) {
    .Timeline .Single-Slider {
        padding: 10px 10px 64px 10px;
    }
}
.Timeline .Single-Slider .image {
    height: 100%;
    background-size: cover;
    background-position: center center;
}
.Timeline .pager {
    position: absolute;
    left: 12px;
    bottom: 25px;
    color: #000;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .Timeline .pager {
        left: 50%;
        transform: translateX(-50%);
        bottom: 25px;
    }
}
.Timeline .pager .slick-prev:before, 
.Timeline .pager .slick-next:before {
    color: #000;
}

@media screen and (min-width: 1px) and (max-width: 400px) {
    .Program { 
        padding: 50px 10px 50px 25px;
    }
}


.fulltext p {
    margin: 0 auto 20px auto;
}


.page-template-page-formular-bar a {
    text-decoration: underline;
}
.page-template-page-formular-bar .kopf-content {
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.9) !important;
}

.brochure-block {
    /* border-top: 1px solid #e4e8e8; */
	background: #ececec;
    color: #000;
}
.brochure-logo {
    width: 300px;
    margin: 0 auto 20px;
    display: block;
}
@media screen and (min-width: 1px) and (max-width: 768px) {
    .brochure-logo {
        width: 200px;
    }
}

/* --------------------------- Buch-Seite -- */
.buch-sectionWrapper {
    position: relative;
}
#buch-grafik {
    position: absolute;
    top: -140px;
    left: 0;
}
#buch-grafik img {
    width: 190px;
}
@media screen and (max-width: 740px) {
    #buch-grafik {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
    }
    #buch-grafik img {
        width: auto;
        max-width: 100%;
        margin-bottom: 20px;
    }
}

/* ------------------------- FAQ */
.accordion:not(.pseudo-accordion) {
    border-top: 1px solid #e1e1e1;
}

.accordion:not(.pseudo-accordion) > div {
    border-bottom: 1px solid #e1e1e1;
}

.accordion h3 {
    font-weight: normal;
    border-bottom: 1px solid #e1e1e1;
    min-height:50px;
    vertical-align: middle;
    padding-top:14px;
    padding-bottom: 10px;
    cursor: pointer;
}

.accordion h3 .icon {
    margin-right: 5px;
}

.accordion div:not(.pseudo-accordion-trigger) {
    display: none;
    padding: 10px 0;
}

.accordion p {
    margin-bottom: 15px;
}
.accordion a {
    text-decoration: underline;
}
.accordion a:hover {
    color: #666;
}
.pager {
    position: relative;
    width:140px;
    margin: auto;
}

.accordion .pseudo-accordion-trigger {
    font-weight: normal;
    min-height: 30px;
    vertical-align: middle;
    padding-top:14px;
    padding-bottom: 10px;
    cursor: pointer;
}

.accordion .pseudo-accordion-trigger .icon {
    margin-right: 5px;
}


.newsletter input , .newsletter .primaryLinkWithStyle {
    min-width: 300px;
}

.more {
    width:200px;
    margin-top: 20px;
}

.price {
    font-weight: bold;
}


.Picker {
    height:60px;
}

.Picker li {
    
    float: left;
    
}

.ContactForm ul {
    
    padding: 0;
    
    
}

.ContactForm .el:first-child {
    padding: 0 100px 0 130px !important;
}

.ContactData label {
    
   display: block; 
   padding: 10px 0 5px 0;
    
}

.ContactData input {
    
    width:400px;
    
}


.ContactFormTour .items[data-total="2"] .el {
    padding: 0 40px;
}
@media screen and (min-width: 1px) and (max-width: 1024px) {
    .ContactFormTour .items[data-total="2"] .el {
        padding: 0 30px;
    }
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    .ContactFormTour .items[data-total="2"] .el {
        padding: 0px;
    }
}

.ContactFormTour .input-wrap {
    clear: both;
    float: left;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}

.ContactFormTour label {
    clear: both;
    display: block;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
}

.ContactFormTour input[type="text"], .ContactFormTour input[type="radio"], .ContactFormTour textarea {
    font-family: Avenir,Arial,Helvetica,sans-serif;
    display: block;
    background: #EAEAEA;
    border: none !important;
    padding: 12px 10px;
    font-size: 16px;
    line-height: 16px;
    color: #173F5F;

    border-radius: 0; /* fuer iPhone */

    width: 400px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */   
}
@media screen and (min-width: 1px) and (max-width: 1000px) {
    .ContactFormTour input[type="text"], .ContactFormTour input[type="radio"], .ContactFormTour textarea {
        width: 100%;
    }
}

.ContactFormTour input[type="submit"] {
    width: initial;
    -webkit-appearance: none; /* fuer iOS */
    border-radius: 0; /* fuer iPhone */
    margin: 10px 0;
}

::-webkit-input-placeholder { color: #000; }
:-moz-placeholder { color: #000; /* Firefox 18- */ }
::-moz-placeholder { color: #000; /* Firefox 19+ */ }
:-ms-input-placeholder { color: #000; }


/* ------------------------ Checkbox */

.ContactFormTour .checkbox-wrap {
    clear: none;
    float: left;
    margin-right: 25px;
}
.ContactFormTour label.checkbox-wrap, .ContactFormTour .checkbox-wrap input {
    width: auto !important;
    padding: 0 !important;
    background: none !important;    
}
.ContactFormTour .checkbox-wrap span {
    padding: 8px 0 0 8px;
    float: left;
}

/* langes label bei checkboxes */
.ContactFormTour .checkbox-wrap.longtext {
    margin-top: 4px;
    margin-right: 0;
}
.ContactFormTour .checkbox-wrap.longtext .ez-checkbox {
    position: absolute;
    top: 4px;
    left: 0;
}
.ContactFormTour .checkbox-wrap.longtext span {
    padding: 6px 0 0 38px;
    float: left;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 4px;
    min-height: 20px;
}

.ContactFormTour .ez-hide { opacity: 0; filter:alpha(opacity=0); font-size: 100px; cursor: pointer;}
.ContactFormTour .ez-checkbox, .ContactFormTour .ez-radio {
    zoom: 1; display:inline-block; width: 30px; height:30px;
    cursor: pointer;
    float: left;
}
.ContactFormTour .ez-checkbox {
    background: #EAEAEA url('../images/input_checkbox.png') 0 0 no-repeat;
}
.ContactFormTour .ez-radio {
    background: #EAEAEA url('../images/input_checkbox.png') 0 0 no-repeat;
}

.ContactFormTour .ez-checked { background-position: 0 -30px !important; }
.ContactFormTour .ez-selected { background-position: 0 -30px !important; }



/* ------------------------ Fehlermeldung */

.ContactFormTour .input-wrap.fehler input, .ContactFormTour .input-wrap.fehler .ez-radio, .ContactFormTour .input-wrap.fehler .ez-checkbox, .ContactFormTour .input-wrap.fehler .dk_toggle {
    border: 1px solid #E05206 !important;
}
.ContactFormTour label.error {
    color: #E05206;
    font-size: 12px !important;
    padding-top: 0;
    text-transform: none;
    letter-spacing: 0;
    padding-bottom: 0;
}
#form-bewerbung-tourblogger.ContactFormTour label {
    text-transform: none;
}

/* Platzmangel-Loesung fuer das KMI-Formular der Startseite */
@media only screen and (min-width : 1000px) {
#form-nexttour label.error {
    position: relative;
    top: 10px;
    left: -20px;
    height: 14px;
    padding-left: 20px;
    background: url(../images/error.png);
    background-repeat: no-repeat;
    border-radius: 10px;
    z-index: 100;
    /*text-indent: -10000px;*/
}
#form-nexttour .mit-checkbox label.error {
    top: 9px;
}

    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    #form-nexttour label.error {
        background: url(../images/error-2x.png);
        background-size: 14px 14px;
        background-repeat: no-repeat;
    }
    }

}

/* ------------------------ Fehlermeldung */

#form-tourcamp .input-wrap.fehler input, #form-tourcamp .input-wrap.fehler .ez-radio, #form-tourcamp .input-wrap.fehler .ez-checkbox, #form-tourcamp .input-wrap.fehler .dk_toggle {
    border: 1px solid #E05206 !important;
}
#form-tourcamp label.error {
    color: #E05206;
    font-size: 12px !important;
    padding-top: 0;
    text-transform: none;
    letter-spacing: 0;
    padding-bottom: 0;
}
@media only screen and (min-width : 1000px) {
#form-tourcamp label.error {
    position: relative;
    top: 5px;
    left: 0px;
    height: 14px;
    padding-left: 20px;
    background: url(../images/error.png);
    background-repeat: no-repeat;
    border-radius: 10px;
    z-index: 100;
    margin-bottom: 15px;
}
#form-tourcamp .mit-checkbox label.error {
    top: 9px;
}

    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    #form-tourcamp label.error {
        background: url(../images/error-2x.png);
        background-size: 14px 14px;
        background-repeat: no-repeat;
    }
    }

}

/* ------------------------ Erfolgsmeldung */
#tourcamp-success.success {
    display: none; /* fuer ajax form */
    color: #00693C;
    font-size: 26px;
    margin-top: 20px;
    height: 60px;
    line-height: 30px;
}

/* ------------------------ Erfolgsmeldung */
.ContactFormTour .success {
    display: none; /* fuer ajax form */
    color: #00693C;
    font-size: 26px;
    margin-top: 20px;
    min-height: 60px;
    line-height: 30px;
}



/* ------------------------ Abwandlung nexttour-Formular */
@media screen and (min-width: 1px) and (max-width: 740px) {
    #form-nexttour {
        margin-top: 30px;
    }
}
#form-nexttour label {
    font-size: 16px;
    line-height: 16px;
    text-transform: none;
    letter-spacing: 0;
}
#form-nexttour label.overlabel-apply {
    position:absolute;
    top:12px;
    left:10px;
    z-index:1;
}

/* ------------------------ Abwandlung tourcamp-Formular */
.button.tourcampbutton {
    text-align: center;
    margin: 0 auto;
    display: table;
}
.onecol .button.tourcampbutton {
    margin: 0px;
    display: block;
}
span.tourcampbutton {
    text-decoration: underline;
    cursor: pointer;
}
@media screen and (min-width: 1px) and (max-width: 740px) {
    #form-tourcamp {
        margin-top: 30px;
    }
}
#form-tourcamp label {
    font-size: 16px;
    line-height: 16px;
    text-transform: none;
    letter-spacing: 0;
}
#form-tourcamp label.overlabel-apply {
    position:absolute;
    top:12px;
    left:10px;
    z-index:1;
}

/* ------------------------ Abwandlung book-Formular */
//.page-template-page-buch-formular .kopfbereich.mitbild.subheight .kopf-content {
//    max-width: 1000px;
//}
.page-template-page-buch-formular #buch-grafik {
    position: relative;
    top: 0;
    left: 0;
}
//.page-template-page-buch-formular .buch-sectionWrapper {
//    padding: 80px 0 0 0;
//}
//.ContactFormBook .form-wrap {
//    max-width: 725px;
//    margin: 0 auto;
//}
//.ContactFormBook .pre-form {
//    text-align: center;
//}
//.ContactFormBook .pre-form p {
//    margin: 0 auto 20px auto;
//    width: 100%;
//    max-width: 100%;
//}
.ContactFormBook .input-wrap {
    width: 100%;
}
.ContactFormBook label {
    padding-top: 0;
}
.page-template-page-buch-formular .ui-selectmenu-button {
    border: 1px solid #fff;
}
.page-template-page-buch-formular .ui-selectmenu-menu .ui-menu {
    border: 1px solid #ccc;
    padding-bottom: 0;
}
.page-template-page-buch-formular .ui-menu .ui-state-focus, 
.page-template-page-buch-formular .ui-menu .ui-state-active {
    margin: 0;
    background: #218cfd;
}
.ContactFormBook input[type="text"], 
.ContactFormBook input[type="radio"], 
.ContactFormBook textarea {
    width: 100%;
    background: #fff;
}
.ContactFormTour .ez-radio {
    background: #fff url(../images/input_checkbox.png) 0 0 no-repeat;
}
.ContactFormTour p {
    margin-bottom: 20px;
}
.ContactFormTour .input-wrap-submit .button {
    margin-bottom: 30px;
}
.ContactFormBook .success {
    margin-top: 0;
}


/* longtext abgewandelt fuer startseite */
#form-nexttour .longtext {
    font-size: 11px;
    line-height: 15px;
    margin-top: 0;
}
#form-nexttour .longtext span {
    padding-top: 0 !important;
    margin-bottom: 0;
    min-height: 32px;
}
#form-nexttour .longtext a {
    text-transform: none;
    text-decoration: underline;
}
#form-nexttour .checkbox-wrap.longtext .ez-checkbox {
    top: 0;
}

@media only screen and (min-width : 0) and (max-width : 630px) {
#nexttour-datenschutz {
    padding: 10px;
}
#nexttour-datenschutz .popup-headline {
    font-size: 20px;
    line-height: 20px;
}
}

#form-bar-influencer label {
    text-transform: inherit;
}

.Selector {
    
    margin: 10px 0 25px 0;
    
}

.slick-slider h3 {
    text-transform: uppercase;
}

.slick-slider p {
    font-size: 14px !important;
    line-height: 1.5em;
    letter-spacing: 0.02em;
}

.Single-Slider, .Single-Slider-auto {
    
    height:90%;
    
}

.Single-Slider .slick-list, .Single-Slider .slick-track,.Single-Slider .el,
.Single-Slider-auto .slick-list, .Single-Slider-auto .slick-track,.Single-Slider-auto .el {
    
    height:100%;
    
}

#partner-popup {
    padding: 30px 20px 20px 20px;
}
.partner-popup-container {
  width: 100%;
}
.partner-popup-row {
    width:100%;
    margin-bottom: 20px
}
@media screen and (max-width: 900px) {
    .partner-popup-row {
        margin-bottom: 40px
    }
}
.partner-popup-container .left.onecol {
  float: left;
  width: 33.3%;
}
@media screen and (max-width: 900px) {
    .partner-popup-container .left.onecol {
        text-align: center;
    }
}
.partner-popup-container .left.onecol img {
    width: 180px;
    max-width: 100%;
}
@media screen and (max-width: 900px) {
    .partner-popup-container .left.onecol img {
        margin-bottom: 20px;
    }
}
.partner-popup-container .right.twocol {
  float: right;
  width: 66.6%;
  padding-left: 15px;   
}
@media screen and (max-width: 900px) {
    .partner-popup-container .right.twocol {
      padding-left: 0px;   
    }
}

.social-wrapper {

    .juicer-feed h1.referral {
        display: none !important;
    }

    [data-feed-id="landroverexp"] .j-poster {
        display: none;
    }

    .juicer-feed .j-poster a {
        display: block;
    }     
    .juicer-feed .j-stack .feed-item {
        border: none;
    }    
    .juicer-feed h3 {
        font-family: Avenir,sans-serif;       
    }    
    .juicer-feed p {
        font-family: Avenir,sans-serif;
        line-height: 1.5em;
        font-size: 14px;
        font-weight: 400;        
    }
    .juicer-feed a.juicer-button {
        width: 250px;
        display: block;
    }  

    .juicer-feed .j-meta a:hover {
        color: #333;
    }   

    .juicer-feed .j-paginate {
        margin-bottom: 40px;
    }

    .social-info-text {
        display: block;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        max-width: 600px;
    }    

    .social-info-text p {
        line-height: 1.5em;
        font-size: 12px;
        font-weight: 400;        
    } 
}

.j-loading {
  border-color: #333 !important;
}

.j-loading:before {
  background-color: #333 !important;
}

.j-poster h3,
.j-poster .j-date,
.j-message p,
.j-post-overlay.juicer-feed .j-overlay-text {
    font-family: Avenir,sans-serif;
}

.j-post-overlay.juicer-feed .j-overlay-text {
    padding: 35px 25px 25px;
}

.j-overlay .j-close {
    top: 15px;
    right: 10px;
}

.fallingSnow {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 500;
    opacity: 0;
}
.fallingSnow span {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: -500px 60px 54px -34px;
    background-image: url(../images/snowflake.png);
    animation: fallingSnow 12s infinite cubic-bezier(.35,.45,.39,.29);

}

.fallingSnow span:nth-child(5n+5) {

    animation-delay: 1.3s;

}
.fallingSnow span:nth-child(3n+2) {

    animation-delay: 1.5s;

}
.fallingSnow span:nth-child(2n+5) {

  animation-delay: 1.7s;

}

.fallingSnow span:nth-child(3n+10) {

 animation-delay: 2.7s;

}
.fallingSnow span:nth-child(7n+2) {

   animation-delay: 3.5s;

}
.fallingSnow span:nth-child(4n+5) {

 animation-delay: 5.5s;
}
.fallingSnow span:nth-child(10) {
    animation-delay: 8s;
}

.fallingSnow span:nth-child(17) {
    animation-delay: 1s;
}

@keyframes fallingSnow {
    0% {
        opacity: 1;

        -webkit-transform: translate(0, 0px) rotateZ(0deg);
    }
    75% {
        opacity: 1;

        -webkit-transform: translate(100px, 200px) rotateZ(270deg);
    }
    100% {
        opacity: 0;

        -webkit-transform: translate(150px, 300px) rotateZ(360deg);
    }
}

.page-child .button {
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 10px !important;
    font-weight: 700;
    letter-spacing: .3em !important;
    line-height: 1.4em !important;
}

.section-sub-headline {
    font-size: 16px;
    padding-top: 40px;
    padding-bottom: 30px;
    color: rgba(255, 255, 255, 0.6);
    max-width: 650px;
    margin: 0 auto;
}










