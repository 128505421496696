/**
 * CSS File for ezMark jQuery Plugin
 *
 **/
.ez-hide { opacity: 0; filter:alpha(opacity=0); }
.ez-checkbox {
	background: transparent url('../assets/images/checkbox-red.png') 0 1px no-repeat;
	display:inline-block;
}

.ez-radio {
	background: transparent url('../assets/images/radio-black.png') 0 1px no-repeat;
	display:inline-block;
}

.ez-checked { background-position: 0 -18px; }
.ez-selected { background-position: 0 -19px; }

.ez-checkbox-green {
	background: transparent url('../assets/images/checkbox-green.png') 0 1px no-repeat;
	display:inline-block;
	*display: inline;
}
.ez-checked-green { background-position: 0 -18px; }
.ez-checkbox, .ez-radio { zoom: 1; *display:inline; _height:30px; }
