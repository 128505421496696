@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .inner-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > img, 
  > .img,
  > video,
  > .background-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;     
  } 

  &.ie-object-fit {
    background-size: cover;
    background-position: center center;

    > img, 
    > .img{
      opacity: 0;
    }
  }

  &.ie-object-fit-video {
    overflow: hidden;

    > video,
    > .background-video {
      top:50%;
      left:50%;
      height:auto;
      width:auto;
      min-width:100%;
      min-height:100%;
      transform:translate(-50%, -50%);   
    }
  }     
}