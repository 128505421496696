.Footer ul {
    margin: 0;
    padding: 0;
}
.Footer {
    text-transform: uppercase;
    max-width: 2000px;
    margin: 0 auto;
    background-color: #4a4f54;
    //background: url(../images/texture-dark-grey.png);
}
.Footer h5 {
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.2em;
    margin-bottom: 5px;
}
@media screen and (min-width: 1px) and (max-width: 900px) {
    .Footer  ul.bottomMenu li #bottomMenuButton {
        display: block; 
    }
    .Footer ul.bottomMenu li .menuItems {
        display: none; 
    } 
    .Footer ul.bottomMenu li .menuItems.open {
        display: block;
        position: absolute;
        bottom: 45px ;
        width: 100%;
    } 
    .Footer ul.bottomMenu li ul li{
        float: left;
        width: 100%;
    }
    .Footer ul.bottomMenu li .menuItems.open li{
        width: 100%;
        background-color: #000;
        border-bottom: 1px solid #222; 
    } 
    .lightTheme .Footer ul.bottomMenu li .menuItems.open li {
        border-bottom-color: #cdd0d0;
    }
    .Footer ul.bottomMenu li a {
        width: 100%;
    }
} 
@media screen and   ( min-width:901px) {
    .Footer  ul.bottomMenu li #bottomMenuButton {
        display: none; 
    }
    .Footer ul.bottomMenu li .menuItems {
        display: block; 
    }
}
.Footer {
  /*  border-top: 1px solid #4e4e4e; */
    position: relative;
    width:100%;
}
.Footer .wrapper {
    overflow: hidden;
    clear: both;
    padding: 0 30px;
}
@media screen and   ( max-width:400px) {
    .Footer .wrapper {
        padding: 0;
    }
}
@media screen and   ( max-width:740px) {
    .col-5{
        display: none;
    }
        .col-5.socialLinks{
        display: block;
    }
}
.Footer .wrapper .col-5 {
    float: left;
    position: relative;
    width: 20%;
    margin: 0;
    padding: 0 0 22px 0;
}
.Footer .wrapper .col-5 ul li {
    padding: 4px 0;
    padding: 10px 0;
    line-height: 12px;
}
.Footer .wrapper .col-5 li a {
    color: #cbcbce;
    font-size: 9px;
    letter-spacing: 0.2em;
    text-decoration: none;
    font-weight: 400;
}
.Footer .wrapper .col-5 li a:hover {
    color: #ffffff;
}
.Footer .wrapper .col-5  h5:first-child {
    margin-top: 50px;
}
.Footer .wrapper .col-5   ul {
    margin: 0 20px 20px 0px;
}


/* ---------------------------------------------- BottomMenu */

.Footer ul.bottomMenu li a {

    color: #cbcbce;
    display: inline-block;
    font-size: 8px;
    letter-spacing: 0.3em;
    padding: 16px 18px;
    text-decoration: none;
    font-weight: 400;
    line-height: 1.5em;
}
.lightTheme .Footer ul.bottomMenu li a {
    color: #4a4f54;
    background: none repeat scroll 0 0 #fff;
    font-size: 9px;
    padding: 16px 20px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.Footer ul.bottomMenu li a:hover{
    color: #FFF; 
    -webkit-transition: color 200ms ease-out 0s;
    -moz-transition: color 200ms ease-out 0s;
    -o-transition: color 200ms ease-out 0s;
    -ms-transition: color 200ms ease-out 0s;
    transition: color 200ms ease-out 0s;
}
.lightTheme .Footer ul.bottomMenu li a:hover{
    color: #0c121c; 
    -webkit-transition: color 400ms ease-out;
    -moz-transition: color 400ms ease-out;
    -o-transition: color 400ms ease-out;
    -ms-transition: color 400ms ease-out;
    transition: color 400ms ease-out;
}
.Footer ul.bottomMenu li ul li{
    float: left; 
}
.Footer  ul.bottomMenu li a.toggleSwitch {
    display: none; 
}
.Footer  ul.bottomMenu {
    background-color: #000; 
    position: absolute;
    width: 100%;
}
.lightTheme .Footer  ul.bottomMenu {
    background-color: #fff; 
}
.Footer ul.bottomMenu > ul{
    width: 100%;
}
.Footer #bottomMenuButton i{
    padding-left: 4px;
}
.Footer #bottomMenuButton i{
    z-index: 19;
    cursor: pointer;
}
.Footer #bottomMenuButton{
    float: left;
    width: auto;
}
.Footer #bottomMenuButton.open{
    background: none repeat scroll 0 0 #4e4e4e;
            -webkit-transition: background-color 200ms linear;
        -moz-transition: background-color 200ms linear;
        -o-transition: background-color 200ms linear;
        -ms-transition: background-color 200ms linear;
        transition: background-color 200ms linear;
}
.lightTheme .Footer #bottomMenuButton.open{
    background: none repeat scroll 0 0 #e4e8e8;
}
.Footer #bottomMenuButton{
    background: none repeat scroll 0 0 #000;
            -webkit-transition: background-color 200ms linear;
        -moz-transition: background-color 200ms linear;
        -o-transition: background-color 200ms linear;
        -ms-transition: background-color 200ms linear;
        transition: background-color 200ms linear;
}
.lightTheme .Footer #bottomMenuButton{
    background: none repeat scroll 0 0 #fff;
}
#social ul li a span:before{
    width: 50px; 
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #222;
    display: block;
    font-size: 23px;
    font-weight: normal;
        -webkit-transition: background-color 300ms linear;
        -moz-transition: background-color 300ms linear;
        -o-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
    display: inline-block;
    margin-right: 8px;
    -webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;

}
#social ul li a:hover span:before{ 
        -webkit-transition: background-color 300ms linear;
        -moz-transition: background-color 300ms linear;
        -o-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
}
#social ul li a.social_facebook:hover span:before{
    background-color: #305891;
}
#social ul li a.social_twitter:hover span:before{
    background-color: #2CA8D2;
}
#social ul li a.social_youtube:hover span:before{
    background-color: #E62D27;
}
#social ul li a.social_instagram:hover span:before{
    background-color: #406e95;
}
#social ul li {
    position: relative; }
#social ul li a .text{
    position: absolute;
    top:22px;
    left: 62px;
}
@media screen and (min-width: 1px) and (max-width: 741px) {
    #social{
        width: 100%;
    }
    #social ul {
         clear: both;
        text-align: center;
            width: 100%;
     }
    #social ul li {
         display: inline;
          }
    #social ul li a .text{
       display: none;
    }
    #social h5{
        text-align: center;
        padding-bottom: 8px;
    }
}


/* ---------------------------------------------- Share-Button */

#bottomMenuShareButton{
    float: right; 
    font-size: 18px;
    height: 42px;
    line-height: 17px;
    padding: 13px 18px;
    color: #FFFFFF;
    background-color: #0c121c;
        -webkit-transition: background-color 300ms linear;
    -moz-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
    z-index: 2;
}
#bottomMenuShareButton:hover, #sharethis a:hover{
    background-color: #141b27;
        -webkit-transition: background-color 300ms linear;
    -moz-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    #bottomMenuShareButton{
        position: absolute;
        right: 0; 
        width: auto;
        float: none;
    }
}
#sharethis{
    position: absolute;
    right: 0;
    bottom: 44px;
    width: 62px;
    clear: both;
    overflow: hidden;
    z-index: 1;
    display: none;
}
#sharethis a{
    font-size: 18px;
    height: 43px;
    line-height: 20px;
    padding: 11px 18px;
    color: #FFFFFF;
    background-color: #0c121c;
    -webkit-transition: background-color 300ms linear;
    -moz-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
}
 
#sharethis #fb:hover{
    background-color: #305891;
}
#sharethis #tw:hover{
    background-color: #2CA8D2;
}
#sharethis #share:hover{
    background-color: #CCC;
}

/* Sub-Footer */
#sub-footer {
    clear: both;
    padding: 20px;
    border-top: 1px solid #222;
    background: #000;
    text-align: center;
    color: #999;
    max-width: 2000px;
    margin: 0 auto;
}
.lightTheme #sub-footer {
    border-top: 2px solid #e4e8e8;
    background: #fff;
    color: #4a4f54;
}
@media screen and (min-width: 1px) and (max-width: 899px) {
    .lightTheme #sub-footer {
        border-top: 1px solid #e4e8e8;
        margin-top: 2px;
    }
}
    
#sub-footer p {
    font-size: 13px;
    line-height: 1.5;
    margin-bottom: 12px;
    max-width: none;
}
.lightTheme #sub-footer p {
    font-size: 12pt;
}
#sub-footer a {
    color: #ccc;
}
.lightTheme #sub-footer a {
    color: #4a4f54;
}
.lightTheme #sub-footer a:hover {
    color: #a4a7a9;
}