/* Slider */

.slick-slider {
	cursor: grab;
}

.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }
.slick-list:focus { outline: none; }
/*.slick-loading .slick-list { background: white url("../ajax-loader.gif") center center no-repeat; }*/
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; zoom: 1; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }
.slick-slide img {
    display: block;
    pointer-events: none;
}
.white .slick-slide img {
    border-radius: 50%;
}
.team-2015.white .slick-slide img {
    border-radius: 0;
}
.white .image-module-slider .slick-slide img {
    border-radius: 0;
}
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

/* Icons */
@font-face { font-family: "slick"; src: url("../fonts/slick.eot"); src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg"); font-weight: normal; font-style: normal; }
/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 20px; width: 20px; line-height: 0; font-size: 0; cursor: pointer; background: transparent; color: transparent; top: 50%; margin-top: -10px; padding: 0; border: none; outline: none; }
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; background: transparent; color: transparent; }
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

.slick-prev:before, .slick-next:before {font-family:'icons',sans-serif; font-size: 20px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.white .slick-prev:before,
.white .slick-next:before {
    color: black;
}

.light .slick-prev:before,
.light .slick-next:before {
    color: black;
}

.darkestgrey .slick-prev:before,
.darkestgrey .slick-next:before {
    color: white;
}

.slick-prev { left: 0px !important; }
.slick-prev:before { content: '\e00b' }

.slick-next { right: 0px !important; }
.slick-next:before { content: '\e00c' }

/* Dots */
.slick-slider { margin-bottom: 18px; }
.sep {font-size: 20px; padding-top: 3px;margin: 0!important;}
#currentSlide { margin-right: 0px; }
.totalSlides { margin-left: 0px !important;}
.slick-dots { list-style: none; display: inline-block; text-align: center; padding: 0;margin: auto;display: block !important;  font-size: 12px;}
.slick-dots li {vertical-align: middle; position: relative; display: inline-block; height: 20px; width: 20px; margin: 0 5px; padding: 0; cursor: pointer; }
.slick-dots li button { border: 0; background: transparent; display: block; height: 20px; width: 20px; outline: none; line-height: 0; font-size: 0; color: transparent; padding: 5px; cursor: pointer; }
.slick-dots li button:hover, .slick-dots li button:focus { outline: none; }
.slick-dots li button:hover:before, .slick-dots li button:focus:before { opacity: 1; }
.slick-dots li button:before { position: absolute; top: 0; left: 0; content: "\2022"; width: 20px; height: 20px; font-family: "slick"; font-size: 6px; line-height: 20px; text-align: center; color: black; opacity: 0.25; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.slick-dots li.slick-active button:before { color: black; opacity: 0.75; }

[dir="rtl"] .slick-next {right: auto;left: -25px;}
[dir="rtl"] .slick-next:before {content: "\2190";}
[dir="rtl"] .slick-prev {right: -25px;left: auto;}
[dir="rtl"] .slick-prev:before {content: "\2192";}
[dir="rtl"] .slick-slide {float: right;}

.counter {
	text-align: center;
}