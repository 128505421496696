/* Buttons weiter entwickeln */

/* button $style $outlinestyle  */

.button,
.juicer-feed a.juicer-button,
.j-post-overlay.juicer-feed a.juicer-button,
._brlbs-btn-accept-all,
#BorlabsCookieBox ._brlbs-manage a,
#BorlabsCookieBox ._brlbs-accept a,
#BorlabsCookieBox .cookie-preference ._brlbs-btn {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0);
    border: 1px solid #000;
    border-radius: 0;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.3em;
    line-height: 12px;
    min-width: 125px;
    //height: 45px;
    overflow: hidden;
    padding: 15px 24px 14px;
    text-align: center;
    text-decoration: none !important;
    text-shadow: none;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    text-transform: uppercase;
}

#BorlabsCookieBox .cookie-preference ._brlbs-btn-accept-all,
#BorlabsCookieBox ._brlbs-accept a {
    background: black;
    border: 1px solid black;
    color: white;
}
#BorlabsCookieBox .cookie-preference ._brlbs-btn-accept-all:hover,
#BorlabsCookieBox ._brlbs-accept a:hover {
    background-color: white;
    border: 1px solid black;
    color: black;
}
#BorlabsCookieBox ._brlbs-manage a,
#BorlabsCookieBox .cookie-preference ._brlbs-btn:not(._brlbs-btn-accept-all) {
    background: rgb(233, 236, 236);
    border: 1px solid rgb(233, 236, 236);
    color: rgb(0, 90, 43);
}
#BorlabsCookieBox ._brlbs-manage a:hover,
#BorlabsCookieBox .cookie-preference ._brlbs-btn:not(._brlbs-btn-accept-all):hover {
    background-color: white;
    border: 1px solid black;
    color: black !important;
}

.page-template-page-home .light a {
    position: initial;
}

.light a {
    display: inline-block;
    position: relative;
}


/* Light Grey/White theme Black Button */
.light .button, 
.white .button,
.light .button.black, 
.white .button.black,
.juicer-feed a.juicer-button,
.juicer-feed .j-paginate,
.j-post-overlay.juicer-feed a.juicer-button {
	background: black;
	border: 1px solid black;
	color: white;
}

/* Light Grey/White theme Grey Button */
.light .button.grey, 
.white .button.grey,
.light .button.white, 
.white .button.white {
    background: rgb(233, 236, 236);
    border: 1px solid rgb(233, 236, 236);
    color: rgb(0, 90, 43);
}

/* Light Grey/White theme hover states */
.light .button:hover,
.light .button:active,
.light .button:focus,
.white .button:hover,
.white .button:active,
.white .button:focus,
.juicer-feed a.juicer-button:hover,
.juicer-feed a.juicer-button:active,
.juicer-feed a.juicer-button:focus,
.juicer-feed .j-paginate:hover,
.juicer-feed .j-paginate:active,
.juicer-feed .j-paginate:focus,
.j-post-overlay.juicer-feed a.juicer-button:hover,
.j-post-overlay.juicer-feed a.juicer-button:active,
.j-post-overlay.juicer-feed a.juicer-button:focus {
    background-color: white;
    border: 1px solid black;
    color: black;
}

/* Dark grey theme Black Button */
.darkestgrey .button, 
.grey .button,
.darkblue .button,
.darkestgrey .button.black, 
.grey .button.black,
.darkblue .button.black {
	background: #0c121c;
	border: 1px solid #0c121c;
	color: white;
}

/* Dark grey theme White Button */
.darkestgrey .button.white, 
.grey .button.white,
.darkblue .button.white,
.darkestgrey .button.grey, 
.grey .button.grey,
.darkblue .button.grey {
    background: white;
    border: 1px solid white;
    color: rgb(0, 90, 43);
}

/* Dark grey theme hover states */
.darkestgrey .button:hover,
.darkestgrey .button:active,
.darkestgrey .button:focus,
.grey .button:hover,
.grey .button:active, 
.grey .button:focus,
.darkblue .button:hover,
.darkblue .button:active, 
.darkblue .button:focus {
    background: #4a4f54;
    border: 1px solid white;
    color: white;
}

/* .kopfbereich.mitbild .button styles */
.kopfbereich.mitbild {
    .button.white {
        background: white;
        border: 1px solid white;
        color: rgb(0, 90, 43);
    }       
    .button.white:hover,
    .button.white:active,
    .button.white:focus {
        background: #4a4f54;
        border: 1px solid white;
        color: white;
    }
    .button.black {
        background: black;
        border: 1px solid black;
        color: white;
    }       
    .button.black:hover,
    .button.black:active,
    .button.black:focus {
        background-color: white;
        border: 1px solid black;
        color: black;
    }    
    .button.grey {
        background: rgb(233, 236, 236);
        border: 1px solid rgb(233, 236, 236);
        color: rgb(0, 90, 43);
    }       
    .button.grey:hover,
    .button.grey:active,
    .button.grey:focus {
        background-color: white;
        border: 1px solid black;
        color: black;
    }    
}

/* .kopfbereich.mitbild .button.white styles für mobile überschreiben */
@media (max-width: 740px) {
    .kopfbereich.mitbild {
        .button.white {
            background: black !important;
            border: 1px solid black !important;
            color: white !important;
        }       
        .button.white:hover,
        .button.white:active,
        .button.white:focus {
            background-color: white !important;
            border: 1px solid black !important;
            color: black !important;
        }
    }
}


/* border variante */
//.light .button.onlyoutline, 
//.white .button.onlyoutline {
//	background: none;
//	border: 1px solid black;
//	color: black;
//}
//.darkestgrey .button.onlyoutline, 
//.grey .button.onlyoutline {
//	background: none;
//	border: 1px solid white;
//	color: white;
//}
//
//
//.light .button.onlyoutline:hover,
//.light .button.onlyoutline:focus,
//.white .button.onlyoutline:hover,
//.white .button.onlyoutline:focus {
//	background-color: black;
//    color: white;
//	border: 1px solid black;
//}
//
//.darkestgrey .button.onlyoutline:hover,
//.darkestgrey .button.onlyoutline:focus,
//.grey .button.onlyoutline:hover, 
//.grey .button.onlyoutline:focus {
//	background: white;
//    color: black;
//	border: 1px solid white;
//}
//
//.button.white {
//    background: white;
//    border: 1px solid white;
//    color: black;   
//}
//.button.white:hover {
//    background: black;
//    border: 1px solid black;
//    color: white;
//}
//
//.button.black {
//    background: black;
//    border: 1px solid black;
//    color: white;   
//}
//.button.black:hover {
//    background: white;
//    border: 1px solid white;
//    color: black;
//}

//.button.orange {
//    background: #E05206;
//    border: 1px solid #E05206;
//    color: white !important;   
//}
//.button.orange:hover {
//    background: #00173A;
//    border: 1px solid #00173A;
//    color: white;
//}

/* XXXXXXXXXXXX alter code */

@media (max-width: 1450px) {
    .onecol .button {
        min-width: 190px;
    }
}


.primaryLinkWithStyle {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0);
    border: 1px solid #000;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.3em;
    line-height: 14px;
    min-width: 125px;
    overflow: hidden;
    padding: 13px 23px 11px;
    text-align: center;
    text-decoration: none !important;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    text-transform: uppercase;
    height:40px;
}

.primaryLinkWithStyle:focus,
.primaryLinkWithStyle:hover {
    background-color: #000;
    color: #fff;
	border: 1px solid #000;
}


.grey .primaryLinkWithStyle, .darkestgrey .primaryLinkWithStyle, .primaryLinkWithWhiteStyle {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 255);
    border: 1px solid #fff;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.3em;
    line-height: 1.4em;
    min-width: 125px;
    overflow: hidden;
    padding: 13px 23px 11px;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
    transition-timing-function: ease;
}

.primaryLinkWithWhiteStyle:focus,
.primaryLinkWithWhiteStyle:hover,
.grey .primaryLinkWithStyle:focus,
.grey .primaryLinkWithStyle:hover,
.darkestgrey .primaryLinkWithStyle:focus,
.darkestgrey .primaryLinkWithStyle:hover

{
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    text-decoration: none
}

.primaryLinkWithWhiteBorderStyle {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0);
    border: 1px solid #FFF;
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.3em;
    line-height: 1.4em;
    margin-top: 5px;
    min-width: 125px;
    overflow: hidden;
    padding: 13px 23px 11px;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
    transition-timing-function: ease;
	text-transform: uppercase;
}

@media (max-width: 740px) {
    .primaryLinkWithWhiteBorderStyle {
        margin-top: 10px;
    }
}

.primaryLinkWithWhiteBorderStyle:focus,
.primaryLinkWithWhiteBorderStyle:hover {
    background-color: #FFF;
    color: #000;
    text-decoration: none;
}

.primaryLinkWithBlackStyle {
    background: #000;
    border: 1px solid #000;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-family: Avenir,Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.3em;
    line-height: 1.4em;
    margin-top: 30px;
    min-width: 125px;
    overflow: hidden;
    padding: 13px 23px 11px;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
    transition-timing-function: ease;
	text-transform: uppercase;
}

.firstbutton {
    margin-right: 10px;
}

@media (max-width: 1450px) {
    .onecol .firstbutton {
        margin-bottom: 10px;
    }
}
.kopfbereich .firstbutton {
    margin-right: 0;
}

/* Button für Tour Seite */
@media (max-width: 739px) {
    .page-template-page-tour-2017-peru .kopfbereich .button-wrap {
        display: none;
    }
}
@media (min-width: 740px) {
    .page-template-page-tour-2017-peru .kopfbereich .button-wrap {
        margin: 0 auto;
        display: block;
        position: relative;
        left: 0;
        width: 100%;
        text-align: center;
    }
    .page-template-page-tour-2017-peru .kopfbereich .button-wrap .button {
        border: 2px solid #fff;
        padding: 17px 23px 16px;
    }
}


/* Buttons im Kopfbereich */
.imageContainer .primaryLinkWithWhiteBorderStyle {
	margin-top: 30px;
}


.primaryLinkWithBlackStyle:focus,
.primaryLinkWithBlackStyle:hover {


    background: #4e4e4e;
    border: 1px solid #4e4e4e;
    text-decoration: none
}


.kopfbereich.mitbild.linksbuendig .button-wrap {
    margin: 0;
    text-align: left;
}

/* Link mit Pfeil */

.secondaryLinkWithStyle {

    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.25em;
    line-height: 1.25;
    margin-top: 20px;
    overflow: hidden;
    padding-left: 18px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    
}

.secondaryLinkWithStyle:before {
    font-family:'icons',sans-serif;
    content: '\e02c';

    position: absolute;
    top: 1px;
    left: 0px;
    font-size: 9px
}

.secondaryLinkWithStyle:focus,
.secondaryLinkWithStyle:hover {
    text-decoration: none;
    color: #696868
}

.secondaryLinkWithStyle:focus:before,
.secondaryLinkWithStyle:hover:before {
    color: #696868
}
