/* ----------------------------- Layout */

.Article {
    background-color: #FFF;
    width: 100%;
}

.sectionWrapper {
    padding: 80px 0;
    display: block;
    color: #393939;
    max-width: 680px;
    margin: 0 auto;
}

@media (max-width: 740px) {
    .sectionWrapper {
        padding: 40px 30px;
    }
}

.checkfrontWrapper {
    padding: 80px 30px;
    display: block;
    margin: 0 auto;	
}

@media (max-width: 740px) {
    .sectionWrapper {
        padding: 40px 10px;
    }
}

.Article {
    .default-wrap.block {
        height: 409px;
        margin-bottom: 20px;

      @media screen and (max-width: 740px) {
        height: auto;
        }

    }



    .default-wrap.block.single-image {
        height: 370px;
        margin-bottom: 20px;

      @media screen and (max-width: 740px) {
        height: auto;
      }

        .slick-slider {
            height: 100%;
            margin-bottom: 0;
        }        
    }     

    .auto-ratio .default-wrap.block.single-image {
        height: auto;        
    } 
}

/* ----------------------------- Typo (der Unterseiten) */

.Article .entry-content div {
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.015em;
    color: #393939;
}

.Article p {
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.015em;
    //padding-bottom: 20px;
    margin: 20px 0;
    color: #393939;
    max-width: 100%;

    &:first-child {
        margin-top: 0;
    }     
    &:last-child {
        margin-bottom: 0;
    }           
}
.Article h2 {
	font-family: Avenir,Arial,Helvetica,sans-serif;
	font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1.1;
	margin: 10px 0 20px 0;
}
@media screen and (max-width: 740px) {
	.Article h2 {
		font-size: 20px;
	}
}
.Article a:not(.category-link) {
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.015em;    
	color: #333;
	text-decoration: underline;
}

/* ----------------------------- SEO-Text */

.seotext {
    background: #f8f8f8;
}

.seotext .sectionWrapper, .seotext .sectionWrapper p {
    max-width: 1000px;
}
@media (max-width: 1020px) {
    .seotext .sectionWrapper {
        padding: 40px 10px;
    }
}


.seotext h1 {
    font-family: Avenir,Arial,Helvetica,sans-serif;
	font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1.1;
	margin: 10px 0 30px 0;
    text-transform: uppercase;
}
.seotext h2 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.015em;
    text-transform: none;
}

/* ----------------------------- Artikelbild */
.wp-post-image {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}

.image-figure,
.image-wrap {
    max-width: 100%;
    margin: 40px 0 40px;

    img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;        
    }
}
.image-figure {
    .image-wrap { 
        margin: 0
    }
    figcaption {
        background-color: #f2f2f2;
        padding: 5px;
    }   
}

.image-module-wrap {
    margin: 40px 0 40px;
    img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;        
    } 
}

/* ----------------------------- Gallery */

.gallery {
	margin-top: 30px;
	border-top: 1px solid #ccc;
	padding-top: 70px;
}
@media screen and (max-width: 740px) {
.gallery {
	margin-top: 10px;
	padding-top: 30px;
}
}
.gallery dl, .gallery dt {
	margin: 0;
	padding: 0;
}

.gallery a {
	display: block;
	line-height: 0; /* einheitliche abstaende */
}

.gallery img{ 
	height: 130px;
	width: auto;
}

.gallery .gallery-item {
	float:left;
	height: 130px;
	margin: 0 15px 15px 0;
}

.gallery img {
        border: 0 !important;
}

@media screen and (max-width: 740px) {
.gallery .gallery-item {height: 100px;}
.gallery img {height: 100px;}
}
@media screen and (max-width: 400px) {
.gallery .gallery-item { height: 70px;}
.gallery img {height: 70px;}
}

/* ----------------------------- Search Result */

.Article.search-result {
    h3 {
        margin-top: 20px;
        padding-bottom: 5px;
    }    
    p {
        margin-top: 0;
    }
}

/* ----------------------------- Blog */
.GridListWrapper.blog-wrapper {

    .blog-items {
        margin-left: -10px;
        margin-right: -10px;        
    }   

    .juicer-feed .blog-item {
        float: left !important;
        background: transparent !important;
        margin: 0 !important;
        padding: 0 10px 20px;
        border: none !important;
        opacity: 1 !important;
        font-family: Avenir,sans-serif !important;

        .j-poster {
            background: #fff;

            a {
                display: block;
            }            
        }
        .j-image {
            display: block;
            overflow: hidden;
            background-color: #f9f9f9;
            @include aspect-ratio(593, 394);

            img {
                margin-bottom: 0;
                transition: all .3s linear;
                text-indent: -10000px;                
            }     

            &:hover img {
              transform: scale(1.05,1.05);
            }                          
        }
        .j-text {
            background: #fff;

            a {
                display: block;
            }            
        }        
    }

    .load-more-button {
        width: 250px;
        display: block;
    }    
}

h2.secondaryHeading.blogHeading {
    font-family: lr_ot4,Arial,Helvetica,sans-serif;
    text-transform: uppercase;
    letter-spacing: .15em;
    line-height: 1.1em;
    max-width: 800px;
    margin: 0 auto;
    font-size: 32px;
    font-weight: 500;
    margin: 0;
    max-width: 100%;
    padding: 0;    
    margin-bottom: 50px;
}

/* ----------------------------- Single Post */
.post-head-section {
    .section-pre-headline {
        margin-bottom: 20px;
        text-transform: uppercase;
        letter-spacing: 3px;
    }
    .author-wrap {
        margin-top: 20px;
    }
}
.author-image {
    display: inline-block;
    border-radius: 50%;
    border-color: white;
    width: 30px !important;
    height: 30px;
    margin: 0 11px 0 0;
    padding: 0;    
}
.author-headline {
    display: inline-block;
    line-height: 30px;
    vertical-align: top;
    margin: 0;    
}
.article-body {
    width: 100%;
}